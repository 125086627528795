import classNames from 'classnames'
import React from 'react'

type Props = {
  colSpan?: number
  className?: string
  children?: React.ReactNode
}

const Column = ({ colSpan, children, className }: Props) => {
  return (
    <td className={classNames('max-w-[144px] py-2 truncate first:pl-4 last:pr-4 overflow-none text-primary-gray text-sm', className)} colSpan={colSpan}>{children ?? '-'}</td>
  )
}

export default Column