import Button from 'components/button'
import Modal from 'components/modals'
import TermsAndConditionsBody from '../html/TermsAndConditions'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TermsAndConditions = ({ isOpen, onClose }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="SUPRA UK LTD, Terms and Conditions of sale" />
      <Modal.Content>
        <div className="max-h-96 overflow-y-scroll informational-html pr-4 break-words">
          <TermsAndConditionsBody />
        </div>
      </Modal.Content>
      <Modal.Footer className="flex">
        <Button className="ml-auto" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TermsAndConditions