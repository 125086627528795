/**
 * Compare two arrays and find out if fields from the second array are part of the first array
 * @param firstArray The first array you want to compare against
 * @param secondArray The secondary array you want to compare the first with
 * @returns 
 */
export const compareArrays = (firstArray: Array<string>, secondArray: Array<string>) => {
  return firstArray.every(element => (
    secondArray.includes(element)
  ))
}