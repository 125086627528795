import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import useErrorHandler from 'hooks/useErrorHandler'
import Button from 'components/button'
import FormLayout from '../layouts/FormLayout'
import TextArea from 'components/form/textarea'
import Configuration from 'api/types/models/configuration'
import { keyBy } from 'lodash'
import ConfigurationType from 'enums/ConfigurationKey'

type FormInputs = {
  default_public_message: string,
}

type FormProps = {
  configurations?: Configuration[]
  onSubmitError?: FetchBaseQueryError | SerializedError | undefined
  onSubmit: (data: FormInputs) => void
  onSubmitLoading?: boolean
}

const ConfigurationForm = ({ configurations, onSubmitError, onSubmit, onSubmitLoading = false }: FormProps) => {
  const errorHandler = useErrorHandler()

  const sortedConfigurations = useMemo(() => keyBy(configurations, 'key'), [configurations])
  
  const { register, handleSubmit, setError, clearErrors, getValues, setValue, formState: { errors }, control } = useForm<FormInputs>({
    defaultValues: {
      'default_public_message': sortedConfigurations?.[ConfigurationType.DEFAULT_PUBLIC_MESSAGE]?.value,
    },
  })

  const submitWrapper = (data: FormInputs) => {
    return onSubmit(data)
  }

  useEffect(() => {
    if (onSubmitError) {
      errorHandler(onSubmitError, setError)
    }
  }, [onSubmitError])

  return (
    <FormLayout>
      <form onSubmit={handleSubmit(submitWrapper)}>
        <FormLayout.Block>
          <FormLayout.Title
            title="Assets"
            subtitle="Information that applies to all assets. These values can be overwritten by a user's individual preferences"
          />
          <FormLayout.Group
            label='Default Public Message'
            description='The default message that is shown to users who try to view a KiCall they are unauthorised to view.'
            htmlFor="default_public_message"
          >
            <TextArea
              {...register('default_public_message')}
              error={errors.default_public_message?.message}
            />
          </FormLayout.Group>
        </FormLayout.Block>

        <FormLayout.Footer>
          <Button type="submit" isLoading={onSubmitLoading}>
            Update
          </Button>
        </FormLayout.Footer>
      </form>
    </FormLayout>
  )
}

export default ConfigurationForm