import Asset from 'api/types/models/asset'
import AssetGridCard from 'components/cards/asset/grid'

type Props = {
  assets?: Asset[]
}

const AssetBrowseGrid = ({ assets }: Props) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
      {
        assets?.map((asset, index) => {
          return (
            <div key={index} className="flex flex-col w-full max-w-md gap-8 mx-auto">
              <AssetGridCard
                asset={asset}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default AssetBrowseGrid