import { useAdminGetFilesQuery } from 'api'
import SearchInput from 'components/admin/search_input'
import AdminTable from 'components/admin/tables'
import Button from 'components/button'
import DeleteUserFilesModal from 'components/modals/forms/user_files/DeleteUserFilesModal'
import Pagination from 'components/pagination'
import { useDebounce } from 'hooks/useDebounce'
import { useSort } from 'hooks/useSort'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const AdminFilesBrowsePage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedUserId, setSelectedUserId] = useState<number|undefined>()
  const [isDeleteUserFilesModalOpen, setDeleteUserFilesModalOpen] = useState<boolean>(false)

  const debouncedSearch = useDebounce({ value: searchTerm })

  const { sorts, toggleSorts } = useSort({
    'id': undefined,
    'last_name': undefined,
  }, () => setCurrentPage(1))

  const openDeleteUserFilesModal = (userId: number) => {
    setSelectedUserId(userId)
    setDeleteUserFilesModalOpen(true)
  }

  const { 
    data: users, 
    isLoading: isLoadingFiles,
  } = useAdminGetFilesQuery({ 
    page: currentPage, 
    sort: sorts, 
    search: debouncedSearch, 
  }, { refetchOnMountOrArgChange: true })

  return (
    <div className="flex flex-col h-full">

      <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
        <h1 className="text-2xl font-medium text-slate-800">
          Files
        </h1>
        <Button href="/admin/dashboard/files/create" className="hidden lg:block">
          Create
        </Button>
      </div>

      <div className="p-3 lg:p-6">
        <SearchInput 
          isLoading={isLoadingFiles} 
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </div>

      <div className="px-3 pb-3 overflow-x-scroll lg:px-6 lg:pb-6">
        <AdminTable isLoading={isLoadingFiles}>
          <AdminTable.Head>
            <AdminTable.Heading
              sortable
              direction={sorts.id}
              onClick={() => toggleSorts('id')}
            >
              User ID
            </AdminTable.Heading>
            <AdminTable.Heading
              sortable
              direction={sorts.last_name}
              onClick={() => toggleSorts('last_name')}
            >
              User Name
            </AdminTable.Heading>
            <AdminTable.Heading>Attachments</AdminTable.Heading>
            <AdminTable.Heading>Action</AdminTable.Heading>
          </AdminTable.Head>
          <AdminTable.Body>
            {
              users && users.data.map((user) => (
                <AdminTable.Row key={`file-browse-${user.id}`}>
                  <AdminTable.Column>{user.id}</AdminTable.Column>
                  <AdminTable.Column>{user.first_name} {user.last_name}</AdminTable.Column>
                  <AdminTable.Column>{user.files.length ?? 0}</AdminTable.Column>
                  <AdminTable.Column>
                    <div className="flex text-sm divide-x">
                      <Link to={`/admin/dashboard/files/manage/${user.id}`} className="px-1 url-text">
                        Manage
                      </Link>
                      <button className="px-1 text-red-500" onClick={() => openDeleteUserFilesModal(user.id)}>
                        Delete
                      </button>
                    </div>
                  </AdminTable.Column>
                </AdminTable.Row>
              ))
            }
          </AdminTable.Body>
        </AdminTable>
      </div>

      <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
        <Pagination onPage={currentPage} onPageChange={(page) => {setCurrentPage(page)}} totalPages={users?.meta.last_page ?? 0} />
      </div>

      <DeleteUserFilesModal
        userId={selectedUserId}
        isOpen={isDeleteUserFilesModalOpen}
        onClose={() => setDeleteUserFilesModalOpen(false)}
      />

    </div>
  )
}

export default AdminFilesBrowsePage