import { HorizontalDivider } from 'components/Dividers/horizontal'
import Button from 'components/button'
import { FormControl } from 'components/form/control'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { FormLabel } from 'components/form/label'
import Phone from 'components/form/phone'
import { RadioCardOption } from 'components/form/radio_card'
import { RadioCardGroup } from 'components/form/radio_card_group'
import { Title } from 'components/headings'
import AssetNotificationMethod from 'enums/AssetNotificationMethod'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormBaseProps } from 'types/form'
import { z } from 'zod'

const assetNotificationSchema = z.object({
  alt_name: z.string().nullable(),
  alt_email: z.string().nullable(),
  alt_phone: z.string().nullable(),
  alt_country_code: z.string().nullable(),
  notification_method: z.nativeEnum(AssetNotificationMethod).nullable(),
  notification_enabled: z.boolean().nullable(),
})

export type AssetNotificationFormInputs = z.infer<typeof assetNotificationSchema>

type AssetNotificationFormProps = FormBaseProps<AssetNotificationFormInputs> & {
  onDirty: (isDirty: boolean) => void
  isSuccess?: boolean,
}

function FormWrapper({
  register,
  onDirty,
  getValues,
  setValue,
  clearErrors,
  control,
  formState: { errors, isDirty },
}: UseFormReturn<AssetNotificationFormInputs> & { onDirty: (isDirty: boolean) => void }) {

  useEffect(() => {
    if (isDirty) {
      onDirty(isDirty)
    }
  }, [isDirty])

  return (
    <div className="flex flex-col gap-4">
      <Title
        size="lg"
        tooltip='Setup an alternative contact receiver'
        tooltipId='alt-contact-form-id'
      >
        Alternative Contact
      </Title>

      <FormControl>
        <FormLabel htmlFor="alt_name">Name</FormLabel>
        <Input
          id="alt_name"
          {...register('alt_name')}
          placeholder="Enter name"
          error={errors?.alt_name?.message}
        />
      </FormControl>

      <div className="flex flex-wrap gap-4 md:flex-nowrap">
        <FormControl>
          <FormLabel htmlFor="alt_email">Email</FormLabel>
          <Input
            id="alt_email"
            {...register('alt_email')}
            placeholder="Enter email"
            error={errors?.alt_email?.message}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="alt_phone">Phone</FormLabel>
          <Phone
            defaultPhone={getValues('alt_phone') ?? ''}
            defaultCountryCode={getValues('alt_country_code') ?? ''}
            onChange={(phone) => {
              setValue('alt_phone', phone.phone)
              setValue('alt_country_code', phone.country_code)

              if (errors.alt_phone || errors.alt_country_code) {
                clearErrors(['alt_country_code', 'alt_phone'])
              }
            }}
            error={errors?.alt_phone?.message || errors?.alt_country_code?.message}
          />
        </FormControl>
      </div>

      <HorizontalDivider />

      <FormControl className='flex flex-col gap-2'>
        <Title
          size="lg"
          tooltip='Choose the method of receiving notifications for this asset'
          tooltipId='channel-form-id'
        >
          Channel
        </Title>

        <RadioCardGroup name="notification_method" control={control}>
          <RadioCardOption value={AssetNotificationMethod.SMS} first>WhatsApp</RadioCardOption>
          <RadioCardOption value={AssetNotificationMethod.EMAIL} last>Email</RadioCardOption>
        </RadioCardGroup>
      </FormControl>

      <FormControl className='flex flex-col gap-2'>
        <Title
          size="lg"
          tooltip='Choose whether notifications are sent for this asset'
          tooltipId='receive-notifications-form-id'
        >
          Receive Notifications
        </Title>

        <RadioCardGroup name="notification_enabled" control={control}>
          <RadioCardOption value={true} first>Receive All</RadioCardOption>
          <RadioCardOption value={false} last>Receive None</RadioCardOption>
        </RadioCardGroup>
      </FormControl>
    </div>
  )
}

export function AssetNotificationForm({
  onSubmit,
  onDirty,
  errors,
  isLoading,
  defaultValues,
  isSuccess,
}: AssetNotificationFormProps) {
  return (
    <Form<AssetNotificationFormInputs, typeof assetNotificationSchema>
      error={errors}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      {(methods) => (
        <>
          <FormWrapper
            onDirty={(isDirty) => onDirty(isDirty)}
            {...methods}
          />

          <Button
            type="submit"
            className="!mt-6"
            isLoading={isLoading}
            isSuccess={isSuccess}
            successText='Asset updated'
            block
          >
            Update Notifications
          </Button>
        </>
      )}
    </Form>
  )
}