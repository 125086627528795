import { z } from 'zod'

/* 
  Use .required if you want to make these non-optional
*/
export const addressSchema = z.object({
  line_1: z.string().nullable(),
  line_2: z.string().nullable(),
  town_or_city: z.string().nullable(),
  county: z.string().nullable(),
  country: z.string().nullable(),
  postcode: z.string().nullable(),
})