import { PropsWithChildren, useMemo } from 'react'
import FormLayout from '../layouts/FormLayout'
import AssetDeviceTable from './device_table'
import Input from 'components/form/input'
import Button from 'components/button'
import ListBox from 'components/form/listbox'
import { useAdminGetAssetFilesQuery, useAdminListThemesQuery } from 'api'
import AssetFileTable from './file_table'
import FormError from 'components/error'
import ComboBox from 'components/form/combobox'
import { useGetClientsQuery } from 'api/endpoints/admin/client'
import { Client } from 'api/types/models/client'
import { RadioCardGroup } from 'components/form/radio_card_group'
import { RadioCardOption } from 'components/form/radio_card'
import { AssetType, FriendlyAssetType } from 'enums/AssetType'
import ReactQuill from 'react-quill'
import { z } from 'zod'
import { FormBaseProps } from 'types/form'
import { UseFormReturn } from 'react-hook-form'
import Asset from 'api/types/models/asset'
import { Form } from 'components/form/form'
import { TimedMessage } from 'feature/message/components/items/timed-message'
import PostCodeLookup from 'components/form/postcode_lookup'

const adminAssetSchema = z.object({
  masked_uid: z.string(),
  type: z.nativeEnum(AssetType),
  client_id: z.coerce.number().nullable(),
  theme_id: z.coerce.number().nullable(),
  default_message_name: z.string(),
  default_message_content: z.string(),
  is_private: z.boolean(),
  description: z.string().min(1, 'Description is required'),
  line_1: z.string().nullable(),
  line_2: z.string().nullable(),
  town_or_city: z.string().nullable(),
  county: z.string().nullable(),
  country: z.string().nullable(),
  postcode: z.string().nullable(),
  note: z.string().nullable(),
})

export type AdminAssetFormInputs = z.infer<typeof adminAssetSchema>

type AdminAssetFormProps = FormBaseProps<AdminAssetFormInputs> & {
  asset?: Asset | null
  isLoading: boolean
  btnTxt: string
}

function FormWrapper({
  asset,
  watch,
  control,
  trigger,
  register,
  getValues,
  setValue,
  formState: { errors, defaultValues },
  children,
}: PropsWithChildren<UseFormReturn<AdminAssetFormInputs>> & { asset?: Asset | null | undefined }) {
  const {
    data: clients,
    isLoading: isClientsLoading,
  } = useGetClientsQuery({})

  const {
    data: themes,
  } = useAdminListThemesQuery({
    client_id: watch('client_id')!,
  }, {
    skip: !watch('client_id'),
  })

  const themeOptions = useMemo(() => {
    return [
      { value: 0, alt: 'No Theme' },
      ...themes?.map((theme) => ({ value: theme.id, alt: theme.title })) ?? [],
    ]
  }, [themes])

  const clientOptions = useMemo(() => {
    let unpaginatedClients = clients as Client[]
    return unpaginatedClients?.map((client) => ({ value: client.id, text: client.display_name }))
  }, [clients])

  const assetTypeOptions = useMemo(() => {
    return Object.values(AssetType)
      .map((type) => ({
        value: type,
        text: FriendlyAssetType[type],
      }))
  }, [])

  const toolbarOptions = [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'link'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }, { 'list': 'ordered' }, { 'list': 'bullet' }],
    ['clean'],
  ]

  return (
    <>
      <FormLayout>
        <FormLayout.Block>
          <FormLayout.Title
            title="Asset Message"
            subtitle="Asset's message information and contents"
          />

          <FormLayout.Group
            label="Type"
            htmlFor="type"
            description="Specify the type of asset"
          >
            <ComboBox
              options={assetTypeOptions}
              placeholder="Select an asset type"
              defaultValue={asset?.type ?? AssetType.KICALL}
              onChange={(option) => {
                if (option) setValue('type', option.value as AssetType)
              }}
            />
            {
              errors.type?.message &&
              <FormError text={errors.type.message} className="mt-1 mb-2 !text-left" />
            }
          </FormLayout.Group>

          <FormLayout.Group
            label="Client"
            htmlFor="client"
            description="Pre-filter themes and users by client"
          >
            <ComboBox
              isLoading={isClientsLoading}
              onChange={(option) => {
                if (option) {
                  setValue('client_id', option.value as number)
                  setValue('theme_id', 0) // No Theme Option
                }
              }}
              options={clientOptions}
              defaultValue={watch('client_id') as number}
              placeholder="Search for a client"
            />
            {
              errors.client_id?.message &&
              <FormError text={errors?.client_id?.message} className="mt-1 mb-2 !text-left" />
            }
          </FormLayout.Group>

          <FormLayout.Group
            label="Theme"
            htmlFor="theme"
            description="Set the theme assigned to this asset"
          >
            <ListBox
              key={watch('client_id')}
              defaultValue={asset?.theme?.id ?? undefined}
              {...register('theme_id')}
              options={themeOptions}
            />
          </FormLayout.Group>

          <FormLayout.Group
            label="URL Parameter"
            htmlFor="masked_uid"
          >
            <Input {...register('masked_uid')} error={errors.masked_uid?.message} disabled={!!asset} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Default Message Name"
            htmlFor="default_message_name"
          >
            <Input {...register('default_message_name')} error={errors.default_message_name?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Default Message"
            htmlFor="default_message_content"
            description="Customize the default message"
          >
            <ReactQuill
              theme="snow"
              value={getValues('default_message_content')}
              placeholder="Write a message and use the toolbar above to style it"
              modules={{ toolbar: toolbarOptions }}
              onChange={(html) => {
                trigger('default_message_content')
                setValue('default_message_content', html, {
                  shouldDirty: getValues('default_message_content') !== defaultValues?.default_message_content,
                })
              }}
            />
            {
              errors?.default_message_content?.message &&
              <FormError text={errors.default_message_content.message} />
            }
          </FormLayout.Group>

          {
            asset &&
            <FormLayout.Group
              label="Timed Messages"
              description="Customise up to 3 timed messages for the asset"
            >
              {
                asset?.messages && asset?.messages.length > 1 &&
                <div className="flex flex-col gap-4">
                  {
                    asset?.messages?.filter((message) => !message.is_default).map((message) => {
                      return (
                        <TimedMessage
                          message={message}
                          key={`timed-message-${message.id}`}
                          to={`/admin/dashboard/assets/manage/${asset.id}/messages/${message.id}`}
                        />
                      )
                    })
                  }
                </div>
              }

              <div className="mt-4 w-full">
                <Button
                  block
                  type="link"
                  href={`/admin/dashboard/assets/manage/${asset.id}/messages/create`}
                  disabled={asset?.messages?.length === 4}
                >
                  Add new message
                </Button>
              </div>
            </FormLayout.Group>
          }

          <FormLayout.Group
            label="Description"
            htmlFor="description"
            description="Set the description for this asset"
          >
            <Input {...register('description')} error={errors.description?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Visibility"
            description="Specify who can view this asset"
            htmlFor="is_private"
          >
            <RadioCardGroup name="is_private" control={control}>
              <RadioCardOption value={false} first>Everyone</RadioCardOption>
              <RadioCardOption value={true} last>Certain People</RadioCardOption>
            </RadioCardGroup>
          </FormLayout.Group>
        </FormLayout.Block>

        <FormLayout.Block>
          <FormLayout.Title
            title="Asset Location"
            subtitle="Assets location specified by address"
          />

          <FormLayout.Group
            label="Address Lookup"
            description="Find and retrieve the assets's address"
          >
            <PostCodeLookup
              onSelect={(object) => {
                setValue('line_1', object.Line1)
                setValue('line_2', object.Line2)
                setValue('town_or_city', object.City)
                setValue('county', object.Province)
                setValue('postcode', object.PostalCode)
              }}
            />
          </FormLayout.Group>

          <FormLayout.Group
            label="Line 1"
            htmlFor="line_1"
          >
            <Input {...register('line_1')} error={errors.line_1?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Line 2"
            htmlFor="line_2"
          >
            <Input {...register('line_2')} error={errors.line_2?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="City"
            htmlFor="town_or_city"
          >
            <Input {...register('town_or_city')} error={errors.town_or_city?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="County"
            htmlFor="county"
          >
            <Input {...register('county')} error={errors.county?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Country"
            htmlFor="country"
          >
            <Input {...register('country')} error={errors.country?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Postcode"
            htmlFor="postcode"
          >
            <Input {...register('postcode')} error={errors.postcode?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Note"
            htmlFor="note"
            description="Define further locational details"
          >
            <Input {...register('note')} error={errors.note?.message} />
          </FormLayout.Group>
        </FormLayout.Block>

        {children}
      </FormLayout>
    </>
  )
}

const AdminAssetForm = ({
  asset,
  onSubmit,
  isLoading,
  errors,
  defaultValues,
  btnTxt,
}: AdminAssetFormProps) => {
  const {
    data: files,
  } = useAdminGetAssetFilesQuery(asset?.id?.toString(), {
    skip: !asset?.id,
  })

  return (
    <>
      <Form<AdminAssetFormInputs, typeof adminAssetSchema>
        error={errors}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        validationSchema={adminAssetSchema}
      >
        {(methods) => (
          <>
            <FormWrapper
              {...methods}
              asset={asset}
            >
              <FormLayout.Footer>
                <Button
                  variant="secondary"
                  className="hidden lg:block"
                  href="/admin/dashboard/assets/browse"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                >
                  {btnTxt}
                </Button>
              </FormLayout.Footer>
            </FormWrapper>
          </>
        )}
      </Form>

      {
        asset && asset?.user_id &&
        <FormLayout>
          {
            files &&
            <FormLayout.Block>
              <FormLayout.Title
                title="Available Files"
                subtitle="Files available for the user which can be linked with their asset"
              />
              <AssetFileTable
                userId={asset.user.id}
                assetId={asset.id}
                files={files.available_files}
              />
            </FormLayout.Block>
          }

          <FormLayout.Block>
            <FormLayout.Title
              title="Third Party Management"
              subtitle="Third parties which are permitted to view this asset"
            />
            <AssetDeviceTable asset={asset} />
          </FormLayout.Block>
        </FormLayout>
      }
    </>
  )
}

export default AdminAssetForm