import Card from 'components/cards/default'

const SignOutPage = () => {
  return (
    <Card className="max-w-lg text-center min-h-min py-12 px-8">
      <h1 className="text-xl mb-3 homepage-header text-primary-gray font-bold">Goodbye</h1>
      <div className="text-sm homepage-body text-gray-500">
        <p>You have been successfully logged out, you can close this window</p>
      </div>
    </Card>
  )
}

export default SignOutPage