import Modal from 'components/modals'
import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import { useDeleteClientMutation } from 'api/endpoints/admin/client'
import { Client } from 'api/types/models/client'

type ModalProps = {
  client: Client | null
  isOpen: boolean
  onClose: () => void
}

const AdminDeleteClientModal = ({
  client,
  isOpen,
  onClose,
}: ModalProps) => {
  const { successToast, errorToast } = useToast()

  const [deleteClient, {
    isLoading: isDeletingClient,
  }] = useDeleteClientMutation()

  const handleDelete = async () => {
    await deleteClient(client!.id)
      .unwrap()
      .then(() => successToast({
        title: 'Client Deleted',
        message: 'The client and associated data have been deleted.',
      }))
      .catch((err) => errorToast({
        title: 'Error Deleting Client',
        message: err?.data?.message ?? 'There was an issue deleting this client, please try again later.',
      }))

    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding>
      <Modal.Header title="Delete Client" />

      <Modal.Content className="space-y-2">
        <p>Are you sure you want to delete <strong>{client?.display_name}</strong>?</p>

        <p>By performing this action, you will be permanently deleting the client and associated contacts, themes, users, and credit blocks.</p>

      </Modal.Content>
      <Modal.Footer className="flex flex-row justify-end gap-2">
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          isLoading={isDeletingClient}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AdminDeleteClientModal