import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { User } from 'api/types/models/user'
import QueryTags from 'api/types/QueryTags'
import AdminUserGetRequest from 'api/types/requests/admin/user/get'
import AdminUserShowRequest from 'api/types/requests/admin/user/show'
import AdminUserStoreRequest from 'api/types/requests/admin/user/store'
import AdminUserUpdateRequest from 'api/types/requests/admin/user/update'
import PaginatedResponse from 'api/types/requests/PaginatedResponse'
import { stringify } from 'qs'

const adminUserEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  adminGetUsers: builder.query<PaginatedResponse<User[]>, AdminUserGetRequest>({
    query: ({ page, sort }) => ({
      url: '/admin/users',
      params: {
        ...(page && { page }),
        ...(sort && { sort: stringify(sort) }),
      },
    }),
    providesTags: ['users'],
  }),

  adminListUsers: builder.query<{ users: User[] }, { search?: string, clientId?: number }>({
    query: ({ search, clientId }) => ({
      url: '/admin/users/list',
      params: {
        ...(clientId && { client_id: clientId }),
        ...(search && { search }),
      },
    }),
    providesTags: ['users'],
  }),

  adminShowUser: builder.query<User, AdminUserShowRequest>({
    query: ({ id }) => ({
      url: `/admin/users/${id}`,
    }),
    providesTags: ['user'],
  }),

  adminStoreUser: builder.mutation<User, AdminUserStoreRequest>({
    query: (body) => ({
      url: '/admin/users',
      method: 'post',
      body,
    }),
    invalidatesTags: ['users', 'user'],
  }),

  adminUpdateUser: builder.mutation<User, AdminUserUpdateRequest>({
    query: ({ id, ...body }) => ({
      url: `/admin/users/${id}`,
      method: 'put',
      body,
    }),
    invalidatesTags: ['users', 'user'],
  }),

  adminDeleteUser: builder.mutation<void, { id: number }>({
    query: ({ id }) => ({
      url: `/admin/users/${id}`,
      method: 'delete',
    }),
    invalidatesTags: ['users'],
  }),
})

export default adminUserEndpoints