import Card from 'components/cards/default'
import { Title } from 'components/headings'
import NotificationToast from 'components/notifications'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useRegisterAssetMutation } from 'api'
import Button from 'components/button'
import { RootState } from 'store'
import AssetConfirmationCard from 'components/cards/asset/confirmation'

const AssetRegisterPage = () => {
  const navigate = useNavigate()
  const { notify } = useNotifier()

  const { user, scannedAsset } = useSelector((state: RootState) => ({
    user: state.auth.user,
    scannedAsset: state.asset.scanned_item,
  }))

  const [registerAsset, { data: registerResult, isLoading }] = useRegisterAssetMutation()
  const onSubmit = () => {
    if (user && scannedAsset) {
      registerAsset({ user_id: user?.id, masked_uid: scannedAsset?.masked_uid })
    }
  }

  useEffect(() => {
    if (registerResult) {
      notify(<NotificationToast message="KiCall registered successfully" />)
      navigate(`/asset/manage/${scannedAsset?.masked_uid}`)
    }
  }, [registerResult])

  return (
    <Card className="max-w-lg">
      <Title>Confirmation</Title>
      <div className="w-full max-w-xs mx-auto mt-6 mb-12">
        {scannedAsset && <AssetConfirmationCard asset={scannedAsset} />}
      </div>
      <div className="flex flex-col gap-2">
        <Button onClick={() => onSubmit()} block isLoading={isLoading}>Register</Button>
        <Button href="/" variant="link" className="text-sm" block>Cancel</Button>
      </div>
    </Card>
  )
}

export default AssetRegisterPage