import { useAdminDeleteUserFilesMutation } from 'api'
import Button from 'components/button'
import Modal from 'components/modals'
import NotificationToast from 'components/notifications'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'

type FormInputs = {
  user_id: number
}

type Props = {
  userId?: number
  isOpen: boolean
  onClose: () => void
}

const DeleteUserFilesModal = ({ userId, isOpen, onClose = () => {} }: Props) => {
  const { notify } = useNotifier()
  const { handleSubmit } = useForm<FormInputs>()
  const [deleteFiles, { isSuccess, isError, isLoading }] = useAdminDeleteUserFilesMutation()

  const onSubmit = () => {
    if (userId) {
      deleteFiles({ userId: userId })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      notify(<NotificationToast message="User files deleted successfully" />)
      onClose()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      notify(<NotificationToast type="error" message="There was a problem deleting these user's files, try again soon" />)
      onClose()
    }
  }, [isError])

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding>
      <Modal.Header title="Remove User Files" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <p>Are you sure you want to remove all files related to this user?</p>
          <p>This will remove any files linked to Assets also.</p>
        </Modal.Content>
        <Modal.Footer className="flex flex-row justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button variant="danger" type="submit" isLoading={isLoading}>Delete All Files</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default DeleteUserFilesModal