import dataApi from 'api'
import { Message } from 'api/types/models/message'
import { CreateMessageRequest } from 'api/types/requests/message/create-message'
import { GetMessageRequest } from 'api/types/requests/message/get-message'
import { GetMessagesRequest } from 'api/types/requests/message/get-messages'
import { UpdateMessageRequest } from 'api/types/requests/message/update-message'

const messageBaseUrl = '/messages'

const messageEndpoints = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    createMessage: builder.mutation<Message, CreateMessageRequest>({
      query: (body) => ({
        url: messageBaseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['messages'],
    }),

    getMessages: builder.query<Message[], GetMessagesRequest>({
      query: (params) => ({
        url: messageBaseUrl,
        params,
      }),
      providesTags: ['messages'],
    }),

    getMessage: builder.query<Message, GetMessageRequest>({
      query: ({ id }) => ({
        url: `${messageBaseUrl}/${id}`,
      }),
      providesTags: ['message'],
    }),

    updateMessage: builder.mutation<Message, UpdateMessageRequest>({
      query: ({ id, ...body }) => ({
        url: `${messageBaseUrl}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['messages', 'message', 'admin_asset'],
    }),

    deleteMessage: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `${messageBaseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['messages', 'message', 'admin_asset', 'admin_assets'],
    }),
  }),
})

export const {
  useCreateMessageMutation,
  useGetMessagesQuery,
  useGetMessageQuery,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
} = messageEndpoints