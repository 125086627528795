import { DateTime } from 'luxon'
import { z } from 'zod'

export const timePeriodSchema = z.object({
  start_time: z.string().min(1, 'Start time is required'),
  end_time: z.string().min(1, 'End time is required'),
}).refine((data) => {
  const startTime = DateTime.fromISO(data.start_time)
  const endTime = DateTime.fromISO(data.end_time)

  return startTime < endTime
}, {
  message: 'Start time must be before end time',
  path: ['start_time'],
})