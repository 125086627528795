import { useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useRequestAssetAccessMutation } from 'api'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNotifier } from 'react-headless-notifier'
import useErrorHandler from 'hooks/useErrorHandler'
import Button from 'components/button'
import IssueTypes from 'enums/IssueType'
import TextArea from 'components/form/textarea'
import Phone, { PhoneInputProps } from 'components/form/phone'
import NotificationToast from 'components/notifications'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import Modal from 'components/modals'
import Input from 'components/form/input'
import AssetNotificationMethod from 'enums/AssetNotificationMethod'

type FormInputs = { 
  issue_type: IssueTypes
  description: string
  phone: string
  country_code: string
  masked_uid: string
  name: string
} 

type ModalProps = {
  defaultPhone?: string
  defaultCountryCode?: string
  isOpen: boolean
  onClose: () => void
}

const RequestAccessModal = ({ isOpen, onClose, defaultPhone, defaultCountryCode }: ModalProps) => {
  const errorHandler = useErrorHandler()
  const { notify } = useNotifier()

  const { scannedAsset } = useSelector((state: RootState) => ({
    scannedAsset: state.asset.scanned_item,
  }))

  const [assetRequest, { error: assetRequestError, isSuccess: assetRequestSuccessful, isLoading }] = useRequestAssetAccessMutation()
  const { register, handleSubmit, setError, clearErrors, setValue, formState: { errors }, reset } = useForm<FormInputs>()

  const onSubmit = (data: FormInputs) => {
    if (!scannedAsset) {
      return notify(<NotificationToast type="error" message="There was a problem sending your request" />)
    }

    data.masked_uid = scannedAsset.masked_uid
    assetRequest({
      ...data,
    })
  }

  // defaultValues will not work here, but this will?
  useEffect(() => {
    if (isOpen) {
      reset({
        issue_type: 'Other' as IssueTypes,
        phone: defaultPhone ?? '',
        country_code: defaultCountryCode ?? '44',
        description: 'I would like access to view this KiCall message.',
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (assetRequestSuccessful) {
      let successNotificationMessage = ''

      switch (scannedAsset?.notification_method) {
        case AssetNotificationMethod.EMAIL:
          successNotificationMessage = 'Your request has been sent. You can expect to receive a response within 48 hours.'
          break
        case AssetNotificationMethod.SMS:
          successNotificationMessage = 'Your request has been sent. You can expect to hear back soon.'
          break
        default:
          successNotificationMessage = 'Your request has been sent'
      }

      notify(<NotificationToast
        message={successNotificationMessage}
      />)
      
      onClose()
    }
  }, [assetRequestSuccessful])

  useEffect(() => {
    if (assetRequestError) {
      errorHandler(assetRequestError, setError)
      switch ((assetRequestError as FetchBaseQueryError).status) {
        case 429:
          notify(<NotificationToast type="error" message="You have sent too many requests recently, please wait a couple of minutes before retrying." />)
          break
        default:
          notify(<NotificationToast type="error" message="There was a problem sending your request" />)
      }
    }
  }, [assetRequestError])

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone)
    setValue('country_code', phone.country_code)

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding beforeEnter={reset}>
      <Modal.Header title="Request Access" description="The owner of this KiCall will receive your request" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content className="flex flex-col gap-2">
          <div className="flex lg:flex-row flex-col gap-2">
            <Input 
              label="Name"
              error={errors?.name?.message}
              {...register('name')}
            />
            <Phone
              defaultCountryCode={defaultCountryCode}
              defaultPhone={defaultPhone}
              onChange={(phone) => handlePhoneOnChange(phone)} 
              error={errors?.phone?.message || errors?.country_code?.message} 
            />
          </div>
          <TextArea 
            {...register('description')}
            label="Message"
            error={errors?.description?.message} />
        </Modal.Content>
        <Modal.Footer className="flex flex-row justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={!scannedAsset} isLoading={isLoading}>Request</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default RequestAccessModal