import 'styles/quill.css'
import Button from 'components/button'
import Spinner from 'components/spinner'
import 'react-quill/dist/quill.snow.css'
import { useToast } from 'hooks/useToast'
import { useEffect, useState } from 'react'
import Card from 'components/cards/default'
import { TabItem, Tabs } from 'components/tabs'
import ArrowIcon from 'components/icons/ArrowIcon'
import { AssetDetailForm } from 'components/forms/asset/detail'
import { ConfirmationModal } from 'components/modals/confirmation'
import AssetNotificationMethod from 'enums/AssetNotificationMethod'
import { AssetDetailFormInputs } from 'components/forms/asset/detail'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useShowAssetQuery, useUpdateAssetMutation } from 'api'
import { AssetLocationForm, AssetLocationFormInputs } from 'components/forms/asset/location'
import { AssetNotificationForm, AssetNotificationFormInputs } from 'components/forms/asset/notification'
import { AssetSecurityForm } from 'components/forms/asset/security'

type AssetFormInputs = AssetDetailFormInputs & AssetNotificationFormInputs & AssetLocationFormInputs

enum AssetTabTypes {
  DETAILS = 'details',
  NOTIFICATIONS = 'notifications',
  LOCATIONS = 'locations',
  SECURITY = 'security',
}

const assetTabs = [
  { id: 'asset-tab-1', value: AssetTabTypes.DETAILS, displayName: 'Details' },
  { id: 'asset-tab-2', value: AssetTabTypes.NOTIFICATIONS, displayName: 'Notifications' },
  { id: 'asset-tab-3', value: AssetTabTypes.LOCATIONS, displayName: 'Locations' },
  { id: 'asset-tab-4', value: AssetTabTypes.SECURITY, displayName: 'Security' },
]

const AssetShowPage = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { uid } = useParams()

  const { errorToast } = useToast()

  const [isFormDirty, setIsFormDirty] = useState(false)
  const [hasTabChanged, setHasTabChanged] = useState(false)
  const [selectedTab, setSelectedTab] = useState<TabItem | null>(null)

  const {
    data: asset,
    isLoading: isAssetLoading,
    isError: getAssetError,
  } = useShowAssetQuery({
    id: uid!,
  }, { skip: !uid })

  useEffect(() => {
    if (getAssetError) {
      errorToast({ message: 'There was a problem loading this asset' })
      navigate('/asset/manage/list')
    }
  }, [getAssetError])

  const [updateAsset, {
    isLoading: isUpdating,
    error: updateError,
    reset: resetUpdateError,
  }] = useUpdateAssetMutation()

  const [isFormUpdatedSuccessfully, setIsFormUpdatedSuccessfully] = useState<boolean>(false)

  const handleUpdateAsset = async (formInputs: AssetDetailFormInputs | AssetNotificationFormInputs | AssetLocationFormInputs) => {
    if (!asset?.asset) return

    const currentAsset = asset?.asset

    const newAsset: AssetFormInputs = {
      ...currentAsset,
      ...formInputs,
    }

    await updateAsset({
      masked_uid: uid!,
      ...newAsset,
    })
      .unwrap()
      .then(() => {
        setIsFormDirty(false)
        setHasTabChanged(false)

        setIsFormUpdatedSuccessfully(true)
        setTimeout(() => {
          setIsFormUpdatedSuccessfully(false)
        }, 3000)

      })
      .catch((err) => {
        if (err?.status == 422) return

        errorToast({
          title: 'Asset Update Failed',
          message: 'There was a problem updating your asset, please try again later',
        })
      })
  }

  useEffect(() => {
    const currentTab = searchParams.get('tab')
    setSearchParams({ tab: currentTab ?? 'details' })
  }, [])

  const activeTab = assetTabs?.find(tab => tab.value === searchParams.get('tab'))

  if (isAssetLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <Card>
      <div className="flex justify-between">
        <Button
          href="/asset/manage/list"
          type="link"
          variant="secondary"
          icon={<ArrowIcon className="w-5 h-5" />}
          isIconOnly
        />
      </div>

      <Tabs
        key={selectedTab?.id}
        className="mt-6"
        value={assetTabs}
        selectedTabIdx={assetTabs?.findIndex(tab => tab.value === searchParams.get('tab')) ?? 0}
        onChange={(tabIdx) => {
          const selectedTab = assetTabs?.[tabIdx]
          if (selectedTab) {
            isFormDirty
              ? setHasTabChanged(true)
              : setSearchParams({ tab: selectedTab.value })

            setSelectedTab(selectedTab)
          }
        }}
      />

      <div className="mt-4">
        <Tabs.Panel show={activeTab?.value === AssetTabTypes.DETAILS}>
          <AssetDetailForm
            asset={asset?.asset}
            onSubmit={handleUpdateAsset}
            isLoading={isUpdating}
            errors={updateError}
            isSuccess={isFormUpdatedSuccessfully}
            onDirty={(isDirty) => setIsFormDirty(isDirty)}
            defaultValues={{
              description: asset?.asset?.description ?? '',
              is_private: asset?.asset?.is_private ?? false,
              public_message: asset?.asset?.public_message ?? '',
              distribution_usage: asset?.asset?.distribution_usage ?? null,
            }}
          />
        </Tabs.Panel>

        <Tabs.Panel show={activeTab?.value === AssetTabTypes.NOTIFICATIONS}>
          <AssetNotificationForm
            onSubmit={handleUpdateAsset}
            onDirty={(isDirty) => setIsFormDirty(isDirty)}
            isLoading={isUpdating}
            errors={updateError}
            isSuccess={isFormUpdatedSuccessfully}
            defaultValues={{
              notification_method: asset?.asset?.notification_method ?? AssetNotificationMethod.SMS,
              notification_enabled: asset?.asset?.notification_enabled ?? false,
              alt_name: asset?.asset?.alt_name ?? '',
              alt_email: asset?.asset?.alt_email ?? '',
              alt_country_code: asset?.asset?.alt_country_code ?? '44',
              alt_phone: asset?.asset?.alt_phone ?? '',
            }}
          />
        </Tabs.Panel>

        <Tabs.Panel show={activeTab?.value === AssetTabTypes.LOCATIONS}>
          <AssetLocationForm
            onSubmit={handleUpdateAsset}
            onDirty={(isDirty) => setIsFormDirty(isDirty)}
            isLoading={isUpdating}
            errors={updateError}
            isSuccess={isFormUpdatedSuccessfully}
            defaultValues={{
              line_1: asset?.asset?.line_1 ?? '',
              line_2: asset?.asset?.line_2 ?? '',
              town_or_city: asset?.asset?.town_or_city ?? '',
              county: asset?.asset?.county ?? '',
              country: asset?.asset?.country ?? '',
              postcode: asset?.asset?.postcode ?? '',
              note: asset?.asset?.note ?? '',
            }}
          />
        </Tabs.Panel>

        <Tabs.Panel show={activeTab?.value === AssetTabTypes.SECURITY}>
          <AssetSecurityForm
            asset={asset?.asset}
            onSubmit={handleUpdateAsset}
            onDirty={(isDirty) => setIsFormDirty(isDirty)}
            isLoading={isUpdating}
            errors={updateError}
          />
        </Tabs.Panel>
      </div>

      <ConfirmationModal
        title="Unsaved Changes"
        isOpen={hasTabChanged && isFormDirty}
        onClose={() => {
          setHasTabChanged(false)
          setSelectedTab(null)
        }}
        renderContent={() => (
          <p>The page you are trying to leave has unsaved changes. Are you sure you want to leave?</p>
        )}
        onConfirm={() => {
          resetUpdateError()
          setSearchParams({ tab: selectedTab?.value ?? 'detail' })
          setIsFormDirty(false)
          setHasTabChanged(false)
        }}
      />
    </Card >
  )
}

export default AssetShowPage