import Button from 'components/button'
import { WeekDay } from 'enums/WeekDay'
import Card from 'components/cards/default'
import ArrowIcon from 'components/icons/ArrowIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateMessageMutation } from 'api/endpoints/user/message'
import { MessageForm, MessageInputs } from 'components/forms/message'
import { useToast } from 'hooks/useToast'
import { useShowAssetQuery } from 'api'

export function AssetCreateMessagePage() {
  const navigate = useNavigate()

  const { successToast } = useToast()
  const { uid } = useParams()

  const [createMessage, {
    isLoading: isCreatingMessage,
    error: createMessageError,
  }] = useCreateMessageMutation()

  const { data: asset } = useShowAssetQuery({
    id: uid!,
  }, { skip: !uid })

  const handleCreateMessage = async (inputs: MessageInputs) => {
    await createMessage({
      asset_id: asset?.asset?.id!,
      name: inputs.name,
      content: inputs.content,
      is_default: inputs.is_default,
      time_frames: 'days_of_week' in inputs
        ? inputs?.days_of_week?.map(day => ({
          day_of_week: day as WeekDay,
          start_time: inputs?.start_time as string,
          end_time: inputs?.end_time as string,
        }))
        : [],
    })
      .unwrap()
      .then(() => {
        navigate(`/asset/manage/${uid}`)
        successToast({ message: 'Message has been created' })
      })
      .catch(() => { /* Errors directly handled by form */ })
  }

  return (
    <Card>
      <div className="flex justify-between">
        <Button
          href={`/asset/manage/${uid}`}
          type="link"
          variant="secondary"
          icon={<ArrowIcon className="w-5 h-5" />}
          isIconOnly
        />
      </div>

      <MessageForm
        btnTxt="Create Message"
        errors={createMessageError}
        isLoading={isCreatingMessage}
        onSubmit={handleCreateMessage}
        defaultValues={{
          asset_id: undefined,
          name: '',
          content: '',
          is_default: false,
          days_of_week: [],
          start_time: '09:00',
          end_time: '17:00',
        }}
      />
    </Card>
  )
}