import { useDetachDeviceMutation } from 'api'
import Asset from 'api/types/models/asset'
import Device from 'api/types/models/device'
import Button from 'components/button'
import Modal from 'components/modals'
import { useToast } from 'hooks/useToast'
import { useForm } from 'react-hook-form'

type FormInputs = {
  from_all: boolean
}

type Props = {
  asset: Asset
  device: Device
  isOpen: boolean
  onClose: () => void
}

const DetachDeviceModal = ({ asset, device, isOpen, onClose }: Props) => {
  const { errorToast, successToast } = useToast()

  const [detachDevice, { isLoading }] = useDetachDeviceMutation()
  const { handleSubmit, register, setValue, getValues, reset } = useForm<FormInputs>()

  const onSubmit = async (data: FormInputs) => {
    detachDevice({
      id: device.id,
      body: {
        ...data,
        masked_uid: asset.masked_uid,
      }})
      .unwrap()
      .then(() => {
        const successMessage = data.from_all
          ? 'This person\'s access has been revoked successfully from all owned KiCalls'
          : 'This person\'s access has been revoked successfully from this KiCall'

        successToast({
          title: 'Successfully Revoked Access',
          message: successMessage,
        })

        onClose()
        reset()
      })
      .catch((err) => {
        if (err?.status === 429) return

        let errorMessage = 'We\'re unable to revoke this person\'s access at this time. Please try again later.'
        if (err?.data?.type === 'custom') {
          errorMessage = err.data?.message
        }

        errorToast({
          title: 'Failed to Revoke Access',
          message: errorMessage,
        })
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Revoke Access"/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content className="space-y-4">

          <div className="space-y-2">
            <p>Are you sure you want to revoke this person&apos;s access from this asset?</p>
            <p><span className="font-semibold">{device?.name}</span> will no longer be able to access this asset if it is set to private.</p>
          </div>

          <hr />

          <div>
            <div className="flex gap-2" onClick={() => setValue('from_all', !getValues('from_all'))}>
              <input
                {...register('from_all')}
                type="checkbox"
                className="mx-1 h-5 aspect-square focus:bg-primary"
              />
              <p className='cursor-default'>Revoke access from other owned KiCalls</p>
            </div>
          </div>

        </Modal.Content>
        <Modal.Footer className="flex flex-row justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button variant="danger" type="submit" isLoading={isLoading}>Revoke</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default DetachDeviceModal