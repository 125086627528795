import { useGetDevicesQuery } from 'api'
import Device from 'api/types/models/device'
import Button from 'components/button'
import Card from 'components/cards/default'
import { Heading, Title } from 'components/headings'
import PencilIcon from 'components/icons/PencilIcon'
import TrashIcon from 'components/icons/TrashIcon'
import RemoveDeviceModal from 'components/modals/forms/asset_devices/DeleteDeviceModal'
import PhoneUrl from 'components/phone_url'
import Spinner from 'components/spinner'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const DeviceBrowsePage = () => {
  const [removeModal, setRemoveModal] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<Device|undefined>(undefined)

  const { data: deviceData, isLoading: isDevicesLoading } = useGetDevicesQuery()

  return (
    <Card className="min-h-[450px]">

      <Title align='left' className="mt-0">Your People</Title>

      <hr />

      <div className='space-y-4 mt-4'>
        {
          isDevicesLoading &&
          <div className="flex justify-center">
            <Spinner className="!text-primary-gray" />
          </div>
        }
        {
          !isDevicesLoading && deviceData?.devices && deviceData?.devices?.length === 0 &&
          <div className="text-center flex flex-col gap-8 lg:my-10">
            <p className="text-sm m-auto text-gray-400  max-w-md">
              You currently have no contacts, simply add some people to one of your KiCalls to see them listed here
            </p>
            <Link to="/asset/manage/list" className="url-text">
              <strong>Your KiCalls</strong>
            </Link>
          </div>
        }
        {
          !isDevicesLoading && deviceData?.devices && deviceData?.devices?.length > 0 &&
            deviceData?.devices?.map((device) => {
              return (
                <div key={`device-list-${device.id}`} className="flex flex-wrap justify-between items-center">

                  <div>
                    <div className="flex flex-wrap gap-x-2 items-baseline">
                      <Heading align="left">{device.name}</Heading>
                      <p>{device.relationship}</p>
                    </div>
                    <PhoneUrl phone={device.full_phone_number} />
                  </div>

                  <div className="flex gap-2">
                    <Button
                      variant="secondary"
                      isIconOnly
                      icon={<PencilIcon className="w-5 h-5" />}
                      href={`/devices/${device.id}`}
                    />
                    <Button
                      variant="danger"
                      isIconOnly
                      icon={<TrashIcon className="w-5 h-5" />}
                      onClick={() => {
                        setSelectedDevice(device)
                        setRemoveModal(true)
                      }}
                    />
                  </div>

                </div>
              )
            })
        }
      </div>

      <RemoveDeviceModal
        device={selectedDevice!}
        isOpen={removeModal}
        onClose={() => setRemoveModal(false)}
      />

    </Card>
  )
}

export default DeviceBrowsePage