import { useUpdateClientMutation } from 'api/endpoints/admin/client'
import { Client } from 'api/types/models/client'
import { ClientForm, ClientInputs } from 'components/admin/forms/client'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import { useNavigate } from 'react-router-dom'

type ManageDetailsSectionProps = {
  client: Client | undefined
}

export function ManageDetailsSection({ client }: ManageDetailsSectionProps) {
  const navigate = useNavigate()
  
  const { successToast } = useToast()

  const [updateClient, {
    isLoading: updatingClient,
    error: clientUpdateErrors,
  }] = useUpdateClientMutation()
  
  const handleSubmit = async (inputs: ClientInputs) => {
    const primaryContact = client?.contacts?.find(contact => contact.is_primary)
    
    if(primaryContact?.id) {
      await updateClient({ 
        ...inputs,
        id: client!.id,
        contact_id: primaryContact.id,
      }).unwrap()
        .then(() => {
          navigate('/admin/dashboard/clients/browse', { replace: true })
          successToast({ message: 'Client updated successfully' })
        }).catch(() => { /* Form directly handles errors */ })
    }
  }

  return (
    <ClientForm 
      onSubmit={handleSubmit}
      errors={clientUpdateErrors}
      defaultValues={{
        ...client?.contacts?.[0],
        display_name: client?.display_name,
      }}
    >
      <FormLayout.Footer>
        <Button href="/admin/dashboard/clients/browse" variant="secondary">
          Cancel
        </Button>

        <Button type="submit" isLoading={updatingClient}>
          Update Client
        </Button>
      </FormLayout.Footer>
    </ClientForm>
  )
}