import { IconBaseProps } from 'types/icon'

export function TrendingUpArrowIcon({ 
  ariaHidden = true, 
  className, 
}: IconBaseProps) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24" 
      strokeWidth="2" 
      stroke="currentColor" 
      fill="none" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      aria-hidden={ariaHidden}
      className={className}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 17l6 -6l4 4l8 -8" />
      <path d="M14 7l7 0l0 7" />
    </svg>
  )
}