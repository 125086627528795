import { CSSProperties, Fragment, ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'

type ToolTipProps = {
  content: string,
  id: string,
  tooltipStyle?: CSSProperties
  children: ReactNode
}

const ToolTip = ({ content, id, tooltipStyle, children }: ToolTipProps) => {
  return (
    <div id={id}>
      <Tooltip anchorSelect={`#${id}`} content={content} style={tooltipStyle ?? {maxWidth: '15rem'}} />
      { children }
    </div>
  )
}

export default ToolTip