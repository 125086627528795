import { useMemo } from 'react'
import Modal from 'components/modals'
import { useToast } from 'hooks/useToast'
import { ModalBaseProps } from 'types/modal'
import { useNavigate } from 'react-router-dom'
import { RequestAccessView } from './views/request'
import { VerifyAccessRequestFormInputs, VerifyAccessRequestView } from './views/verify'
import { useAdminGetAccessRequestsQuery, useAdminRequestAccessMutation, useAdminVerifyRequestMutation } from 'api/endpoints/admin/request-access'

const steps = {
  request: {
    step: 'request',
    title: 'Request Access',
    description: 'To view this information, you need to request access from the owner.',
  },
  verify: {
    step: 'verify',
    title: 'Verify Request',
    description: 'Verify your request by entering the OTP sent to the owner.',
  },
}

type AdminAccessRequestProps = ModalBaseProps & {
  userId: number | null
  href: string
  afterLeave?: () => void
}

export function AdminAccessRequest({
  userId,
  href,
  isOpen,
  onClose,
}: AdminAccessRequestProps) {
  const navigate = useNavigate()

  const { errorToast, successToast } = useToast()

  const [requestAccess, {
    isLoading: isRequestingAccess,
    error: requestAccessError,
    reset: resetRequestAccessError,
  }] = useAdminRequestAccessMutation()

  const [verifyRequest, {
    isLoading: isVerifyingRequest,
    error: verifyRequestError,
    reset: resetVerifyRequestError,
  }] = useAdminVerifyRequestMutation()

  const {
    data: accessRequests,
    isLoading: isLoadingAccessRequests,
  } = useAdminGetAccessRequestsQuery({}, { skip: !isOpen })

  const handleRequestAccess = async () => {
    await requestAccess({ user_id: userId! })
      .unwrap()
      .catch((err) => {
        if (err?.status === 429) return

        let errorMessage = 'We\'re unable to request access at this time. Please try again later.'
        if (err?.data?.type === 'custom') {
          errorMessage = err.data?.message
        }

        errorToast({
          title: 'Failed to Request Access',
          message: errorMessage,
        })
      })
  }

  const handleVerifyRequest = async (inputs: VerifyAccessRequestFormInputs) => {
    const accessRequest = accessRequests?.find(request => request?.user_id === userId)

    await verifyRequest({
      id: accessRequest!.id,
      code: inputs.code,
    }).unwrap()
      .then(() => {
        navigate(href)
        successToast({
          title: 'Access Granted',
          message: 'You can now view and manage this owners information for 15 minutes',
        })
      })
      .catch((err) => {
        if (err?.status === 422 || err?.status === 429) return

        errorToast({
          title: 'Failed to Verify Request',
          message: 'We\'re unable to verify your request at this time. Please try again later.',
        })
      })
  }

  const activeStep = useMemo(() => {
    const accessRequest = accessRequests?.find(request => request?.user_id === userId)
    return accessRequest
      ? steps.verify
      : steps.request
  }, [accessRequests, userId])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="lg:!max-w-xl"
      loading={isLoadingAccessRequests}
      afterLeave={() => {
        resetRequestAccessError()
        resetVerifyRequestError()
      }}
    >
      <Modal.Header
        title={activeStep.title}
        description={activeStep.description}
      />

      {activeStep.step === 'request' && (
        <RequestAccessView
          onClose={onClose}
          onSubmit={handleRequestAccess}
          isLoading={isRequestingAccess}
          queryErrors={requestAccessError}
        />
      )}

      {activeStep.step === 'verify' && (
        <VerifyAccessRequestView
          onClose={onClose}
          onResend={handleRequestAccess}
          onSubmit={handleVerifyRequest}
          isLoading={isVerifyingRequest}
          isResending={isRequestingAccess}
          queryErrors={verifyRequestError}
        />
      )}
    </Modal>
  )
}