import { useState } from 'react'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import CheckIcon from '../../icons/CheckIcon'
import CrossIcon from '../../icons/CrossIcon'

type ToggleProps = {
  checked?: boolean
  className?: string
  disabled?: boolean
  onChange?: (value: boolean) => void
  showText?: boolean
  showIcon?: boolean
  label?: string
}

const Toggle = ({
  checked = false,
  className,
  disabled = false,
  onChange = () => {},
  showText = false,
  showIcon = false,
  label,
}: ToggleProps) => {
  const innerClasses = classNames('relative inline-flex flex-shrink-0 h-6 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-primary', {
    'bg-secondary-blue cursor-pointer': checked && !disabled,
    'bg-secondary-blue-disabled': checked && disabled,
    'bg-secondary-gray': !checked && disabled,
    'bg-disabled-dark cursor-pointer': !checked && !disabled,
    'w-11': !showText,
    'w-14': showText,
  })

  const handleChange = (value: boolean) => {
    onChange(value)
  }

  return (
    <div className={classNames(className, {
      'flex items-center gap-2 text-sm': label,
    })}>
      <Switch
        checked={checked}
        onChange={(value: boolean) => handleChange(value)}
        className={classNames(innerClasses)}
        disabled={disabled}
      >

        {
          showText &&
          <span className={classNames('text-white absolute text-xs transition-none mx-2', {
            'bottom-[2px] left-0': checked,
            'bottom-[2px] right-0': !checked,
          })}>
            {checked ? 'On' : 'Off'}
          </span>
        }

        {
          showIcon &&
          <span className={classNames('text-white absolute text-xs transition-none mx-1', {
            'bottom-[5px] left-[2px]': checked,
            'bottom-[5px] right-[2px]': !checked,
          })}>
            {checked ? <CheckIcon className="w-[10px] h-[10px]" /> : <CrossIcon className="w-[10px] h-[10px]" />}
          </span>
        }

        <span
          aria-hidden="true"
          className={classNames('pointer-events-none inline-block bg-white h-5 w-5 rounded-full transform ring-0 transition ease-in-out duration-200 z-50', {
            'translate-x-5': checked && (!showText || !showIcon),
            'translate-x-8': checked && showText,

            'translate-x-0': !checked,
          })}
        />
      </Switch>
      {
        label &&
        <p>{label}</p>
      }
    </div>
    
  )
}

export default Toggle