import { useAdminGetAssetEventsQuery } from 'api'
import EventLog from 'api/types/models/event_log'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import FormHeader from 'components/admin/form_header'
import AdminTable from 'components/admin/tables'
import Pagination from 'components/pagination'
import PhoneUrl from 'components/phone_url'
import AssetEvent from 'enums/AssetEvent'
import { formatDate } from 'helpers/dates'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const AdminAssetHistoryPage = () => {
  const params = useParams()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { data: getEvents, isLoading } = useAdminGetAssetEventsQuery({id: params.id!, page: currentPage }, { refetchOnMountOrArgChange: true })

  const getFriendlyEventDescription = (description: string) => {
    switch (description) {
      case AssetEvent.SCAN:
        return 'KiCall Scanned'
      case AssetEvent.SCAN_PRIVATE:
        return 'KiCall Accessed'
      case AssetEvent.UPDATE:
        return 'KiCall Updated'
      case AssetEvent.ACCESS_REQUEST:
        return 'KiCall Request Received'
      case AssetEvent.REGISTER:
        return 'KiCall Registered'
      default:
        return 'Unknown KiCall Event'
    }
  }

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Asset History"
        description="This history of an asset, including it's scans and updates"
        backBtnText="Back to Assets"
        backBtnUrl="/admin/dashboard/assets/browse"
      />
      <div className="flex flex-col gap-12 p-10">

        <div className="space-y-4">
          <FormLayout.Title
            title="Statistics"
            subtitle="Asset's statistics for successful scans and accesses"
          />
          <AdminTable className="max-w-sm">
            <AdminTable.Head>
              <AdminTable.Heading>#</AdminTable.Heading>
              <AdminTable.Heading>Last 10 Days</AdminTable.Heading>
              <AdminTable.Heading>Lifetime</AdminTable.Heading>
            </AdminTable.Head>
            <AdminTable.Body>
              <AdminTable.Row>
                <AdminTable.Column>Number of Scans</AdminTable.Column>
                <AdminTable.Column>{getEvents?.statistics.scans.recent.toString()}</AdminTable.Column>
                <AdminTable.Column>{getEvents?.statistics.scans.total.toString()}</AdminTable.Column>
              </AdminTable.Row>
              <AdminTable.Row>
                <AdminTable.Column>Number of Accesses</AdminTable.Column>
                <AdminTable.Column>{getEvents?.statistics.accessed.recent.toString()}</AdminTable.Column>
                <AdminTable.Column>{getEvents?.statistics.accessed.total.toString()}</AdminTable.Column>
              </AdminTable.Row>
            </AdminTable.Body>
          </AdminTable>
        </div>

        <div className="space-y-4">
          <FormLayout.Title
            title="Event Log"
            subtitle="Asset's log of successful or failed events"
          />
          <AdminTable isLoading={isLoading}>
            <AdminTable.Head>
              <AdminTable.Heading>ID</AdminTable.Heading>
              <AdminTable.Heading>User ID</AdminTable.Heading>
              <AdminTable.Heading>Description</AdminTable.Heading>
              <AdminTable.Heading>Phone</AdminTable.Heading>
              <AdminTable.Heading>IP</AdminTable.Heading>
              <AdminTable.Heading>Status</AdminTable.Heading>
              <AdminTable.Heading>Created At</AdminTable.Heading>
            </AdminTable.Head>
            <AdminTable.Body>
              {
                getEvents && getEvents?.eventLog?.data?.length > 0 ?
                  getEvents.eventLog.data.map((item: EventLog, index: number) => (
                    <AdminTable.Row key={index}>
                      <AdminTable.Column>{item.id?.toString()}</AdminTable.Column>
                      <AdminTable.Column>{item.user_id?.toString()}</AdminTable.Column>
                      <AdminTable.Column>{getFriendlyEventDescription(item.description)}</AdminTable.Column>
                      <AdminTable.Column>
                        {
                          item.phone &&
                          <PhoneUrl phone={item.phone} />
                        }
                      </AdminTable.Column>
                      <AdminTable.Column>{item.ip}</AdminTable.Column>
                      <AdminTable.Column>
                        {
                          item.is_success
                            ? <p className="px-2 text-xs text-green-600 bg-green-100 rounded-md max-w-min">Success</p>
                            : <p className="px-2 text-xs text-red-600 bg-red-100 rounded-md max-w-min">Failure</p>
                        }
                      </AdminTable.Column>
                      <AdminTable.Column>{formatDate(item.created_at, 'dd/LL/yy HH:mm:ss')}</AdminTable.Column>
                    </AdminTable.Row>
                  )) : <></>
              }
            </AdminTable.Body>
          </AdminTable>
        </div>

        <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
          <Pagination onPage={currentPage} onPageChange={(page) => setCurrentPage(page)} totalPages={getEvents?.eventLog?.meta?.last_page ?? 0} />
        </div>
      </div>
    </div>
  )
}

export default AdminAssetHistoryPage