import AdminTable from 'components/admin/tables'
import { ClientTableSkeletonRow } from './skeleton'
import { Link } from 'react-router-dom'
import { Client } from 'api/types/models/client'
import { FormatNumber } from 'components/format-number'

type ClientTableProps = {
  value?: Client[]
  onDelete: (client: Client) => void,
  isLoading?: boolean
}

export function ClientTable({ value, onDelete, isLoading }: ClientTableProps) {
  return (
    <AdminTable>
      <AdminTable.Head>
        <AdminTable.Heading>#</AdminTable.Heading>
        <AdminTable.Heading>Name</AdminTable.Heading>
        <AdminTable.Heading>Contact Name</AdminTable.Heading>
        <AdminTable.Heading>Contact Phone</AdminTable.Heading>
        <AdminTable.Heading>Contact Email</AdminTable.Heading>
        <AdminTable.Heading>Credit Usage</AdminTable.Heading>
        <AdminTable.Heading>Actions</AdminTable.Heading>
      </AdminTable.Head>
    
      <AdminTable.Body>
        {
          isLoading && (
            <>
              {Array.from({ length: 10 }).map((_, idx) => (
                <ClientTableSkeletonRow key={idx} />
              ))}
            </>
          )
        }

        {
          !isLoading && !value?.length && (
            <AdminTable.Row>
              <AdminTable.Column colSpan={7} className="text-center">
                <span className="text-base">
                  No clients found
                </span>
              </AdminTable.Column>
            </AdminTable.Row>
          )
        }

        {
          value?.map((client) => (
            <AdminTable.Row key={client.id}>
              <AdminTable.Column>
                {client.id}
              </AdminTable.Column>
            
              <AdminTable.Column>
                {client.display_name}
              </AdminTable.Column>

              <AdminTable.Column>
                {client?.contacts?.[0]?.full_name}
              </AdminTable.Column>

              <AdminTable.Column>
                {client?.contacts?.[0]?.full_phone_number}
              </AdminTable.Column>

              <AdminTable.Column>
                {client?.contacts?.[0]?.email}
              </AdminTable.Column>

              <AdminTable.Column>
                <FormatNumber value={client?.total_spent ?? 0} /> / <FormatNumber value={client?.total_credits ?? 0} />
              </AdminTable.Column>

              <AdminTable.Column>
                <div className="flex mx-1 text-sm divide-x">
                  <Link to={`/admin/dashboard/clients/manage/${client.id}`} className="px-1 font-normal url-text">
                    Manage
                  </Link>
                  <button className="px-1 text-red-500" onClick={() => onDelete(client)}>
                    Delete
                  </button>
                </div>
              </AdminTable.Column>
            </AdminTable.Row>
          ))
        }
      </AdminTable.Body>
    </AdminTable>
  )
}