import { FormatNumber } from 'components/format-number'

type LabelledProps = {
  label: string
  value: number | undefined | null
  color?: string
}

export function Labelled({
  label,
  value,
  color = 'primary',
}: LabelledProps) {
  return (
    <div className="flex flex-col">
      <span className='text-sm'>{label}</span>
      <div className={`text-2xl font-semibold text-${color}`}>
        <span>
          {value ?? '-'}
        </span>
      </div>
    </div>
  )
}