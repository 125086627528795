const CrossIcon = ({ className }: { className?: string }) => {
  return (
    <svg viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M5.72526 4.20443L9.04297 0.889323C9.24349 0.688802 9.24349 0.364583 9.04297 0.165365C8.84245 -0.0351562 8.51823 -0.0351562 8.31901 0.165365L5 3.47917L1.68099 0.164062C1.48047 -0.0364583 1.15625 -0.0364583 0.957031 0.164062C0.75651 0.364583 0.75651 0.688802 0.957031 0.888021L4.27474 4.20312L0.957031 7.51953C0.75651 7.72005 0.75651 8.04427 0.957031 8.24349C1.05729 8.34375 1.1888 8.39323 1.31901 8.39323C1.45052 8.39323 1.58073 8.34375 1.68099 8.24349L5 4.92839L8.31901 8.24479C8.41927 8.34505 8.54948 8.39453 8.68099 8.39453C8.8125 8.39453 8.94271 8.34505 9.04297 8.24479C9.24349 8.04427 9.24349 7.72005 9.04297 7.52083L5.72526 4.20443Z" fill="currentColor"/>
    </svg>
  )
}



export default CrossIcon