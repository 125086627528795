import classNames from 'classnames'
import { PropsWithChildren } from 'react'

type FormControlProps = PropsWithChildren<{
  className?: string
}>

export function FormControl({ className, children }: FormControlProps) {
  return (
    <div className={classNames('w-full', className)}>
      {children}
    </div>
  )
}