import { countries, Country } from 'countries-list'
import {useEffect, useState } from 'react'
import '../../../styles/phoneInput.css'
import FormError from '../../error'
import Input from '../input'

export type PhoneInputProps = {
  phone: string
  country_code: string
}

type Props = {
  control?: any
  disabled?: boolean
  defaultValue?: string
  defaultPhone?: string
  placeHolder?: string
  defaultCountryCode?: string
  onChange: (e: PhoneInputProps) => void
  error?: string
  label?: string
}

const Phone = ({ disabled = false, placeHolder, defaultPhone = '', defaultCountryCode = '44', onChange, error }: Props) => {
  const [phone, setPhone] = useState(defaultPhone)
  const [countryCode, setCountryCode] = useState(defaultCountryCode)

  const packagedCountries = countries

  const acceptedCountries = ['GB', 'FR', 'DE', 'AE']
  let countriesArray: Country[] = []

  Object.keys(packagedCountries).map((countries, index) => {
    if (acceptedCountries.includes(countries)) {
      return index
    }
  })
    .filter((keys) => keys !== undefined)
    .map((key) => key && countriesArray.push(Object.values(packagedCountries)[key]) )

  useEffect(() => {
    if (defaultPhone !== phone) {
      setPhone(defaultPhone)
    }
  }, [defaultPhone])

  useEffect(() => {
    onChange({ phone: phone, country_code: countryCode })
  }, [phone, countryCode])

  const cleanPhoneNumber = (phone: string) => {
    return phone.trim().replaceAll(' ', '')
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex">
        <select 
          className="px-3 border appearance-none border-secondary-gray rounded-l-md outline-secondary-blue" 
          defaultValue={countryCode} 
          onChange={(element) => setCountryCode(element.target.value)}
          disabled={disabled}>
          {
            countriesArray.map((country, index) => (
              <option 
                key={index} 
                value={country.phone}
              >
                {country.emoji} +{country.phone}
              </option>
            ))
          }
        </select>
        <Input
          value={phone}
          onChange={(element) => setPhone(cleanPhoneNumber(element.target.value))} 
          className="border-l-0 rounded-l-none"
          placeholder={placeHolder}
          type="text" 
          onPaste={(e) => {
            e.preventDefault()
            let paste = e.clipboardData.getData('text')
            paste = cleanPhoneNumber(paste)
            setPhone(paste)
          }}
          maxLength={15}
          inputMode="numeric"
          pattern="[0-9]*" 
          disabled={disabled}
        />
      </div>
      {
        error &&
        <FormError className="mt-1 !text-left" text={error} />
      }          
    </div>
  )
}

export default Phone