import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import { ModalBaseProps } from 'types/modal'
import { useDeleteMessageMutation } from 'api/endpoints/user/message'
import Modal from 'components/modals'
import { Message } from 'api/types/models/message'
import React from 'react'

type ModalProps = ModalBaseProps & {
  message: Message | undefined
  onConfirm: () => void
  renderContent?: React.ReactNode
}

export function DeleteMessageModal({
  message,
  isOpen,
  onClose,
  onConfirm,
  renderContent = undefined,
}: ModalProps) {
  const { successToast, errorToast } = useToast()

  const [deleteMessage, {
    isLoading: isDeletingMessage,
  }] = useDeleteMessageMutation()

  const handleDelete = async () => {
    await deleteMessage(message!.id)
      .unwrap()
      .then(() => {
        onConfirm()
        successToast({ message: 'Message deleted successfully' })
      })
      .catch(() => {
        onClose()
        errorToast({ message: 'There was an issue deleting this message, please try again later.' })
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding>
      <Modal.Header title="Delete Message" />

      <Modal.Content className="space-y-2">
        {
          !renderContent
            ? (
              <>
                <p>You&apos;re about to delete the message <strong>{message?.name ?? ''}</strong> from this asset.</p>
                <p>By performing this action, associated time frames will also be permanently removed.</p>
              </>
            ) : renderContent
        }
      </Modal.Content>
      <Modal.Footer className="flex flex-row justify-end gap-2">
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          isLoading={isDeletingMessage}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}