import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useDispatch } from 'react-redux'
import { logout } from 'slices/auth'
import { useIdleTimer } from 'react-idle-timer'
import { useEffect, useState } from 'react'
import Button from 'components/button'
import useTimer from 'hooks/useTimer'
import Modal from 'components/modals'
import { useAdminLogoutMutation } from 'api'
import TimeConfig from 'config/time'

const ProtectedRoute = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { time, stopTimer, startTimer } = useTimer(30)

  const [inactivityModal, setInactivityModal] = useState(false)
  const [submitLogout] = useAdminLogoutMutation()

  const { isAuthenticated } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
  }))

  const onIdle = () => {
    setInactivityModal(true)
    startTimer()
  }

  const extendSession = () => {
    stopTimer()
    setInactivityModal(false)
  }

  const idleTimer = useIdleTimer({ onIdle, timeout: TimeConfig.FIVE_MINUTES })

  const triggerLogout = async () => {
    await submitLogout()
    dispatch(logout())
    navigate('/sign-out')
  }

  useEffect(() => {
    if (time <= 0) {
      triggerLogout()
    }
  }, [time])

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return (
    <div className="flex flex-col w-full h-full mx-auto">
      <Outlet />
      <Modal
        isOpen={inactivityModal}
        onClose={() => setInactivityModal(false)}
      >
        <Modal.Header title='Session Timeout' />
        <Modal.Content>
          <p className='mr-5'>Your session will expire in <span className='font-bold'>{time}</span> seconds. Do you want to extend the session?</p>
        </Modal.Content>
        <Modal.Footer>
          <div className='flex justify-end gap-x-2'>
            <Button onClick={extendSession}>Extend session</Button>
            <Button onClick={() => triggerLogout()}>Logout</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ProtectedRoute
