import MagnifyingGlass from 'components/icons/MagnifyingGlass'
import Spinner from 'components/spinner'

type SearchInputProps = {
  value: string
  placeHolder?: string
  onChange?: (value: string) => void
  isLoading?: boolean
}

const SearchInput = ({ 
  value,
  placeHolder,
  onChange, 
  isLoading,
}: SearchInputProps) => {
  return (
    <div className="relative flex items-center w-full max-w-sm">
      <div className="absolute p-2 h-9 aspect-square">
        <MagnifyingGlass className="text-primary-gray" />
      </div>
      {
        isLoading &&
        <div className="absolute right-0 p-2 h-9 aspect-square">
          <Spinner className="text-black" />
        </div>
      }
      <input
        value={value}
        placeholder={placeHolder ?? 'Search'}
        onChange={(e) => onChange && onChange(e.target.value)}
        className="pl-8 py-1 border-secondary-gray border rounded-md overflow-hidden w-full outline-primary h-[2.375rem]"
      />
    </div>
  )
}

export default SearchInput