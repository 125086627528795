
import { useCallback } from 'react'
import { useNotifier } from 'react-headless-notifier'
import NotificationToast from 'components/notifications'

type ToastProps = {
  title?: string
  message: string
}

export function useToast() {
  const { notify } = useNotifier()

  const successToast = useCallback(({ title, message }: ToastProps) => {
    notify(<NotificationToast type="success" title={title} message={message} />)
  }, [])

  const warningToast = useCallback(({ title, message }: ToastProps) => {
    notify(<NotificationToast type="warning" title={title} message={message} />)
  }, [])

  const errorToast = useCallback(({ title, message }: ToastProps) => {
    notify(<NotificationToast type="error" title={title} message={message} />)
  }, [])

  return { successToast, warningToast, errorToast }
}