import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useEffect, useRef } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import useErrorHandler from 'hooks/useErrorHandler'
import FormLayout from '../layouts/FormLayout'
import Button from 'components/button'
import UploadIcon from 'components/icons/UploadIcon'
import DocumentTextIcon from 'components/icons/DocumentTextIcon'
import ComboBox from 'components/form/combobox'
import { useAdminListUsersQuery } from 'api'
import { User } from 'api/types/models/user'
import FormError from 'components/error'
import { useNotifier } from 'react-headless-notifier'
import NotificationToast from 'components/notifications'
import PencilIcon from 'components/icons/PencilIcon'
import { ManageableAttachment } from 'api/types/requests/admin/file/update'
import ArrowTopRightIcon from 'components/icons/ArrowTopRightIcon'
import CrossIcon from 'components/icons/CrossIcon'

type FormInputs = {
  user_id: number
  filenames?: {
    name: string
  }[]
  attachments?: {
    file: File
  }[]
  current_attachments?: Partial<ManageableAttachment[]>
}

type FormProps = {
  user?: User
  onSubmitError?: FetchBaseQueryError | SerializedError | undefined
  onSubmit: (data: FormInputs) => void
  onSubmitBtnText?: string
  onSubmitLoading?: boolean
}

const AdminFileForm = ({ user, onSubmitError, onSubmit, onSubmitBtnText = 'Create', onSubmitLoading = false }: FormProps) => {
  const errorHandler = useErrorHandler()
  const { notify } = useNotifier()

  const pdfRef = useRef<any>(null)

  const { data: users, isLoading: isLoadingUsers } = useAdminListUsersQuery({}, { refetchOnMountOrArgChange: true })

  const { handleSubmit, setError, formState: { errors }, control, getValues, setValue, reset, clearErrors } = useForm<FormInputs>({
    defaultValues: {
      //
    },
  })

  const { fields: attachments, append: appendAttachments, remove: removeAttachment } = useFieldArray({
    control,
    name: 'attachments',
  })

  const { append: appendFilenames, remove: removeFilenames, update: updateFilenames } = useFieldArray({
    control,
    name: 'filenames',
  })

  const { fields: currentAttachments, update: updateCurrentAttachments } = useFieldArray({
    control,
    name: 'current_attachments',
    keyName: 'key',
  })

  const submitWrapper = (data: FormInputs) => {
    clearErrors()
    return onSubmit(data)
  }

  useEffect(() => {
    if (onSubmitError) {
      errorHandler(onSubmitError, setError)
      notify(<NotificationToast type="error" message="There was a problem updating this user's files, try again soon" />)
    }
  }, [onSubmitError])

  useEffect(() => {
    if (user) {
      reset({
        attachments: undefined,
        current_attachments: user ? user.files.map((file) => ({ id: file.id, attachment: file.attachment, delete: false, name: file.name })) : undefined,
      })
    }
  }, [user])

  return (
    <FormLayout>
      <form onSubmit={handleSubmit(submitWrapper)}>
        <FormLayout.Block>
          <FormLayout.Title
            title="User Files"
            subtitle="User files which can be linked to an Asset"
          />

          <FormLayout.Group
            label="User"
            description="Find the user you want to link these files to"
            htmlFor="user_id"
          >
            <ComboBox
              defaultValue={user?.id}
              isLoading={isLoadingUsers}
              onChange={(option) => {
                if (option) setValue('user_id', option.value as number)
              }}
              options={users?.users.map((user: User) => ({ value: user.id, text: `${user.first_name} ${user.last_name}`  }))}
              placeholder="Search for a user..."
              disabled={!!user}
            />
            {
              errors?.user_id?.message &&
              <FormError text={errors?.user_id?.message} className="mt-2" />
            }
          </FormLayout.Group>

          {/* NEW ATTACHMENTS SECTION */}
          <FormLayout.Group
            label={user ? 'New Files' : 'Files'}
            description="Upload a selection of PDF files which become available for any assets the user owns. Individual files can be no larger than 10MB."
            htmlFor="attachment"
          >
            <Controller
              name="attachments"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files && files.length > 0) {

                    Array.from(files).map((file) => {
                      appendAttachments({ file: file})
                      appendFilenames({ name: file.name })
                    })

                  } else {
                    if (getValues('attachments') !== undefined) {
                      return
                    }

                    field.onChange(null)
                  }
                }

                return (
                  <>
                    <div className="w-full">

                      {
                        attachments.length > 0 &&
                        <div className="flex flex-wrap justify-start border-x border-t border-secondary-gray rounded-t-md max-h-[240px] overflow-y-auto">
                          {
                            attachments.map((attachment, index) => (
                              <div key={`new-attachments-${index}`} className="w-full h-fit last:mb-2" >
                                <div className="m-0.5 p-1 flex">

                                  <div className="flex mb-auto cursor-default">
                                    <DocumentTextIcon className="w-6 p-1 aspect-square inline" />
                                  </div>

                                  <div className="w-full flex flex-wrap">
                                    <div className="max-w-md w-full mx-1 min-h-[1.5rem] relative">
                                      <PencilIcon className="absolute h-4 aspect-square right-1 top-1" />
                                      <input
                                        className="pl-1 pr-6 border-b w-full border-b-secondary-gray outline-primary"
                                        defaultValue={attachment.file.name}
                                        onChange={(event) => {
                                          clearErrors(`filenames.${index}`)
                                          updateFilenames(index, { name: event.target.value })
                                        }}
                                      />
                                    </div>
                                    <div>
                                      {
                                      // TS ignore here as the FormInput types here do not match the response
                                      // This is because we have simplified some of the form with FieldArrays which
                                      // require an object inside the array, but our response only has an array item
                                      // @ts-ignore
                                        ((errors?.attachments && errors?.attachments[index]?.message) || (errors?.filenames && errors?.filenames[index]?.message)) &&
                                        // @ts-ignore
                                        <FormError className="!text-left" text={
                                          [
                                            // @ts-ignore
                                            errors?.attachments && errors?.attachments[index]?.message, errors?.filenames && errors?.filenames[index]?.message,
                                          ].filter((errorMsg) => errorMsg ).join(', ')
                                        } />
                                      }
                                    </div>
                                  </div>

                                  <div className="flex ml-auto mb-auto gap-2">
                                    <a
                                      type="button"
                                      className="ml-auto rounded-sm flex items-center gap-1 group text-sm outline-offset-2 outline-primary"
                                      href={URL.createObjectURL(attachment.file)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <p className="font-light text-primary-gray">View</p>
                                      <ArrowTopRightIcon className="w-6 aspect-square p-1 bg-gray-100 group-focus:bg-primary group-hover:bg-primary group-focus:text-white group-hover:text-white rounded-sm" />
                                    </a>
                                    <button
                                      type="button"
                                      className="ml-auto rounded-sm flex items-center gap-1 group text-sm outline-offset-2 outline-primary"
                                      onClick={() => {
                                        removeAttachment(index)
                                        removeFilenames(index)
                                      }}>
                                      <p className="font-light text-primary-gray">Delete</p>
                                      <CrossIcon className="w-6 aspect-square p-1 bg-gray-100 group-focus:bg-red-500 group-hover:bg-red-500 group-focus:text-white group-hover:text-white rounded-sm" />
                                    </button>
                                  </div>

                                </div>
                              </div>
                            ))
                          }
                        </div>
                      }

                      <button type="button" onClick={() => ( pdfRef.current?.click() )} className={`w-full bg-primary text-white rounded-b-md py-2 ${attachments.length === 0 ? 'rounded-t-md' : ''}`}>
                        <div className="flex items-center justify-center">
                          <p className="mr-2 truncate">Upload</p>
                          <UploadIcon className="w-6 p-0.5 aspect-square inline" />
                        </div>
                      </button>

                    </div>

                    <input
                      type="file"
                      multiple
                      accept=".pdf"
                      className="hidden"
                      ref={pdfRef}
                      onChange={(input) => handleChange(input.target?.files)}
                    />
                  </>
                )
              }}
            />
          </FormLayout.Group>

          {/* EXISTING ATTACHMENTS SECTION */}
          {
            currentAttachments.length > 0 &&
            <FormLayout.Group
              label="Files"
              description="Manage files which are already linked to the user, each file can be no larger than 10MB. Save your changes with the Update button"
              htmlFor="undefined"
            >
              <div className="rounded-md border border-secondary-gray">
                {
                  currentAttachments?.map((attachment, index) => (
                    <div key={`attachments-${attachment.id}`} className="w-full h-fit">
                      <div className="m-0.5 p-1 flex">

                        <div className="flex mb-auto cursor-default">
                          <DocumentTextIcon className={`w-6 p-1 aspect-square inline ${attachment.deleted ? 'text-secondary-gray' : 'text-primary-gray'}`} />
                        </div>

                        <div className="w-full flex flex-wrap">

                          <div className="max-w-md w-full mx-1 h-6 relative">
                            <PencilIcon className={`absolute h-4 aspect-square right-1 top-1 peer ${attachment.deleted ? 'text-secondary-gray' : 'text-primary-gray'}`} />
                            <input
                              disabled={attachment.deleted}
                              className="pl-1 pr-6 border-b w-full border-b-secondary-gray disabled:text-secondary-gray rounded-t outline-primary"
                              defaultValue={attachment.name}
                              onChange={(event) => {
                                clearErrors(`current_attachments.${index}`)
                                updateCurrentAttachments(index, { ...attachment, name: event.target.value })
                              }}
                            />
                          </div>

                          <div>
                            {
                              (errors.current_attachments || errors.current_attachments) &&
                              <FormError className="!text-left" text={[errors?.current_attachments[index]?.deleted?.message, errors?.current_attachments[index]?.name?.message].filter((errorMsg) => errorMsg).join(', ')} />
                            }
                          </div>

                        </div>

                        <div className="flex ml-auto mb-auto gap-2">
                          <a
                            type="button"
                            className="ml-auto rounded-sm flex items-center gap-1 group text-sm outline-offset-2 outline-primary"
                            href={attachment?.attachment?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p className="font-light text-primary-gray">View</p>
                            <ArrowTopRightIcon className="w-6 aspect-square p-1 bg-gray-100 group-focus:bg-primary group-hover:bg-primary group-focus:text-white group-hover:text-white rounded-sm" />
                          </a>
                          <button
                            type="button"
                            className="ml-auto rounded-sm flex items-center gap-1 group text-sm outline-offset-2 outline-primary"
                            onClick={() => updateCurrentAttachments(index, { ...attachment, deleted: attachment.deleted ? false : true })}
                          >
                            <p className="font-light text-primary-gray">Delete</p>
                            <CrossIcon className="w-6 aspect-square p-1 bg-gray-100 group-focus:bg-red-500 group-hover:bg-red-500 group-focus:text-white group-hover:text-white rounded-sm" />
                          </button>
                        </div>
                      </div>

                    </div>
                  ))
                }
              </div>
            </FormLayout.Group>
          }

        </FormLayout.Block>

        <FormLayout.Footer>
          <Button href="/admin/dashboard/files/browse" variant="secondary" className="hidden lg:block">
            Cancel
          </Button>
          <Button type="submit" isLoading={onSubmitLoading}>{onSubmitBtnText}</Button>
        </FormLayout.Footer>
      </form>
    </FormLayout>
  )
}

export default AdminFileForm