import { useAdminImportAssetsMutation, useAdminListThemesQuery, useAdminListUsersQuery } from 'api'
import FormHeader from 'components/admin/form_header'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import ComboBox from 'components/form/combobox'
import Input from 'components/form/input'
import { useEffect, useMemo, useState } from 'react'
import TextArea from 'components/form/textarea'
import { useForm } from 'react-hook-form'
import Button from 'components/button'
import { useNotifier } from 'react-headless-notifier'
import NotificationToast from 'components/notifications'
import FormError from 'components/error'
import useErrorHandler from 'hooks/useErrorHandler'
import Alert from 'components/alerts'
import { getAssetImportErrorString } from 'helpers/string'
import { useGetClientsQuery } from 'api/endpoints/admin/client'
import { Client } from 'api/types/models/client'
import { useNavigate } from 'react-router-dom'
import { AssetType, FriendlyAssetType } from 'enums/AssetType'
import { capitalize } from 'lodash'
import { RadioCardGroup } from 'components/form/radio_card_group'
import { RadioCardOption } from 'components/form/radio_card'
import ReactQuill from 'react-quill'

type FormInputs = {
  type: AssetType
  client_id?: number
  theme_id?: number
  user_id?: number
  description?: string
  default_message_content: string
  urls: string[]
  is_private: boolean
}

const AdminAssetImportPage = () => {
  const { notify } = useNotifier()

  const navigate = useNavigate()
  const errorHandler = useErrorHandler()

  const [urlsInput, setUrlsInput] = useState('')

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, defaultValues },
    reset,
    getValues,
    clearErrors,
    trigger,
  } = useForm<FormInputs>({
    defaultValues: {
      type: AssetType.KICALL,
      client_id: undefined,
      theme_id: undefined,
      user_id: undefined,
      description: undefined,
      default_message_content: '',
      is_private: false,
      urls: [],
    },
  })

  const {
    data: clients,
    isLoading: isClientsLoading,
  } = useGetClientsQuery({})

  const {
    data: themes,
    isLoading: isThemesLoading,
  } = useAdminListThemesQuery({
    client_id: watch('client_id'),
  }, {
    skip: !watch('client_id'),
  })

  const {
    data: users,
    isLoading: isUsersLoading,
  } = useAdminListUsersQuery({
    clientId: watch('client_id'),
  }, {
    skip: !watch('client_id'),
  })

  const [submitImport, {
    data: importResult,
    error: importError,
    isLoading: isImportLoading,
  }] = useAdminImportAssetsMutation()

  const submitWrapper = (data: FormInputs) => {
    data.urls = urlsInput?.split(',').map((url) => url.trim()).filter((url) => url !== '').map((url) => url.replace(' ', ''))
    return submitImport(data)
  }

  useEffect(() => {
    if (importResult) {
      if (importResult.assets && !importResult.errors) {
        navigate('/admin/dashboard/assets/browse')
        notify(<NotificationToast message={`You have successfully imported ${importResult.assets.length} assets`} />)
      }
    }
  }, [importResult])

  useEffect(() => {
    if (importError) {
      errorHandler(importError, setError)
    }
  }, [importError])

  const clientOptions = useMemo(() => {
    let unpaginatedClients = clients as Client[]
    return unpaginatedClients?.map((client) => ({ value: client.id, text: client.display_name }))
  }, [clients])

  const assetTypeOptions = useMemo(() => {
    return Object.values(AssetType)
      .map((type) => ({
        value: type,
        text: FriendlyAssetType[type],
      }))
  }, [])

  const toolbarOptions = [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'link'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }, { 'list': 'ordered' }, { 'list': 'bullet' }],
    ['clean'],
  ]

  return (
    <>
      <div className="flex flex-col h-full">
        <FormHeader
          title="Import Assets"
          description="Import and assign multiple assets using this form"
          backBtnText="Back to Assets"
          backBtnUrl="/admin/dashboard/assets/browse"
        />

        <FormLayout>
          <form onSubmit={handleSubmit(submitWrapper)}>
            <FormLayout.Block>
              <FormLayout.Group
                label="Type"
                htmlFor="type"
                description="Specify the type of asset(s) being imported"
              >
                <ComboBox
                  defaultValue={AssetType.KICALL}
                  placeholder="Select an asset type"
                  onChange={(option) => {
                    if (option) setValue('type', option.value as AssetType)
                  }}
                  options={assetTypeOptions}
                />
                {
                  errors.type?.message &&
                  <FormError text={errors.type.message} className="mt-1 mb-2 !text-left" />
                }
              </FormLayout.Group>

              <FormLayout.Group
                label="Client"
                htmlFor="client"
                description="Pre-filter themes and users by client"
              >
                <ComboBox
                  isLoading={isClientsLoading}
                  onChange={(option) => {
                    if (option) {
                      setValue('client_id', option.value as number)
                      setValue('theme_id', undefined)
                      setValue('user_id', undefined)
                    }
                  }}
                  options={clientOptions}
                  placeholder="Search for a client"
                />
                {
                  errors.client_id?.message &&
                  <FormError text={errors?.client_id?.message} className="mt-1 mb-2 !text-left" />
                }
              </FormLayout.Group>

              <FormLayout.Group
                label="Theme"
                htmlFor="theme_id"
                description="Find the theme you want to link your assets"
              >
                <ComboBox
                  key={watch('client_id')} // This is a hack to force combobox to re-create itself as selected state is internal
                  isLoading={isUsersLoading}
                  disabled={!watch('client_id')}
                  placeholder="Search for a theme"
                  defaultValue={watch('theme_id')}
                  onChange={(option) => {
                    if (option) setValue('theme_id', option.value as number)
                  }}
                  options={themes?.map((theme) => ({ value: theme.id, text: theme.title }))}
                />
                {
                  errors.theme_id &&
                  <FormError text={errors.theme_id.message} className="mt-1 mb-2 !text-left" />
                }
              </FormLayout.Group>

              <FormLayout.Group
                label="User"
                htmlFor="user_id"
                description="Find the user you want to link your assets"
              >
                <ComboBox
                  key={watch('client_id')} // This is a hack to force combobox to re-create itself as selected state is internal
                  isLoading={isThemesLoading}
                  disabled={!watch('client_id')}
                  placeholder="Search for a user"
                  onChange={(option) => {
                    if (option) setValue('user_id', option.value as number)
                  }}
                  options={users?.users.map((user) => ({ value: user.id, text: `${user.first_name} ${user.last_name}` }))}
                />
                {
                  errors.user_id?.message &&
                  <FormError text={errors.user_id?.message} className="mt-1 mb-2 !text-left" />
                }
              </FormLayout.Group>

              <FormLayout.Group
                label="Description"
                htmlFor="description"
                description="Give each asset in your import a specific description"
              >
                <Input {...register('description')} placeholder="Description" />
                {
                  errors.description &&
                  <FormError text={errors.description.message} className="mt-1 mb-2" />
                }
              </FormLayout.Group>

              <FormLayout.Group
                label="Default Message"
                htmlFor="default_message_content"
                description="Optionally customize the default message for each asset in your import"
              >
                <ReactQuill
                  theme="snow"
                  value={getValues('default_message_content')}
                  placeholder="Write a message and use the toolbar above to style it"
                  modules={{ toolbar: toolbarOptions }}
                  onChange={(html) => {
                    trigger('default_message_content')
                    setValue('default_message_content', html, {
                      shouldDirty: getValues('default_message_content') !== defaultValues?.default_message_content,
                    })
                  }}
                />
                {
                  errors?.default_message_content?.message &&
                  <FormError text={errors.default_message_content.message} />
                }
              </FormLayout.Group>

              <FormLayout.Group
                label="URLs"
                htmlFor="urls"
                description="Input the URLs of the assets you want to add, these should separated by comma. Up to 1000 assets can be created at a time."
              >
                <div className="flex flex-col gap-3">
                  <TextArea
                    value={urlsInput}
                    onChange={(value) => {
                      setUrlsInput(value.currentTarget.value)
                      clearErrors('urls')
                    }}
                    placeholder="https://www.example.com/?d=abc, https://www.example.com/?d=def..."
                    className="min-h-[172px] text-sm px-2 py-1"
                  />
                  {
                    errors.urls &&
                    // @ts-ignore - React Hook forms types the array validation as a FieldError[] when the response is actually an object
                    <FormError text={errors.urls.message} className="mt-1 mb-2" />
                  }
                  {
                    importResult?.errors &&
                    <Alert type="error" className="overflow-y-auto max-h-64" message={
                      <div className="-mt-1">
                        <div className="mb-5">
                          <h1 className="mb-1 text-lg font-bold text-red-600">There was a problem importing some of your assets</h1>
                          <p>{importResult?.errors.length} asset(s) failed to import</p>
                          <p>See below for details on what went wrong with your import attempt</p>
                        </div>
                        {
                          importResult.errors.map(({ url, data }, index) => (
                            <div key={`import-error-${index}`}>
                              <h3 className="mb-1 font-semibold text-red-500">{url}</h3>
                              <ul className="mb-2 ml-4 list-disc">
                                {
                                  data && data.map((err, index) => (
                                    <li key={`import-error-${index}-item-${index}`}>
                                      {getAssetImportErrorString(err.message)} {err.count > 1 ? `(${err.count})` : ''}
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          ))
                        }
                      </div>
                    } />
                  }
                </div>
              </FormLayout.Group>

              <FormLayout.Group
                label="Visibility"
                description="Specify who can view this asset"
                htmlFor="is_private"
              >
                <RadioCardGroup name="is_private" control={control}>
                  <RadioCardOption value={false} first>Everyone</RadioCardOption>
                  <RadioCardOption value={true} last>Certain People</RadioCardOption>
                </RadioCardGroup>
              </FormLayout.Group>
            </FormLayout.Block>

            <FormLayout.Footer>
              <Button variant="secondary" onClick={() => {
                reset()
                setUrlsInput('')
              }}>
                Clear
              </Button>
              <Button href="/admin/dashboard/assets/browse" variant="secondary" className="hidden lg:block">
                Cancel
              </Button>
              <Button type="submit" isLoading={isImportLoading}>Import</Button>
            </FormLayout.Footer>
          </form>
        </FormLayout>
      </div >
    </>
  )
}

export default AdminAssetImportPage