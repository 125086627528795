import { useAdminListUsersQuery } from 'api'
import { useGetCreditLogsQuery } from 'api/endpoints/admin/credit-log'
import SearchInput from 'components/admin/search_input'
import Pagination from 'components/pagination'
import { CreditLogTable } from 'components/tables/credit_log'
import CreditLogReason, { getFormattedCreditReason } from 'enums/CreditLogReason'
import { useFilters } from 'hooks/useFilters'
import { useSort } from 'hooks/useSort'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select'

type CreditLogFilters = {
  search: string | undefined
  users: number[]
  reasons: CreditLogReason[]
}

export function AdminCreditLogBrowsePage() {
  const [searchParams] = useSearchParams()
  
  const [currentPage, setCurrentPage] = useState(1)

  const { sorts, toggleSorts } = useSort({
    'user_id': undefined,
    'asset_id': undefined,
    'reason': undefined,
    'created_at': undefined,
  }, () => setCurrentPage(1))

  const [{ filters, debouncedFilters }, setFilters] = useFilters<CreditLogFilters>({
    onFilterChange: () => setCurrentPage(1),
    initialFilters: {
      search: searchParams.get('search') ?? undefined,
      users: searchParams.getAll('users') as unknown as number[] ?? [],
      reasons: searchParams.getAll('reasons') as unknown as CreditLogReason[] ?? [],
    },
  })

  const {
    data: creditLogs, 
    isLoading: isLoadingCreditLogs,
    isFetching: isFetchingCreditLogs, 
  } = useGetCreditLogsQuery({ 
    page: currentPage,
    sort: sorts,
    ...debouncedFilters,
  })

  const {
    data: users,
  } = useAdminListUsersQuery({})

  const reasonOptions = useMemo(() => {
    return Object
      .values(CreditLogReason)
      .map((reason) => {
        const stat = Object
          .values(creditLogs?.stats ?? {})
          .find((stat) => stat?.reason === reason)

        return ({ 
          value: reason, 
          label: `${getFormattedCreditReason(reason)} (${stat?.count ?? 0})`,
        })
      })
  }, [creditLogs?.stats])

  const userOptions = useMemo(() => {
    return users?.users?.map(user => ({
      value: user.id, 
      label: user?.full_name ?? '', 
    })) ?? []
  }, [users])

  return (
    <div>
      <div className="p-3 border-b lg:p-6 border-secondary-gray">
        <h1 className="text-2xl font-medium text-slate-800">
          Credit Logs
        </h1>
      </div>

      <div className="p-3 space-y-6 lg:p-6">
        <div className="flex flex-wrap items-start gap-3">
          <SearchInput 
            isLoading={isFetchingCreditLogs} 
            value={filters.search ?? ''}
            placeHolder="Search for assets"
            onChange={(term) => 
              setFilters({ 
                ...debouncedFilters, 
                search: term ?? undefined, 
              } as CreditLogFilters)
            }
          />

          <Select
            isMulti
            placeholder="Filter Reason"
            classNamePrefix="react-select"
            className="react-select-container"
            value={reasonOptions.filter((option) => filters.reasons.includes(option.value))}
            onChange={(options) => 
              setFilters({ 
                ...debouncedFilters,
                reasons: options.map((option) => (option.value)), 
              } as CreditLogFilters)
            }
            options={reasonOptions}
          />

          <Select
            isMulti
            placeholder="Filter Users"
            classNamePrefix="react-select"
            className="react-select-container"
            value={
              userOptions.filter((option) => 
                filters.users
                  ?.map((u) => parseInt(u as unknown as string))
                  ?.includes(option.value)
              )
            }
            onChange={
              (options) => 
                setFilters({ 
                  ...debouncedFilters,
                  users: options.map((option) => (option.value)), 
                } as CreditLogFilters)
            }
            options={userOptions}
          />
        </div>

        <div className="overflow-x-scroll">
          <CreditLogTable 
            value={creditLogs}
            sorts={sorts}
            onSort={toggleSorts}
            isLoading={isLoadingCreditLogs} 
          />
        </div>

        <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
          <Pagination 
            onPage={creditLogs?.logs?.meta?.current_page ?? 1} 
            onPageChange={(page) => setCurrentPage(page)} 
            totalPages={creditLogs?.logs?.meta?.last_page ?? 1 } 
          />
        </div>

      </div>
    </div>
  )
}