import { cn } from 'helpers/styling'
import { Badge } from 'components/badge'
import { TrendingUpArrowIcon } from 'components/icons/TrendingUpArrow'
import { TrendingDownArrowIcon } from 'components/icons/TrendingDownArrow'

type ChangeProps = {
  type: 'increase' | 'decrease'
  change: number
  className?: string
}

export function Change({ 
  type, 
  change,
  className,
}: ChangeProps) {
  const isIncrease = type === 'increase'

  return (
    <Badge 
      className={cn('hidden gap-2 mt-2 md:flex', className)}
      intent={isIncrease ? 'success' : 'danger'}
    >
      <div>
        <span className="sr-only"> {isIncrease ? 'Increased' : 'Decreased'} by </span>
        <span>{change}%</span>
      </div>

      {
        isIncrease
          ? <TrendingUpArrowIcon className="w-5 shrink-0" />
          : <TrendingDownArrowIcon className="w-5 shrink-0" />
      }
    </Badge>
  )
}