import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import UserOutlet from './pages/user/auth/outlet'
import LoginPage from './pages/user/auth/login'
import ProtectedRoute from './ProtectedRoute'
import AssetRegisterPage from './pages/user/asset/register'
import MainPage from './pages/user/main'
import AssetManageOutlet from './pages/user/asset/manage/outlet'
import AssetBrowsePage from './pages/user/asset/manage/browse'
import AssetShowPage from './pages/user/asset/manage/show'
import ErrorPage from './pages/error'
import AssetAccessPage from './pages/user/asset/manage/access'
import RegisterPageOutlet from './pages/user/auth/register/outlet'
import RegisterStepOne from './pages/user/auth/register/step_one'
import RegisterStepTwo from './pages/user/auth/register/step_two'
import RegisterStepThree from './pages/user/auth/register/step_three'
import AdminOutlet from './pages/admin/outlet'
import AdminLoginPage from './pages/admin/auth/login'
import AdminDashboardPage from './pages/admin/dashboard'
import AdminAssetBrowsePage from './pages/admin/dashboard/assets/browse'
import AdminAssetManagePage from './pages/admin/dashboard/assets/manage'
import AdminDeviceBrowsePage from './pages/admin/dashboard/devices/browse'
import AdminDeviceCreatePage from './pages/admin/dashboard/devices/create'
import AdminDeviceManagePage from './pages/admin/dashboard/devices/manage'
import AdminUserBrowsePage from 'pages/admin/dashboard/users/browse'
import AdminUserCreatePage from 'pages/admin/dashboard/users/create'
import AdminUserManagePage from 'pages/admin/dashboard/users/manage'
import AdminAssetHistoryPage from 'pages/admin/dashboard/assets/history'
import AccountPage from './pages/user/auth/account'
import AssetDashboardPage from 'pages/user/asset/manage/dashboard'
import SignOutPage from 'pages/user/sign_out'
import AdminThemeBrowsePage from 'pages/admin/dashboard/themes'
import AdminThemeStorePage from 'pages/admin/dashboard/themes/store'
import AdminThemeManagePage from 'pages/admin/dashboard/themes/update'
import AdminFilesBrowsePage from 'pages/admin/dashboard/files'
import AdminFileStorePage from 'pages/admin/dashboard/files/store'
import AdminFileManagePage from 'pages/admin/dashboard/files/update'
import AdminAssetImportPage from 'pages/admin/dashboard/assets/import'
import AdminClientBrowsePage from 'pages/admin/dashboard/clients/browse'
import AdminClientStorePage from 'pages/admin/dashboard/clients/create'
import { AdminClientManagePage } from 'pages/admin/dashboard/clients/manage'
import { AdminCreditBlockCreatePage } from 'pages/admin/dashboard/credit-blocks/create'
import { AdminCreditLogBrowsePage } from 'pages/admin/dashboard/credit-logs'
import ConfigurationManagePage from 'pages/admin/dashboard/configurations'
import { AssetManageMessagePage } from 'pages/user/asset/manage/message/manage'
import { AssetCreateMessagePage } from 'pages/user/asset/manage/message/create'
import { AdminInvitePage } from 'pages/admin/dashboard/users/invite'
import { AdminVerifyAccountPage } from 'pages/admin/auth/verify-account'
import ReportsPage from 'pages/admin/dashboard/reports'
import DeviceBrowsePage from 'pages/user/device/browse'
import DeviceShowPage from 'pages/user/device/show'
import AdminMessageManagePage from 'pages/admin/dashboard/assets/manage/messages/manage'
import AdminMessageCreatePage from 'pages/admin/dashboard/assets/manage/messages/create'

const router = () => {
  return (
    <BrowserRouter>
      <App>
        <Routes>
          <Route element={<UserOutlet />} >
            <Route path="/" element={<MainPage />} />
            <Route path="/register" element={<RegisterPageOutlet />} >
              <Route path="step-one" element={<RegisterStepOne />} />
              <Route path="step-two" element={<RegisterStepTwo />} />
              <Route path="step-three" element={<RegisterStepThree />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-out" element={<SignOutPage />} />

            <Route element={<ProtectedRoute />} >

              <Route path="asset">
                <Route path="manage" element={<AssetManageOutlet />} >
                  <Route path="list" element={<AssetBrowsePage />} />
                  <Route path=":uid">
                    <Route path="" element={<AssetShowPage />} />
                    <Route path="access" element={<AssetAccessPage />} />
                    <Route path="history" element={<AssetDashboardPage />} />
                    <Route path="messages" element={<AssetCreateMessagePage />} />
                    <Route path="messages/:messageId" element={<AssetManageMessagePage />} />
                  </Route>
                </Route>
                <Route path="register" element={<AssetRegisterPage />} />
              </Route>

              <Route path="devices">
                <Route path="" element={<DeviceBrowsePage />} />
                <Route path=":id" element={<DeviceShowPage />} />
              </Route>

              <Route path="account" element={<AccountPage />} />
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Route>

          <Route path="/admin" element={<AdminOutlet />}>
            <Route path="login" element={<AdminLoginPage />} />
            <Route path="verify-account" element={<AdminVerifyAccountPage />} />
            <Route path="dashboard" element={<AdminDashboardPage />}>

              <Route path="assets">
                <Route path="browse" element={<AdminAssetBrowsePage />} />
                <Route path="import" element={<AdminAssetImportPage />} />
                <Route path="history/:id" element={<AdminAssetHistoryPage />} />
                <Route path="manage/:id">
                  <Route path="" element={<AdminAssetManagePage />} />
                  <Route path="messages">
                    <Route path=":messageId" element={<AdminMessageManagePage />} />
                    <Route path="create" element={<AdminMessageCreatePage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="devices">
                <Route path="browse" element={<AdminDeviceBrowsePage />} />
                <Route path="create" element={<AdminDeviceCreatePage />} />
                <Route path="manage/:id" element={<AdminDeviceManagePage />} />
              </Route>

              <Route path="files">
                <Route path="browse" element={<AdminFilesBrowsePage />} />
                <Route path="create" element={<AdminFileStorePage />} />
                <Route path="manage/:id" element={<AdminFileManagePage />} />
              </Route>

              <Route path="themes">
                <Route path="browse" element={<AdminThemeBrowsePage />} />
                <Route path="create" element={<AdminThemeStorePage />} />
                <Route path="manage/:id" element={<AdminThemeManagePage />} />
              </Route>

              <Route path="users">
                <Route path="browse" element={<AdminUserBrowsePage />} />
                <Route path="create" element={<AdminUserCreatePage />} />
                <Route path="invite" element={<AdminInvitePage />} />
                <Route path="manage/:id" element={<AdminUserManagePage />} />
              </Route>

              <Route path="clients">
                <Route path="browse" element={<AdminClientBrowsePage />} />
                <Route path="create" element={<AdminClientStorePage />} />

                <Route path="manage/:clientId">
                  <Route path="" element={<AdminClientManagePage />} />
                  <Route path="credit-blocks">
                    <Route path="create" element={<AdminCreditBlockCreatePage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="configuration" element={<ConfigurationManagePage />} />
              <Route path="reports" element={<ReportsPage />} />
              <Route path="credit-logs" element={<AdminCreditLogBrowsePage />} />

            </Route>
          </Route>
        </Routes>
      </App>
    </BrowserRouter>
  )
}

export default router