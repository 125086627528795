import { Link } from 'react-router-dom'
import Button from 'components/button'
import AdminTable from 'components/admin/tables'
import Asset from 'api/types/models/asset'
import { useState } from 'react'
import Device from 'api/types/models/device'
import AdminDeleteDeviceModal from 'components/modals/forms/device'
import PhoneUrl from 'components/phone_url'

type Props = {
  asset: Asset
}

const AssetDeviceTable = ({ asset }: Props) => {
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const openDeviceDeleteModal = (device: Device) => {
    setSelectedDevice(device)
    setIsDeleteModalOpen(true)
  }

  return (
    <>
      {
        asset.devices &&
        <div>
          <div className="overflow-y-auto rounded-sm border-y border-secondary-gray max-h-52">
            <AdminTable>
              <AdminTable.Head className="sticky top-0">
                <AdminTable.Heading>#</AdminTable.Heading>
                <AdminTable.Heading>Name</AdminTable.Heading>
                <AdminTable.Heading>Phone</AdminTable.Heading>
                <AdminTable.Heading className="hidden lg:table-cell">Relationship</AdminTable.Heading>
                <AdminTable.Heading>Actions</AdminTable.Heading>
              </AdminTable.Head>
              <AdminTable.Body>
                <>
                  {
                    asset.devices.map((device, index) => (
                      <AdminTable.Row key={index}>
                        <AdminTable.Column>
                          <Link to={`/admin/dashboard/devices/manage/${device.id}`} className="url-text">
                            {device.id.toString()}
                          </Link>
                        </AdminTable.Column>
                        <AdminTable.Column>{device.name}</AdminTable.Column>
                        <AdminTable.Column><PhoneUrl phone={device.full_phone_number} /></AdminTable.Column>
                        <AdminTable.Column className="hidden lg:table-cell">{device.relationship ?? 'N/a'}</AdminTable.Column>
                        <AdminTable.Column>
                          <div className="flex text-sm divide-x">
                            <Link to={`/admin/dashboard/devices/manage/${device.id}?asset=${asset.id}`} className="px-1 url-text">Manage</Link>
                            <button type="button" className="px-1 url-text" onClick={() => openDeviceDeleteModal(device)}>
                              Delete
                            </button>
                          </div>
                        </AdminTable.Column>
                      </AdminTable.Row>
                    ))
                  }
                </>
              </AdminTable.Body>
            </AdminTable>
          </div>
          <div className="flex justify-end mt-2">
            <Button href={`/admin/dashboard/devices/create?asset=${asset.id}`}>Add</Button>
          </div>
        </div>
      }

      <AdminDeleteDeviceModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} device={selectedDevice!} />

    </>
  )
}

export default AssetDeviceTable