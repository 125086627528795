import { ModalBaseProps } from 'types/modal'
import Modal from '.'
import { ReactNode } from 'react'
import Button from 'components/button'

type ConfirmationModalProps = ModalBaseProps & {
  title: string
  btnTxt?: string
  onConfirm?: () => void,
  isLoading?: boolean,
  renderContent?: () => ReactNode
}

export function ConfirmationModal({
  title,
  isOpen,
  onClose,
  onConfirm,
  btnTxt,
  isLoading,
  renderContent,
}: ConfirmationModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding>
      <Modal.Header title={title} />

      <Modal.Content className="space-y-2">
        {renderContent && renderContent()}
      </Modal.Content>
      <Modal.Footer className="flex flex-row justify-end gap-2">
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={onConfirm}
          isLoading={isLoading}
        >
          {btnTxt ?? 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}