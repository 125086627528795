import { Message } from 'api/types/models/message'
import { Badge } from 'components/badge'
import ChevronIcon from 'components/icons/ChevronUpIcon'
import { Link } from 'react-router-dom'

type DefaultMessageProps = {
  to: string
  message: Message
}

export function DefaultMessage({ to, message }: DefaultMessageProps) {
  return (
    <Link 
      to={to} 
      className="block p-3 border rounded-md"
    >
      <div className="flex items-center justify-between gap-3">
        <div className="flex items-center gap-2">
          <p className="font-semibold">
            {message?.name ?? 'No name'}
          </p>

          <Badge intent="success" size="sm">
          Default
          </Badge>
        </div>

        <div className="flex items-center text-primary">
          <ChevronIcon className="w-4 -rotate-90" />
        </div>
      </div>
    </Link>

  )
}