import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Button from 'components/button'
import Collapse from 'components/collapse'
import Card from 'components/cards/default'
import { Title } from 'components/headings'
import TermsAndConditionsBody from 'components/footer/html/TermsAndConditions'
import EulaBody from 'components/footer/html/Eula'
import PrivacyAndCookiesBody from 'components/footer/html/PrivacyAndCookies'
import { useNotifier } from 'react-headless-notifier'
import { RegisterState } from 'types/registerState'
import NotificationToast from 'components/notifications'

const RegisterStepOne = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { notify } = useNotifier()
  const location = useLocation()
  const state = useLocation().state as RegisterState

  // if we are redirected from a step further in the process
  if (state && state.acceptedTerms == false){
    notify(<NotificationToast type='error' message='You must accept the terms & conditions before registering' />)
    location.state = null
  }

  const acceptTerms = () => {
    navigate('/register/step-two', {
      state: {
        acceptedTerms: true,
        asset: searchParams.get('asset'),
      },
    })
  }

  return (
    <Card>
      <Title>Legals</Title>
      <div className="flex flex-col my-10 flex-shrink-0">
        <Collapse defaultOpen header="Terms and Conditions" body={<TermsAndConditionsBody />} bodyClassName="informational-html" />
        <Collapse defaultOpen header="EULA" body={<EulaBody />} bodyClassName="informational-html" />
        <Collapse defaultOpen header="Privacy / 3rd Party Consent" body={<PrivacyAndCookiesBody />} bodyClassName="informational-html" />
      </div>
      <Button block onClick={() => acceptTerms()}>I Accept</Button>
    </Card>
  )
}

export default RegisterStepOne