import { useMemo } from 'react'

type FormatNumberProps = {
  value: number | null | undefined
  className?: string
}

export const FormatNumber = ({ value, className }: FormatNumberProps): JSX.Element => {
  const formatter = useMemo(() => {
    return new Intl.NumberFormat(navigator.language ?? 'en-GB')
  }, [])

  if (value === undefined || value === null || isNaN(value)) {
    return <span>-</span>
  }

  return <span className={className}>{formatter.format(value)}</span>
}