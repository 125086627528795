import Configuration from 'api/types/models/configuration'
import dataApi from 'api'

const adminConfigurationBaseUrl = '/admin/configurations'

const adminConfigurationEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    getConfigurations: build.query<Configuration[], null>({
      query: () => ({
        url: adminConfigurationBaseUrl,
      }),
      providesTags: ['configurations'],
    }),

    updateConfigurations: build.mutation<{ configurations: Configuration[] }, { id: number }>({
      query: (body) => ({
        url: adminConfigurationBaseUrl,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['configurations'],
    }),
  }),
})

export const {
  useGetConfigurationsQuery,
  useUpdateConfigurationsMutation,
} = adminConfigurationEndpoints