import { cva } from 'class-variance-authority'
import { cn } from 'helpers/styling'

type SpinnerProps = {
  className?: string
}

const Spinner = ({ className }: SpinnerProps) => {
  const spinnerStyling = cva(
    [
      'inline-block w-[1em] h-[1em] border-2 rounded-full border-t-current border-r-current border-b-transparent border-l-transparent animate-spin text-white',
    ]
  )

  return (
    <div className={cn([spinnerStyling(), className])}></div>
  )
}

export default Spinner
