import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import AssetStatistics from 'api/types/models/asset_statistics'
import EventLog from 'api/types/models/event_log'
import Asset from '../../types/models/asset'
import Device from '../../types/models/device'
import QueryTags from '../../types/QueryTags'
import AuthenticateDeviceRequest from '../../types/requests/asset/authenticateDevice'
import AssetRegisterRequest from '../../types/requests/asset/register'
import AssetAccessRequest from '../../types/requests/asset/request'
import DenyAccessRequest from 'api/types/requests/asset/denyAccess'
import { UpdateAssetRequest } from 'api/types/requests/asset/update'
import dataApi from 'api'
import { ViewAssetRequest } from 'api/types/requests/asset/view'
import UserAssetsRequest from 'api/types/requests/asset/user'

const assetBaseUrl = '/asset'

const assetEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  showAsset: builder.query<{ asset: Asset }, { id: string, include_messages?: boolean }>({
    query: ({ id, ...params }) => ({
      url: `/asset/${id}`,
      params,
    }),
    providesTags: ['asset'],
  }),

  viewAsset: builder.query<{ asset: Asset, redirect_url?: string }, ViewAssetRequest>({
    query: ({ id, ...params }) => ({
      url: `/asset/${id}/view`,
      params,
    }),
    providesTags: ['asset'],
  }),

  getUserAssets: builder.query<Asset[], UserAssetsRequest>({
    query: ({...params}) => ({
      url: '/asset/user',
      params,
    }),
    providesTags: ['assets'],
  }),

  authenticateDevice: builder.mutation<{ asset: Asset, assetDevice: Device }, AuthenticateDeviceRequest>({
    query: ({ id, body }) => ({
      url: `/asset/${id}/device-check`,
      method: 'post',
      body,
    }),
  }),

  registerAsset: builder.mutation<void, AssetRegisterRequest>({
    query: (body) => ({
      url: '/asset/register',
      method: 'post',
      body,
    }),
    invalidatesTags: ['assets', 'asset'],
  }),

  deregisterAsset: builder.mutation<void, { assetUid: string }>({
    query: ({ assetUid }) => ({
      url: `/asset/${assetUid}`,
      method: 'PATCH',
    }),
    invalidatesTags: ['assets'],
  }),

  updateAsset: builder.mutation<{ asset: Asset }, UpdateAssetRequest>({
    query: (body) => ({
      url: `${assetBaseUrl}/update`,
      method: 'POST',
      body,
    }),
    invalidatesTags: ['assets', 'asset'],
    async onQueryStarted({ masked_uid: maskedUid }, { dispatch, queryFulfilled }) {
      try {
        const { data: updatedAsset } = await queryFulfilled
        dispatch(
          dataApi.util.updateQueryData('showAsset', { id: maskedUid }, (draft) => {
            Object.assign(draft, updatedAsset)
          }),
        )
      } catch {
        //
      }
    },
  }),

  denyAssetRequest: builder.mutation<{ asset: Asset }, DenyAccessRequest>({
    query: (body) => ({
      url: '/asset/deny-access',
      method: 'post',
      body,
    }),
  }),

  requestAssetAccess: builder.mutation<void, AssetAccessRequest>({
    query: (body) => ({
      url: '/asset/request-access',
      method: 'post',
      body,
    }),
  }),

  getAssetEvents: builder.query<{ eventLog: EventLog[], statistics: AssetStatistics }, { id: string, devices: string[] | undefined }>({
    query: ({ id, ...params }) => ({
      url: `/asset/${id}/events`,
      params,
    }),
  }),

})

export default assetEndpoints