import { z } from 'zod'
import Input from 'components/form/input'
import { Form } from 'components/form/form'
import { FormControl } from 'components/form/control'
import { ReactNode } from 'react'
import { PasswordStrength } from 'components/indicators/PasswordStrength'
import { validatePassword } from 'helpers/validation/fields/password'
import validationIssuer from 'helpers/validation/issuer'
import { FormPasswordInput } from 'components/form/password'
import FormError from 'components/error'
import { FormBaseProps } from 'types/form'
import Phone from 'components/form/phone'

const verifyAccountSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  phone: z.string().min(1, 'Phone is required'),
  countryCode: z.string().min(1, 'Country code is required'),
  password: z.string().min(1, 'Password is required'),
  passwordConfirmation: z.string().min(1, 'Password confirmation is required'),
})
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'Passwords do not match',
    path: ['passwordConfirmation'],
  })
  .superRefine((fields, ctx) => {
    validationIssuer('password', validatePassword(fields.password), ctx)
  })

export type VerifyAccountFormInputs = z.infer<typeof verifyAccountSchema>

type VerifyAccountFormProps = FormBaseProps<VerifyAccountFormInputs> & {
  children: ReactNode
}

export function VerifyAccountForm({
  errors,
  onSubmit,
  defaultValues,
  children,
}: VerifyAccountFormProps) {
  return (
    <Form<VerifyAccountFormInputs, typeof verifyAccountSchema>
      error={errors}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={verifyAccountSchema}
    >
      {({ register, watch, getValues, setValue, clearErrors, formState: { errors } }) => (
        <div className="space-y-4">
          <FormControl>
            <Input 
              placeholder="Enter first name"
              error={errors.firstName?.message}
              {...register('firstName')}
            />
          </FormControl>

          <FormControl>
            <Input 
              placeholder="Enter last name"
              error={errors.lastName?.message}
              {...register('lastName')}
            />
          </FormControl>

          <FormControl>
            <Phone
              defaultPhone={getValues('phone')}
              defaultCountryCode="44"
              placeHolder="Enter phone number"
              onChange={(phone) => {
                setValue('phone', phone.phone)
                setValue('countryCode', phone.country_code)

                if (errors.phone || errors.countryCode) {
                  clearErrors(['countryCode', 'phone'])
                }               
              }} 
              error={errors?.phone?.message || errors?.countryCode?.message} 
            />
          </FormControl>

          <FormControl className="space-y-2">
            <FormPasswordInput 
              name="password"
              placeholder="Enter password"
            />
            <PasswordStrength
              password={watch('password')} 
              isError={!!errors?.password} 
            />
          </FormControl>

          <FormControl>
            <FormPasswordInput 
              name="passwordConfirmation"
              placeholder="Enter confirmation"
            />
            <FormError text={errors?.passwordConfirmation?.message} />
          </FormControl>

          {children}
        </div>
      )}
    </Form>
  )
}