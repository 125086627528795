import Asset from 'api/types/models/asset'
import Button from 'components/button'
import { AssetType } from 'enums/AssetType'
import { QRCodeSVG } from 'qrcode.react'
import { Link } from 'react-router-dom'

type AssetConfirmationCardProps = {
  asset: Asset
  showManageLink?: boolean
  showDashboardLink?: boolean
}

const AssetConfirmationCard = ({
  asset,
  showManageLink = false,
  showDashboardLink = false,
}: AssetConfirmationCardProps) => {
  let params: string = '/'
  
  if(asset) {
    params += `?d=${asset.masked_uid}`

    if(asset?.type === AssetType.TAMO) {
      params += `&t=${asset?.type}`
    }
  }

  return (
    <div className="flex flex-row gap-4">
      <div className="flex-shrink-0 max-w-[7.5rem] w-full">
        <Link target="_blank" to={params} className="flex flex-col gap-1 p-2 border-2 rounded-md outline-none border-primary-gray bg-gradient-to-br from-white via-white to-slate-200 focus:border-primary">
          <div className="flex max-w-[6.5rem] max-h-[6.5rem]">
            <QRCodeSVG value={asset?.url} className="max-w-[6.5rem] max-h-[6.5rem]" />
          </div>
          <p className="mx-auto font-bold text-black">KiCall</p>
        </Link>
        
        {
          asset.is_private
            ? <p className="inline-block w-full p-px px-2 text-xs font-medium text-center text-red-600 bg-red-100 rounded">Private</p>
            : <p className="inline-block w-full p-px px-2 text-xs font-medium text-center text-blue-600 bg-blue-100 rounded">Public</p>
        }
      </div>

      <div className="flex flex-col w-full gap-2">
        <p className="mb-2 font-medium capitalize">{asset.description ?? 'KiCall (Not Named)'}</p>
        {
          showManageLink &&
            <Button type="link" href={`/asset/manage/${asset.masked_uid}`} block>Edit Message</Button>
        }
        {
          showDashboardLink &&
          <Button variant="secondary" href={`/asset/manage/${asset.masked_uid}/history`}>History</Button>
        }
      </div>
    </div>
  )
}

export default AssetConfirmationCard