import dayjs from 'dayjs'
import { RefObject, useEffect, useRef, useState } from 'react'
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import './styles.DatePicker.css'
import Button from 'components/button'
import classNames from 'classnames'
import CalendarIcon from 'components/icons/CalendarIcon'
import { Transition } from '@headlessui/react'

export type isoDateRange = {
  from?: string
  to?: string
}

type PropTypes = {
  onChange: (date: isoDateRange) => void
  dates?: isoDateRange
  placeholder: string
  className?: string
  startBlank?: boolean
  isLoading?: boolean
}

const RangeDatePicker = ({
  onChange,
  dates,
  placeholder,
  className = '',
  startBlank = false,
  isLoading,
}: PropTypes) => {
  const defaultState = { from: undefined, to: undefined }

  // For handling expected time format internally
  const [internalRange, setInternalRange] = useState<DateRange | undefined>({
    from: !startBlank ? undefined : dayjs(dates?.from).toDate() ?? undefined,
    to: !startBlank ? undefined : dayjs(dates?.to).toDate() ?? undefined,
  })

  const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean>(false)
  const currentMonth = dayjs(internalRange?.from).toDate()

  // Return ISO format from the component to work with dayjs out the box
  const [isoRange, setIsoRange] = useState<isoDateRange | undefined>({
    from: dates?.from ?? undefined,
    to: dates?.to ?? undefined,
  })

  const handleRangeChange: SelectRangeEventHandler = (dates: DateRange | undefined) => {
    setInternalRange(dates)
    setIsoRange({ from: dayjs(dates?.from).toISOString(), to: dayjs(dates?.to ? dates.to : dates?.from).toISOString() })
  }

  const resetRanges = () => {
    setInternalRange(defaultState)
    setIsoRange(defaultState)
  }

  useEffect(() => {
    if (isoRange) {
      onChange(isoRange)
    }
  }, [isoRange])

  const ref: RefObject<HTMLDivElement> = useRef(null)
  const footerRef: RefObject<HTMLButtonElement> = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !(footerRef.current && footerRef.current.contains(event.target as Node))
    ) {
      setIsDateRangeOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const Footer = () => {
    return (
      <div className="mt-2">
        <Button
          block
          variant="secondary"
          onClick={() => resetRanges()}
          ref={footerRef}
          isLoading={isLoading}
        >
          Clear Selection
        </Button>
      </div>
    )
  }

  return (
    <div
      className='relative w-full max-w-sm'
      onFocus={() => setIsDateRangeOpen(true)}
      ref={ref}
    >
      <div className="absolute p-2 h-9 aspect-square">
        <CalendarIcon className="text-primary-gray" />
      </div>
      <input
        placeholder={internalRange?.from ? `${internalRange?.from ? internalRange.from.toDateString() : ''}${internalRange?.to ? ' - ' + internalRange.to.toDateString() : ''}` : placeholder}
        readOnly
        className={'pl-8 py-1 border-secondary-gray border rounded-md overflow-hidden w-full outline-primary h-[2.375rem]'}
      />
      <Transition
        show={isDateRangeOpen}
        enter="transition-opacity duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <DayPicker
            className={classNames(className, 'absolute')}
            mode="range"
            defaultMonth={currentMonth}
            selected={internalRange}
            fromYear={dayjs().subtract(100, 'year').year()}
            toYear={dayjs().add(2, 'year').year()}
            captionLayout="dropdown-buttons"
            onSelect={handleRangeChange}
            footer={<Footer />}
          />
        </div>
      </Transition>
    </div>
  )
}

export default RangeDatePicker