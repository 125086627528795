import { FormEvent, ReactNode, useEffect } from 'react'
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  UseFormReturn,
  ValidationMode,
  useForm,
} from 'react-hook-form'
import { ZodType } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'
import { useQueryError } from 'hooks/useQueryError'
import { isEqual } from 'lodash'

export type FormProps<
  TFormValues extends FieldValues,
  ValidationSchema extends ZodType = ZodType
> = {
  onSubmit?: (fields: TFormValues, methods?: UseFormReturn<TFormValues>) => void;
  className?: string;
  validationSchema?: ValidationSchema;
  error?: FetchBaseQueryError | SerializedError;
  children: (methods: UseFormReturn<TFormValues>) => ReactNode;
  defaultValues?: DefaultValues<TFormValues>;
  mode?: keyof ValidationMode;
};

export function Form<
  TFormValues extends FieldValues,
  ValidationSchema extends ZodType = ZodType
>({
  onSubmit,
  error,
  className,
  validationSchema,
  defaultValues,
  mode = 'all',
  children,
}: FormProps<TFormValues, ValidationSchema>) {
  const methods = useForm({
    mode,
    defaultValues,
    resolver: validationSchema && zodResolver(validationSchema),
  })

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation()

    /* 
      Be aware that if onSubmit is not present, then this will affect
      when validation is executed due to methods.handleSubmit not being
      triggered. Submit buttons will act like normal and refresh the page.
    */
    if (!onSubmit) return

    methods.handleSubmit((data: TFormValues) => onSubmit(data, methods))(event)
  }

  /*
    Sometimes we might make an async request that doesn't contain the correct defaultValues
    during initial render. This useEffect will reset form state once that operation is complete.
  */
  useEffect(() => {
    if (!isEqual(methods.formState.defaultValues, defaultValues)) {
      methods.reset(defaultValues)
    }
  }, [defaultValues])

  useQueryError<TFormValues>({ error, setError: methods.setError })

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} className={className}>
        {/* fieldset uses min-width: min-content by default, so we override it */}
        <fieldset className="flex flex-col w-full min-w-0">
          {children(methods)}
        </fieldset>
      </form>
    </FormProvider>
  )
}