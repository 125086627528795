import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'store'

const AdminOutlet = () => {
  const location = useLocation()

  const { user, isAdmin} = useSelector((state: RootState) => ({
    isAdmin: state.auth.is_admin,
    user: state.auth.user,
  }))

  if (user && isAdmin) {
    if (location.pathname === '/admin') {
      return <Navigate to="/admin/dashboard" />
    }
  }

  if (!user && location.pathname !== '/admin/login' && location.pathname !== '/admin/verify-account') {
    return <Navigate to="/admin/login" />
  }

  if (user && !isAdmin) {
    return <Navigate to="/" />
  }

  return (
    <div className="w-full h-screen overflow-y-auto">
      <Outlet />
    </div>
  )
}

export default AdminOutlet