import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useState } from 'react'
import ChevronIcon from '../icons/ChevronUpIcon'

type CollapseProps = {
  header: string
  body: string | JSX.Element
  bodyClassName?: string
  defaultOpen?: boolean
}

const Collapse = ({ header, body, bodyClassName, defaultOpen = false }: CollapseProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen ?? false)

  return (
    <div className="flex flex-col w-full group">
      <div className='w-full mx-auto'>
        <div className={classNames('flex items-center gap-2 p-2 bg-gray-100 cursor-pointer bg-custom group-first:rounded-t-md group-last:rounded-b-md border border-secondary-gray', isOpen && 'group-last:rounded-b-none')} onClick={() => setIsOpen(!isOpen)}>
          <ChevronIcon className={classNames(
            'transition -rotate-90 w-4 h-4',
            {
              'rotate-0': isOpen,
            }
          )} />
          <h1 className='text-sm font-normal text-black opacity-80 '>{header}</h1>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <div className={`p-3 overflow-x-auto text-xs text-black bg-white border-secondary-gray border-x group-last:border-b max-h-20 lg:max-h-48 text-opacity-70 md:text-sm group-last:rounded-b-md break-words ${bodyClassName}`}>
            {body}
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default Collapse