import { Title } from 'components/headings'
import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRegisterMutation } from 'api'
import Button from 'components/button'
import Card from 'components/cards/default'
import Input from 'components/form/input'
import Phone, { PhoneInputProps } from 'components/form/phone'
import { compareArrays } from 'helpers/arrays'
import useErrorHandler from 'hooks/useErrorHandler'
import PostCodeLookup, { ExpectedAddressDetails } from 'components/form/postcode_lookup'
import { updateAuthUser } from 'slices/auth'
import { RegisterState } from 'types/registerState'

type FormInputs = {
  first_name: string,
  last_name: string,
  email: string,
  country_code: string,
  phone: string,
  street_address: string,
  second_line: string,
  city: string,
  county: string,
  postcode: string,
}

const RegisterStepTwo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandler = useErrorHandler()
  const state = useLocation().state as RegisterState

  if (state === null){
    return <Navigate to={'/register/step-one'} state={{acceptedTerms: false}} />
  }
  
  const [searchParams] = useSearchParams()

  const requiredFields = ['first_name', 'last_name', 'email', 'phone']

  const { register, handleSubmit, setValue, control, setError, clearErrors, formState: { errors }, resetField, watch } = useForm<FormInputs>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      country_code: '',
      postcode: '',
    },
  })

  const { dirtyFields } = useFormState<FormInputs>({
    control,
  })

  const [submitRegister, { data: registerResult, isLoading, error: submissionError }] = useRegisterMutation()

  useEffect(() => {
    if (submissionError) {
      errorHandler(submissionError, setError)
    }
  }, [submissionError])

  useEffect(() => {
    if (registerResult) {
      // We are not authenticating the user here, but adding the user information to state
      dispatch(updateAuthUser(registerResult.user))
      navigate('/register/step-three', { state: state })
    }
  }, [dispatch, registerResult])

  useEffect(() => {
    if (watch('phone') === undefined) {
      resetField('phone')
    }
  }, [watch('phone')])

  const onSubmit = (data: FormInputs) => {
    submitRegister({
      masked_uid: searchParams?.get('asset') ?? undefined,
      ...data,
    })
  }

  const handleSelect = (e: ExpectedAddressDetails) => {
    setValue('postcode', e.PostalCode)
    setValue('street_address', e.Line1)
    setValue('second_line', e.Line2)
    setValue('city', e.City)
    setValue('county', e.Province)
  }

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone, { shouldDirty: true })
    setValue('country_code', phone.country_code)

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full">
      <Card className="min-h-full">
        <Title>Account Details</Title>
        <div className="flex flex-col gap-4 mt-8 mb-3">
          <div className="flex gap-3">
            <Input {...register('first_name', { required: true })} placeholder="First Name *" error={errors?.first_name?.message} />
            <Input {...register('last_name', { required: true })} placeholder="Last Name *" error={errors?.last_name?.message} />
          </div>
          <Input {...register('email', { required: true })} type="email" placeholder="Email *" error={errors?.email?.message} />
          <Phone
            onChange={(phone) => handlePhoneOnChange(phone)} 
            error={errors?.phone?.message || errors?.country_code?.message} 
          />
          <div className="flex flex-col gap-4">
            <PostCodeLookup onSelect={(object) => handleSelect(object)} />
            <Input {...register('postcode')} placeholder="Postcode" />
            <Input {...register('street_address')} placeholder="Street Address" />
            <Input {...register('second_line')} placeholder="Second Line" />
            <div className="flex gap-3">
              <Input {...register('city')} placeholder="City" />
              <Input {...register('county')} placeholder="County" />
            </div>
          </div>
        </div>
        <div className='flex flex-col h-full gap-2 mt:0 lg:mt-2'>
          <p className="px-1 my-6 text-sm text-center rounded-md text-primary-gray">If you already have an account, or abandoned your previous registration, <Link to="/login" className="font-medium transition text-secondary-blue hover:text-secondary-dark-blue">try logging in</Link></p>
          <div className="flex flex-col gap-2 mt-auto">
            <Button type="submit" block disabled={!compareArrays(requiredFields, Object.keys(dirtyFields))} isLoading={isLoading}>Continue</Button>
            <Button href="/" type="button" block variant="link" className="text-sm">Cancel</Button>
          </div>
        </div>
      </Card>
    </form>
  )
}

export default RegisterStepTwo