import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import CrossIcon from 'components/icons/CrossIcon'
import React, { Fragment } from 'react'
import ModalContent from './components/content'
import ModalFooter from './components/footer'
import ModalHeader from './components/header'

type ModalProps = {
  isOpen: boolean,
  className?: string,
  onClose: () => void,
  afterLeave?: () => void,
  beforeEnter?: () => void,
  containerClassName?: string,
  loading?: boolean,
  children?: React.ReactNode
  mobilePadding?: boolean
}

const Modal = ({
  onClose,
  children,
  className,
  containerClassName,
  loading,
  beforeEnter,
  afterLeave,
  isOpen = false,
  mobilePadding = false,
}: ModalProps): JSX.Element => {
  return (
    <Transition show={isOpen} as={Fragment} afterLeave={afterLeave} beforeEnter={beforeEnter}>
      <Dialog onClose={onClose} className="relative z-[999] flex items-center justify-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-10" />
        </Transition.Child>

        <div className={classNames('fixed inset-0 flex items-center justify-center', {
          'px-2': mobilePadding,
        }, containerClassName)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-full lg:translate-y-0 lg:opacity-0 scale-95"
            enterTo="translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0 lg:opacity-100 scale-100"
            leaveTo="translate-y-full lg:translate-y-0 opacity-40 scale-95"
          >
            <Dialog.Panel className={classNames('relative lg:max-w-4xl w-full max-h-full h-min bg-white border mx-auto rounded-md rounded-b-none lg:rounded-md self-end lg:self-center', className)}>
              <div className="absolute right-0 px-6 py-4 text-primary-gray">
                <button className="cursor-pointer focus:outline-primary focus:shadow-focus-large" onClick={onClose}>
                  <CrossIcon className="w-3 h-3 text-primary-gray" />
                </button>
              </div>
              {!loading && children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.Header = ModalHeader
Modal.Content = ModalContent
Modal.Footer = ModalFooter

export default Modal
