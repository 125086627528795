import Device from 'api/types/models/device'
import Button from 'components/button'
import LockIcon from 'components/icons/LockIcon'
import TrashIcon from 'components/icons/TrashIcon'
import UnlinkIcon from 'components/icons/UnlinkIcon'
import PhoneUrl from 'components/phone_url'

type DeviceCardProps = {
  device: Device
  onRemoveClick?: () => void
  onDetachClick?: () => void
}

const DeviceCard = ({ device, onRemoveClick, onDetachClick }: DeviceCardProps) => {
  return (
    <>
      {
        device.is_asset_owner ? (
          <div className="flex items-center justify-between bg-muted-gray p-4 border-primary-gray border rounded-md">
            <div className="flex flex-col mr-2">
              <p>{device.name} ({device.relationship})</p>
              <PhoneUrl phone={device.full_phone_number} />
            </div>
            <Button
              isIconOnly
              icon={<LockIcon className="h-5 w-5" />}
              disabled
            />
          </div>
        ) : (
          <div className="flex items-center justify-between bg-muted-gray p-4">
            <div className="flex flex-col flex-grow mr-2">
              <p>{device.name} ({device.relationship})</p>
              <PhoneUrl phone={device.full_phone_number} />
            </div>
            <div className="flex gap-2 pl-2 py-2">
              <Button
                isIconOnly
                icon={<UnlinkIcon className="w-5 h-5" />}
                onClick={onDetachClick}
                variant="secondary"
              />
              <Button
                isIconOnly
                icon={<TrashIcon className="w-5 h-5" />}
                onClick={onRemoveClick}
                variant="danger"
              />
            </div>
          </div>
        )
      }
    </>
  )
}

export default DeviceCard
