import { useAdminGetReportQuery } from 'api/endpoints/admin/report'
import Select from 'react-select'
import { useFilters } from 'hooks/useFilters'
import { useMemo } from 'react'
import ReportRequest from 'api/types/requests/admin/report/get'
import AdminReportTable from './table'
import RangeDatePicker from 'components/date-picker/range'

const ReportsPage = () => {
  const [{ filters }, setFilters] = useFilters<ReportRequest>({
    initialFilters: {
      date_from: undefined,
      date_to: undefined,
      client_id: undefined,
    },
  })

  const {
    data: reportData,
    isLoading: isReportLoading,
  } = useAdminGetReportQuery({ ...filters }, { refetchOnMountOrArgChange: true })

  const clientOptions = useMemo(() => {
    return reportData?.clients?.map(client => ({
      value: client.id,
      label: client.display_name,
    })) ?? []
  }, [reportData])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
        <div className="flex flex-col">
          <h1 className="text-2xl font-medium text-slate-800">
            Reports
          </h1>
        </div>
      </div>

      <div className="flex flex-wrap items-start gap-3 px-3 pt-3 lg:px-6 lg:pt-6">
        <Select
          isClearable
          isLoading={isReportLoading}
          placeholder="Filter by Client (all selected)"
          classNamePrefix="react-select"
          className="react-select-container"
          onChange={(option) => setFilters({
            ...filters,
            client_id: option?.value,
          })}
          options={clientOptions}
        />
        <RangeDatePicker
          placeholder='Filter by Date'
          className='absolute'
          onChange={(date) => setFilters({
            ...filters,
            date_from: date.from,
            date_to: date.to,
          })
          }
          isLoading={isReportLoading || !reportData}
        />
      </div>

      <AdminReportTable
        isFiltering={!!filters.client_id}
        reportData={reportData}
      />
    </div>
  )
}

export default ReportsPage