import PostCodeLookup from 'components/form/postcode_lookup'
import { Title } from 'components/headings'
import { useSelector } from 'react-redux'
import { useUpdateAccountMutation } from 'api'
import Button from 'components/button'
import Card from 'components/cards/default'
import Input from 'components/form/input'
import Phone from 'components/form/phone'
import { RootState } from 'store'
import ArrowIcon from 'components/icons/ArrowIcon'
import { Form } from 'components/form/form'
import { z } from 'zod'
import { FormLabel } from 'components/form/label'
import { FormControl } from 'components/form/control'
import { HorizontalDivider } from 'components/Dividers/horizontal'
import { RadioCardGroup } from 'components/form/radio_card_group'
import { RadioCardOption } from 'components/form/radio_card'
import AssetNotificationMethod from 'enums/AssetNotificationMethod'
import { useToast } from 'hooks/useToast'
import { UseFormReturn } from 'react-hook-form'
import { ConfirmationModal } from 'components/modals/confirmation'
import { useEffect, useState } from 'react'
import { User } from 'api/types/models/user'

const accountSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address').min(1, 'Email is required'),
  phone: z.string().min(1, 'Phone is required'),
  country_code: z.string().min(1, 'Country code is required'),

  street_address: z.string().nullable().optional(),
  second_line: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  county: z.string().nullable().optional(),
  postcode: z.string().nullable().optional(),

  notification_enabled: z.boolean().nullable(),
  notification_method: z.nativeEnum(AssetNotificationMethod).nullable(),
})

type AccountFormInputs = z.infer<typeof accountSchema>

const FormWrapper = ({
  register,
  control,
  getValues,
  watch,
  setValue,
  clearErrors,
  isLoading,
  data,
  formState: { errors },
}: UseFormReturn<AccountFormInputs> & {
  isLoading: boolean,
  data: { user: User } | undefined
},
) => {
  const { authUser } = useSelector((state: RootState) => ({
    authUser: state.auth.user,
  }))

  const [openChannelConfirmationModal, setOpenChannelConfirmationModal] = useState(false)
  const [openEnabledConfirmationModal, setOpenEnabledConfirmationModal] = useState(false)

  useEffect(() => {
    if (watch('notification_method')) {
      setOpenChannelConfirmationModal(true)
    }
  }, [watch('notification_method')])

  useEffect(() => {
    if (watch('notification_enabled') !== null) {
      setOpenEnabledConfirmationModal(true)
    }
  }, [watch('notification_enabled')])

  return (
    <div className="space-y-4">
      <div>
        <Title className="!text-left !m-0" size="lg">Details</Title>
        <p className="text-sm text-gray-500">Manage your account details</p>
      </div>

      <HorizontalDivider />

      <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            {...register('first_name')}
            placeholder="Enter first name"
            error={errors?.first_name?.message}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            {...register('last_name')}
            placeholder="Enter last name"
            error={errors?.last_name?.message}
          />
        </FormControl>
      </div>

      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          {...register('email')}
          placeholder="Enter email"
          error={errors?.email?.message}
          disabled={authUser?.is_admin}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Phone</FormLabel>
        <Phone
          defaultPhone={getValues('phone')}
          defaultCountryCode={getValues('country_code')}
          onChange={(phone) => {
            setValue('phone', phone.phone)
            setValue('country_code', phone.country_code)

            if (errors.phone || errors.country_code) {
              clearErrors(['country_code', 'phone'])
            }
          }}
          error={errors?.phone?.message || errors?.country_code?.message}
        />
      </FormControl>

      <div className="!mt-8">
        <Title className="!text-left !m-0" size="lg">Address</Title>
        <p className="text-sm text-gray-500">Manage your address</p>
      </div>

      <HorizontalDivider />

      <FormControl>
        <PostCodeLookup
          onSelect={(object) => {
            setValue('postcode', object.PostalCode)
            setValue('street_address', object.Line1)
            setValue('second_line', object.Line2)
            setValue('city', object.City)
            setValue('county', object.Province)
          }}
        />
      </FormControl>

      <HorizontalDivider />

      <FormControl>
        <FormLabel>Line 1</FormLabel>
        <Input
          {...register('street_address')}
          placeholder="Enter street address"
          error={errors?.street_address?.message}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Line 2</FormLabel>
        <Input
          {...register('second_line')}
          placeholder="Enter second line"
          error={errors?.second_line?.message}
        />
      </FormControl>

      <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input
            {...register('city')}
            placeholder="Enter city"
            error={errors?.city?.message}
          />
        </FormControl>

        <FormControl>
          <FormLabel>County</FormLabel>
          <Input
            {...register('county')}
            placeholder="Enter county"
            error={errors?.county?.message}
          />
        </FormControl>
      </div>

      <FormControl>
        <FormLabel>Postcode</FormLabel>
        <Input
          {...register('postcode')}
          placeholder="Enter postcode"
          error={errors?.postcode?.message}
        />
      </FormControl>

      <div className="!mt-8">
        <Title className="!text-left !m-0" size="lg">Notification Preferences</Title>
        <p className="text-sm text-gray-500">Globally configure your notifications</p>
      </div>

      <HorizontalDivider />

      <FormControl>
        <div className="mb-4">
          <FormLabel>Channel</FormLabel>
          <p className="text-sm text-gray-500">
            Override all your KiCalls to the selected channel
          </p>
        </div>

        <RadioCardGroup name="notification_method" control={control}>
          <RadioCardOption value={AssetNotificationMethod.SMS} first>WhatsApp</RadioCardOption>
          <RadioCardOption value={AssetNotificationMethod.EMAIL} last>Email</RadioCardOption>
        </RadioCardGroup>
      </FormControl>

      <FormControl>
        <div className="mb-4">
          <FormLabel>Receive Notifications</FormLabel>
          <p className="text-sm text-gray-500">
            Set whether you receive notifications from all KiCalls
          </p>
        </div>

        <RadioCardGroup name="notification_enabled" control={control}>
          <RadioCardOption value={true} first>Receive All</RadioCardOption>
          <RadioCardOption value={false} last>Receive None</RadioCardOption>
        </RadioCardGroup>
      </FormControl>

      <Button
        type="submit"
        className="!mt-8"
        isLoading={isLoading}
        isSuccess={data && !isLoading}
        block
      >
        Update Account
      </Button>

      <ConfirmationModal
        title="Confirm Preference Override"
        btnTxt="Confirm"
        isOpen={openEnabledConfirmationModal}
        onConfirm={() => setOpenEnabledConfirmationModal(false)}
        onClose={() => {
          setValue('notification_enabled', null)
          setOpenEnabledConfirmationModal(false)
        }}
        renderContent={() => (
          <div>
            <p>By selecting <strong>{watch('notification_enabled') ? 'Receive All' : 'Receive None'}</strong>, you will be overriding all KiCalls to this notification preference.</p>
          </div>
        )}
      />

      <ConfirmationModal
        title="Confirm Preference Override"
        btnTxt="Confirm"
        onClose={() => {
          setValue('notification_method', null)
          setOpenChannelConfirmationModal(false)
        }}
        isOpen={openChannelConfirmationModal}
        onConfirm={() => setOpenChannelConfirmationModal(false)}
        renderContent={() => (
          <div>
            <p>By selecting <strong>{watch('notification_method')}</strong>, you will be overriding all KiCalls to this notification preference.</p>
          </div>
        )}
      />
    </div>
  )
}

const AccountPage = () => {
  const { errorToast } = useToast()

  const [updateAccount, {
    data: updatedAccountData,
    isLoading: isUpdatingAccount,
    error: updateError,
  }] = useUpdateAccountMutation()

  const { authUser } = useSelector((state: RootState) => ({
    authUser: state.auth.user,
  }))

  const handleSubmit = async (inputs: AccountFormInputs, method?: UseFormReturn<AccountFormInputs>) => {
    await updateAccount({
      ...inputs,
      id: authUser!.id,
      street_address: inputs?.street_address ?? null,
      second_line: inputs?.second_line ?? null,
      city: inputs?.city ?? null,
      county: inputs?.county ?? null,
      postcode: inputs?.postcode ?? null,
      notification_enabled: inputs?.notification_enabled ?? null,
      notification_method: inputs?.notification_method ?? null,
    })
      .unwrap()
      .catch(() => errorToast({
        title: 'Account Update Failed',
        message: 'Your account and preferences could not be updated, please try again later',
      }))

    method?.resetField('notification_method')
    method?.resetField('notification_enabled')
  }

  return (
    <Card>
      <div className="mb-6 ">
        <Button
          isIconOnly
          icon={<ArrowIcon className="w-5 h-5" />}
          href="/asset/manage/list"
          type="button"
          className="text-sm w-min"
          variant="secondary"
        />

        <Title className="!m-0 !justify-self-center">
          Manage Account
        </Title>
      </div>

      <Form<AccountFormInputs, typeof accountSchema>
        onSubmit={handleSubmit}
        error={updateError}
        validationSchema={accountSchema}
        defaultValues={{
          first_name: authUser?.first_name ?? '',
          last_name: authUser?.last_name ?? '',
          email: authUser?.email ?? '',
          country_code: authUser?.country_code ?? '44',
          phone: authUser?.phone ?? '',
          postcode: authUser?.postcode ?? '',
          street_address: authUser?.street_address ?? '',
          second_line: authUser?.second_line ?? '',
          city: authUser?.city ?? '',
          county: authUser?.county ?? '',
          notification_enabled: null,
          notification_method: null,
        }}
      >
        {(methods) => <FormWrapper {...methods} isLoading={isUpdatingAccount} data={updatedAccountData} />}
      </Form>
    </Card>
  )
}

export default AccountPage

