import { Message } from 'api/types/models/message'
import { Form } from 'components/form/form'
import { WeekDay } from 'enums/WeekDay'
import { timePeriodSchema } from 'helpers/validation/fields/time'
import { PropsWithChildren, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormBaseProps } from 'types/form'
import { z } from 'zod'
import FormLayout from '../../layouts/FormLayout'
import Input from 'components/form/input'
import ReactQuill from 'react-quill'
import FormError from 'components/error'
import Toggle from 'components/form/toggle'
import { WeekDays } from 'components/week-days'
import { MessageConflict } from 'feature/message/components/lists/conflict'
import { useGetMessagesQuery } from 'api/endpoints/user/message'
import { DateTime } from 'luxon'
import Button from 'components/button'
import { ConfirmationModal } from 'components/modals/confirmation'
import { DeleteMessageModal } from 'components/modals/forms/message'
import { useNavigate } from 'react-router-dom'

// ---

const baseMessageSchema = z.object({
  is_default: z.boolean(),
  name: z.string().min(1, 'Name is required'),
  content: z.string().min(1, 'Content is required'),
})

const defaultMessageSchema = z.object({
  is_default: z.literal(true),
})

const timeFrameSchema = z.object({
  is_default: z.literal(false),
  days_of_week: z.array(z.nativeEnum(WeekDay)).min(1, 'At least one day of the week is required'),
}).and(timePeriodSchema)

const adminMessageSchema = baseMessageSchema.and(z.union([timeFrameSchema, defaultMessageSchema]))

export type AdminMessageFormInputs = z.infer<typeof adminMessageSchema> & {
  asset_id: number | undefined,
}

type AdminMessageFormProps = FormBaseProps<AdminMessageFormInputs> & {
  message?: Message | null
  btnText: string,
  isLoading: boolean,
}

// ---

const toolbarOptions = [
  [{ 'header': [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'link'],
  [{ align: '' }, { align: 'center' }, { align: 'right' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }, { 'list': 'ordered' }, { 'list': 'bullet' }],
  ['clean'],
]

function FormWrapper({
  message,
  watch,
  control,
  trigger,
  register,
  getValues,
  setValue,
  formState: { errors, defaultValues },
  children,
}: PropsWithChildren<UseFormReturn<AdminMessageFormInputs> & { message: Message | null | undefined }>) {
  const navigate = useNavigate()
  const messageId = message?.id?.toString() ?? '0'
  const assetUid = message?.asset?.masked_uid

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { data: existingMessages } = useGetMessagesQuery(
    { asset_identifier: assetUid! },
    { skip: !assetUid }
  )

  const conflictingTimeFrames = useMemo(() => {
    const messageTimeFrames = getValues('days_of_week')?.map(day => ({
      day_of_week: day,
      start_time: getValues('start_time'),
      end_time: getValues('end_time'),
    }))

    const existingTimeFrames = existingMessages
      ?.reduce((acc, message) => {
        return [
          ...acc,
          ...message.time_frames?.map(tf => ({
            messageId: message.id,
            name: message?.name ?? '',
            start_time: tf.start_time as string,
            end_time: tf.end_time as string,
            day_of_week: tf.day_of_week,
          })) ?? [],
        ]
      }, [] as { messageId: number, name: string, day_of_week: WeekDay, start_time: string, end_time: string }[])
      ?.filter(timeFrame => {
        if (timeFrame?.messageId === parseInt(messageId)) return

        return messageTimeFrames?.some(messageTimeFrame => {
          return timeFrame.day_of_week === messageTimeFrame.day_of_week &&
            DateTime.fromISO(timeFrame.start_time as string) <= DateTime.fromISO(messageTimeFrame.end_time as string) &&
            DateTime.fromISO(timeFrame.end_time as string) >= DateTime.fromISO(messageTimeFrame.start_time as string)
        })
      })

    return existingTimeFrames
  }, [existingMessages, watch(['days_of_week', 'start_time', 'end_time'])])

  const [openConfirmDefaultModal, setOpenConfirmDefaultModal] = useState({
    open: false,
    value: getValues('is_default'),
  })

  const handleDefaultSwitch = () => {
    setValue('is_default', openConfirmDefaultModal.value)
    setValue('days_of_week', [])
    setValue('start_time', '')
    setValue('end_time', '')
    setOpenConfirmDefaultModal({ open: false, value: true })
  }

  return (
    <FormLayout>
      <FormLayout.Block>
        <FormLayout.Group
          label="Name"
          htmlFor="name"
          description="Identification of this message"
        >
          <Input
            id="name"
            {...register('name', { required: true })}
            error={errors?.name?.message}
          />
        </FormLayout.Group>

        <FormLayout.Group
          label="Default Message"
          htmlFor="default_message_content"
          description="Customize the default message"
        >
          <ReactQuill
            theme="snow"
            value={getValues('content')}
            placeholder="Write a message and use the toolbar above to style it"
            modules={{ toolbar: toolbarOptions }}
            onChange={(html) => {
              trigger('content')
              setValue('content', html, {
                shouldDirty: getValues('content') !== defaultValues?.content,
              })
            }}
          />
          {
            errors?.content?.message &&
              <FormError text={errors.content.message} />
          }
        </FormLayout.Group>

        <FormLayout.Group
          label="Time Frame"
          description="Configure when this message is displayed and repeats"
        >
          <div className="flex flex-col gap-4">
            <WeekDays name="days_of_week" control={control} disabled={watch('is_default')} />
            {
              'days_of_week' in errors && errors?.days_of_week?.message &&
              <FormError text={errors?.days_of_week?.message} />
            }

            <Input
              type="time"
              {...register('start_time')}
              disabled={watch('is_default')}
            />

            <Input
              type="time"
              {...register('end_time')}
              disabled={watch('is_default')}
            />

            <div className="grid grid-cols-2 gap-14">
              {
                'start_time' in errors && errors?.start_time?.message &&
                <FormError text={errors?.start_time?.message} />
              }
              {
                'end_time' in errors && errors?.end_time?.message &&
                <FormError text={errors?.end_time?.message} />
              }
            </div>

            <MessageConflict value={conflictingTimeFrames ?? []} />
          </div>
        </FormLayout.Group>

        <FormLayout.Group
          label="Default Message"
          htmlFor="is_default"
          description="Set this message to be the default message"
          intent="danger"
        >
          <Toggle
            disabled={watch('is_default')}
            checked={watch('is_default')}
            onChange={(value) => setOpenConfirmDefaultModal({ open: true, value })}
          />
        </FormLayout.Group>

        {
          message &&
          <FormLayout.Group
            label="Delete Message"
            description="Delete this message from the asset"
            intent="danger"
          >
            <Button
              variant="danger"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete
            </Button>
          </FormLayout.Group>
        }

      </FormLayout.Block>


      {children}

      <ConfirmationModal
        title="Confirm Default Message"
        isOpen={openConfirmDefaultModal.open}
        onClose={() => setOpenConfirmDefaultModal({ open: false, value: false })}
        renderContent={() => (
          <div>
            <p>You&apos;re switching this scheduled message to the <strong>default message.</strong></p>
            <br />
            <p>Your existing default message will be changed to a scheduled message and will need to be configured.</p>
            <br />
            <p>This message will display outside of scheduled messages if confirmed.</p>
            <br />
            <p>Confirming this action will not allow you to update the time frames in this form.</p>
          </div>
        )}
        onConfirm={handleDefaultSwitch}
      />

      {
        message &&
        <DeleteMessageModal
          message={message}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={() => navigate(`/admin/dashboard/assets/manage/${message.asset!.id}`)}
          renderContent={
            <>
              <p>You&apos;re about to delete the message <strong>{message?.name ?? ''}</strong> from an asset.</p>
              <p>By performing this action, associated time frames will also be permanently removed.</p>
              <p>You will be returned to the asset on completion of this action.</p>
            </>
          }
        />
      }

    </FormLayout>
  )
}

const AdminMessageForm = ({
  message,
  onSubmit,
  isLoading,
  errors,
  defaultValues,
  btnText,
}: AdminMessageFormProps) => {
  return (
    <>
      <Form<AdminMessageFormInputs, typeof adminMessageSchema>
        onSubmit={onSubmit}
        error={errors}
        validationSchema={adminMessageSchema}
        defaultValues={defaultValues}
      >
        {(methods) => {
          return (
            <FormWrapper
              {...methods}
              message={message}
            >
              <FormLayout.Footer>
                <Button
                  variant="secondary"
                  className="hidden lg:block"
                  href="/admin/dashboard/assets/browse" // Send back to asset
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                >
                  {btnText}
                </Button>
              </FormLayout.Footer>
            </FormWrapper>
          )
        }}
      </Form>
    </>
  )
}

export default AdminMessageForm