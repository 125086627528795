import classNames from 'classnames'
import CrossIcon from '../icons/CrossIcon'

type NotificationProps = {
  title?: string
  message: string,
  type?: 'success' | 'info' | 'error' | 'warning',
  dismiss?: () => void,
}

const NotificationToast = ({ title, message, type = 'success', dismiss }: NotificationProps) => {

  const notificationClasses = classNames({
    'bg-notification-success-secondary border-notification-success-primary': type === 'success',
    'bg-notification-info-secondary border-notification-info-primary': type === 'info',
    'bg-notification-error-secondary border-notification-error-primary': type === 'error',
    'bg-notification-warning-secondary border-notification-warning-primary': type === 'warning',
  })

  const indicatorClasses = classNames({
    'bg-indicator-success': type === 'success',
    'bg-indicator-info': type === 'info',
    'bg-indicator-error': type === 'error',
    'bg-indicator-warning': type === 'warning',
  })

  return (
    <div className={`flex gap-3 border p-[17px] rounded ${notificationClasses}`}>

      <div className={`min-w-[21px] min-h-[20px] max-w-[21px] max-h-[20px] rounded-full ${indicatorClasses}`}></div>

      <div>
        <p className="font-semibold leading-6 capitalize text-notification-title">{title ?? type}</p>

        <p className="mt-1 text-sm leading-5 text-notification-message">{message}</p>
      </div>

      <div className="relative ml-auto -mt-1">
        <button
          type="button"
          onClick={dismiss}
        >
          <CrossIcon className="text-black text-opacity-[0.45] w-3 h-3" />
        </button>
      </div>

    </div>
  )
}

export default NotificationToast