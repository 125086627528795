import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store'
import { useAdminGetUsersQuery } from 'api'
import Button from 'components/button'
import { User } from 'api/types/models/user'
import AdminTable from 'components/admin/tables'
import Pagination from 'components/pagination'
import DeleteUserModal from 'components/modals/forms/user/DeleteUserModal'
import { useSort } from 'hooks/useSort'
import { UserRole, getFormattedRole } from 'enums/UserRole'
import { Badge } from 'components/badge'
import { FormatDateTime } from 'components/format-datetime'
import { DateTime } from 'luxon'
import { useLazyTimeout } from 'hooks/useLazyTimeout'
import { useResendInviteMutation } from 'api/endpoints/invite'
import { useToast } from 'hooks/useToast'
import { useAuthorization } from 'hooks/useAuthorization'
import { useAdminGetAccessRequestsQuery } from 'api/endpoints/admin/request-access'
import { AdminAccessRequest } from 'components/modals/forms/access-request'
import { MfaRequestStatus } from 'enums/MfaRequestStatus'
import { AccessRequestLayout } from 'layouts/AccessRequestLayout'

const AdminUserBrowsePage = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const [accessRequestUser, setAccessRequestUser] = useState<User | null>(null)
  const [openAccessRequestModal, setOpenAccessRequestModal] = useState<boolean>(false)

  const authUser = useSelector((state: RootState) => state.auth.user)

  const { successToast, errorToast } = useToast()

  const [toggled, triggerResend] = useLazyTimeout({ delay: 10000 })

  const { checkPermission } = useAuthorization()

  const [currentPage, setCurrentPage] = useState<number>(1)

  const { sorts, toggleSorts } = useSort({
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    is_admin: undefined,
    role: undefined,
    verified_at: undefined,
  }, () => setCurrentPage(1))

  const {
    data: accessRequests,
  } = useAdminGetAccessRequestsQuery({})

  const {
    data: users,
    isLoading: isLoadingUsers,
  } = useAdminGetUsersQuery({
    page: currentPage,
    sort: sorts,
  }, { refetchOnMountOrArgChange: true })

  const [resendInvite, {
    isLoading: isResendingInvite,
  }] = useResendInviteMutation()

  const openUserDeleteModal = (user: User) => {
    setSelectedUser(user)
    setIsDeleteModalOpen(true)
  }

  const handleResendInvite = async (userId: number) => {
    if (!toggled) {
      triggerResend()

      await resendInvite({ user_id: userId })
        .unwrap()
        .then(() => successToast({
          title: 'Invite Sent',
          message: 'An invite link has been resent to this user.',
        }))
        .catch(() => errorToast({
          title: 'Failed to Resend Invite',
          message: 'We\'re unable to resend the invite at this time. Please try again later.',
        }))
    }
  }

  const parsedUsers = useMemo(() => {
    return users?.data?.map(user => {
      const accessRequest = accessRequests?.find(request => request?.user_id === user.id)

      let hasAccess = false

      // If the user is an admin, then we don't need to request access to information
      if (user?.is_admin) {
        hasAccess = true
      }

      // If the user is a super admin, then they have access to everything
      if (authUser?.role === UserRole.SUPER_ADMIN) {
        hasAccess = true
      }

      // If the auth admin has redeemed, and non-expired access request, then they should have access
      if (accessRequest && accessRequest?.status === MfaRequestStatus.REDEEMED) {
        hasAccess = true
      }

      return {
        ...user,
        has_access: hasAccess,
        access_request: accessRequest,
      }
    })
  }, [users, accessRequests])

  return (
    <AccessRequestLayout blockRedirect={true}>
      <div className="flex flex-col h-full">
        <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
          <h1 className="text-2xl font-medium text-slate-800">
            Users
          </h1>
          <div className="flex gap-4">
            <Button href="/admin/dashboard/users/create" className="hidden lg:block">
              Create
            </Button>
            {
              checkPermission('user:invite') &&
              <Button href="/admin/dashboard/users/invite" variant="secondary" className="hidden lg:block">
                Invite Admin
              </Button>
            }
          </div>
        </div>
        <div className="p-3 overflow-x-scroll lg:p-6">
          <AdminTable isLoading={isLoadingUsers}>
            <AdminTable.Head>
              <AdminTable.Heading>#</AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.first_name}
                onClick={() => toggleSorts('first_name')}
              >
                First Name
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.last_name}
                onClick={() => toggleSorts('last_name')}
              >
                Last Name
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.email}
                onClick={() => toggleSorts('email')}
              >
                Email
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.role}
                onClick={() => toggleSorts('role')}
              >
                Role
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.verified_at}
                onClick={() => toggleSorts('verified_at')}
              >
                Joined
              </AdminTable.Heading>
              <AdminTable.Heading>Actions</AdminTable.Heading>
            </AdminTable.Head>
            <AdminTable.Body>
              {
                parsedUsers?.map((user) => (
                  <AdminTable.Row key={user.id}>
                    <AdminTable.Column>{user.id}</AdminTable.Column>
                    <AdminTable.Column>{user?.first_name}</AdminTable.Column>
                    <AdminTable.Column>{user?.last_name}</AdminTable.Column>
                    <AdminTable.Column>{user.email}</AdminTable.Column>
                    <AdminTable.Column>
                      <Badge intent={user.is_admin ? 'danger' : 'primary'}>
                        {getFormattedRole(user.role)}
                      </Badge>
                    </AdminTable.Column>
                    <AdminTable.Column>
                      {
                        user?.verified_at ? (
                          <FormatDateTime value={user?.verified_at} format={DateTime.DATE_MED} />
                        ) : (
                          <div className="flex items-center gap-1">
                            <p className="text-yellow-700">Pending</p>
                            {
                              checkPermission('user:invite') &&
                              <Button
                                variant="link"
                                disabled={toggled}
                                onClick={() => handleResendInvite(user.id)}
                                className="!p-0 !min-w-0 w-[80px] !justify-start text-primary disabled:bg-white disabled:text-gray-400 disabled:cursor-default"
                              >
                                <span>({isResendingInvite ? 'Resending...' : toggled ? 'Resent' : 'Resend'})</span>
                              </Button>
                            }
                          </div>
                        )
                      }
                    </AdminTable.Column>
                    <AdminTable.Column>
                      <div className="flex text-sm divide-x">
                        {
                          user?.has_access ? (
                            <>
                              {
                                checkPermission('user:update', user) &&
                                <Link to={`/admin/dashboard/users/manage/${user.id}`} className="px-1 url-text">
                                  Manage
                                </Link>
                              }

                              {
                                checkPermission('user:delete', user) &&
                                <button className="px-1 text-red-500" onClick={() => openUserDeleteModal(user)}>
                                  Delete
                                </button>
                              }
                            </>
                          ) : (
                            <button
                              className="px-1 text-primary hover:underline"
                              onClick={() => {
                                setAccessRequestUser(user)
                                setOpenAccessRequestModal(true)
                              }}
                            >
                              {user?.access_request ? 'Verify Request' : 'Request Access'}
                            </button>
                          )
                        }
                      </div>
                    </AdminTable.Column>
                  </AdminTable.Row>
                ))
              }
            </AdminTable.Body>
          </AdminTable>
        </div>

        <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
          <Pagination
            onPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            totalPages={users?.meta?.last_page ?? 1}
          />
        </div>

        <DeleteUserModal
          user={selectedUser!}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        />

        <AdminAccessRequest
          userId={accessRequestUser?.id ?? null}
          href={`/admin/dashboard/assets/manage/${accessRequestUser?.id}`}
          isOpen={openAccessRequestModal}
          onClose={() => setOpenAccessRequestModal(false)}
        />
      </div>
    </AccessRequestLayout>
  )
}

export default AdminUserBrowsePage