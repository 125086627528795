// Additions to this file needs to be reflected on the backend file

export enum CreditLogReason {
  LOGIN_MFA = 'login_mfa',
  ACCESS_REQUEST = 'access_request',
  REGISTRATION_MFA = 'registration_mfa',
  PUBLIC_ASSET_SCANNED = 'public_asset_scanned',
  PRIVATE_ASSET_SCANNED = 'private_asset_scanned',
  ASSET_OWNER_AUTH_MFA = 'asset_owner_auth_mfa',
  ASSET_DEVICE_AUTH_MFA = 'asset_device_auth_mfa',
}

export function getFormattedCreditReason(reason: CreditLogReason) {
  const enumerables = {
    [CreditLogReason.LOGIN_MFA]: 'Login MFA',
    [CreditLogReason.ACCESS_REQUEST]: 'Access Request',
    [CreditLogReason.REGISTRATION_MFA]: 'Registration MFA',
    [CreditLogReason.PUBLIC_ASSET_SCANNED]: 'Public Asset Scan',
    [CreditLogReason.PRIVATE_ASSET_SCANNED]: 'Private Asset Scan',
    [CreditLogReason.ASSET_OWNER_AUTH_MFA]: 'Asset Owner Auth MFA',
    [CreditLogReason.ASSET_DEVICE_AUTH_MFA]: 'Asset Device Auth MFA',
  }

  return enumerables?.[reason]
}

export default CreditLogReason