import { DateTime } from 'luxon'
import AdminTable from 'components/admin/tables'
import { CreditBlockTableSkeletonRow } from './skeleton'
import { CreditBlock } from 'api/types/models/credit_block'
import { FormatDateTime } from 'components/format-datetime'

type CreditBlockTableProps = {
  value?: CreditBlock[]
  isLoading?: boolean
}

export function CreditBlockTable({ value, isLoading }: CreditBlockTableProps) {
  return (
    <AdminTable>
      <AdminTable.Head>
        <AdminTable.Heading>Credits</AdminTable.Heading>
        <AdminTable.Heading>Purchase Date</AdminTable.Heading>
      </AdminTable.Head>
    
      <AdminTable.Body>
        {
          isLoading && (
            <>
              {Array.from({ length: 10 }).map((_, idx) => (
                <CreditBlockTableSkeletonRow key={idx} />
              ))}
            </>
          )
        }

        {
          !isLoading && !value?.length && (
            <AdminTable.Row>
              <AdminTable.Column colSpan={2} className="text-center">
                <span className="text-base">
                  No credit blocks found
                </span>
              </AdminTable.Column>
            </AdminTable.Row>
          )
        }

        {
          value?.map((creditBlock) => (
            <AdminTable.Row key={creditBlock.id}>            
              <AdminTable.Column>
                {creditBlock.amount}
              </AdminTable.Column>

              <AdminTable.Column>
                <FormatDateTime value={creditBlock?.purchase_date} format={DateTime.DATE_MED} />
              </AdminTable.Column>
            </AdminTable.Row>
          ))
        }
      </AdminTable.Body>
    </AdminTable>
  )
}