import { EyeClosedIcon } from 'components/icons/EyeClosedIcon'
import { EyeOpenIcon } from 'components/icons/EyeOpenIcon'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { cva } from 'class-variance-authority'
import classNames from 'classnames'

const inputStyling = cva('border-secondary-gray focus:outline-primary/75 text-primary-gray border leading-6 w-full py-2 pl-2 pr-9 rounded-md', {
  variants: {
    isDisabled: {
      true: 'bg-disabled text-disabled-dark',
    },
  },
})

type FormPasswordInputProps = {
  name: string
  isDisabled?: boolean
  placeholder: string
}

export const FormPasswordInput = ({
  name,
  isDisabled,
  placeholder,
}: FormPasswordInputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false)

  const { register } = useFormContext()

  return (
    <div className="relative w-full">
      <input
        id={name}
        disabled={isDisabled}
        placeholder={placeholder}
        className={inputStyling({ isDisabled })}
        type={showPassword ? 'text' : 'password'}
        {...register(name)}
      />

      <div className="absolute inset-y-0 right-[12px] flex items-center">
        <button
          type="button"
          disabled={isDisabled}
          onClick={() => setShowPassword(!showPassword)}
          tabIndex={-1} // Prevents the button from being tabbed into
          className={classNames('w-5 transition-colors outline-none focus-visible:text-primary hover:text-primary', {
            'text-disabled-dark hover:text-disabled-dark': isDisabled,
          })}
        >
          {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
        </button>
      </div>
    </div>
  )
}