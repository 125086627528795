import Button from 'components/button'
import AdminTable from 'components/admin/tables'
import Toggle from 'components/form/toggle'
import { useFieldArray, useForm } from 'react-hook-form'
import KiCallFile from 'api/types/models/file'
import { useAdminUpdateAssetFilesMutation } from 'api'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import NotificationToast from 'components/notifications'
import ArrowTopRightIcon from 'components/icons/ArrowTopRightIcon'

type FormInputs = {
  files: KiCallFile[]
}

type PropTypes = {
  assetId: number
  userId: number
  files: KiCallFile[]
}

const AssetFileTable = ({ userId, assetId, files }: PropTypes) => {
  const { notify } = useNotifier()

  const [submitUpdate, { data, isError, isSuccess, isLoading }] = useAdminUpdateAssetFilesMutation()

  const { handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      files: files,
    },
  })

  const { fields, update } = useFieldArray({
    control,
    name: 'files',
    keyName: 'key',
  })

  const submitWrapper = (data: FormInputs) => {
    return submitUpdate({ assetId: assetId.toString(), body: data })
  }

  useEffect(() => {
    if (isSuccess) {
      notify(<NotificationToast message="Asset files have been updated" />)
    }
  }, [isSuccess])

  useEffect((() => {
    if (isError) {
      notify(<NotificationToast type="error" message="There was a problem updating the files for this asset, try again soon" />)
    }
  }), [isError])

  return (
    <>
      <div>
        <div className="overflow-y-auto rounded-sm border-y border-secondary-gray max-h-52">
          <AdminTable>
            <AdminTable.Head className="sticky top-0">
              <AdminTable.Heading className="lg:w-1/4">#</AdminTable.Heading>
              <AdminTable.Heading className="lg:w-1/4">File</AdminTable.Heading>
              <AdminTable.Heading className="lg:w-1/4">Show on Asset</AdminTable.Heading>
              <AdminTable.Heading className="lg:w-1/4">Open In New tab</AdminTable.Heading>
            </AdminTable.Head>
            <AdminTable.Body>
              {
                fields && fields.map((file, index) => (
                  <AdminTable.Row key={`asset-files-${file.id}`}>
                    <AdminTable.Column>{file.id}</AdminTable.Column>
                    <AdminTable.Column>
                      <a href={file.attachment.url} target="_blank" rel="noreferrer" className="url-text" title={file.name}>
                        {file.name} <ArrowTopRightIcon className="inline h-4 aspect-square ml-1" />
                      </a>
                    </AdminTable.Column>
                    <AdminTable.Column>
                      <input
                        type="checkbox"
                        defaultChecked={file.is_enabled}
                        checked={file.is_enabled}
                        className="mx-1 h-5 aspect-square"
                        onChange={(value) => update(index, { ...file, is_enabled: value.target.checked, new_tab: false })}
                      />
                    </AdminTable.Column>
                    <AdminTable.Column>
                      <input
                        type="checkbox"
                        defaultChecked={file.new_tab}
                        checked={file.new_tab}
                        className="mx-1 h-5 aspect-square"
                        disabled={file.is_enabled === false}
                        onChange={(value) => update(index, { ...file, new_tab: value.target.checked })}
                      />
                    </AdminTable.Column>
                  </AdminTable.Row>
                ))
              }
            </AdminTable.Body>
          </AdminTable>
        </div>
        <form onSubmit={handleSubmit(submitWrapper)} className="flex justify-end mt-2 gap-2">
          <Button type="button" variant="secondary" href={`/admin/dashboard/files/manage/${userId}`}>Manage</Button>
          <Button type="submit" isLoading={isLoading}>Update</Button>
        </form>
      </div>
    </>
  )
}

export default AssetFileTable