import { useGetConfigurationsQuery, useUpdateConfigurationsMutation } from 'api/endpoints/admin/configuration'
import ConfigurationForm from 'components/admin/forms/configuration'
import { useToast } from 'hooks/useToast'
import { useEffect } from 'react'

const ConfigurationsPage = () => {
  const { successToast } = useToast()
  
  const {
    data: configurationData,
    isLoading: isConfigurationLoading,
  } = useGetConfigurationsQuery(null)

  const [updateConfiguration, {
    data: updateResult,
    error: configurationUpdateErrors,
  }] = useUpdateConfigurationsMutation()

  const handleSubmit = async (inputs: any) => {
    await updateConfiguration({ ...inputs })
  }

  useEffect(() => {
    if (updateResult) {
      successToast({ message: 'Configuration updated successfully' })
    }
  }, [updateResult])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
        <div className="flex flex-col">
          <h1 className="text-2xl font-medium text-slate-800">
            Configurations
          </h1>
        </div>
      </div>
      {!isConfigurationLoading &&
        <ConfigurationForm
          onSubmit={handleSubmit}
          configurations={configurationData}
          onSubmitError={configurationUpdateErrors}
        />
      }
    </div>
  )
}

export default ConfigurationsPage