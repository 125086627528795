export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}

export function getFormattedRole(role: UserRole) {
  const enumerables = {
    SUPER_ADMIN: 'Super Admin',
    ADMIN: 'Admin',
    OWNER: 'Owner',
  }

  return enumerables?.[role]
}