import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import QueryTags from 'api/types/QueryTags'
import { SearchResult } from 'api/types/loqate/searchResult'


const loqateEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    findAddress: builder.query<SearchResult[], { search: string, containerId: string | null }>({
      query: ({ search, containerId }) => ({
        url: '/address/find',
        params: {
          ...(search && { search: search }),
          ...(containerId && { container_id: containerId }),
        },
      }),
    }),

    retrieveAddress: builder.query<void, { addressId?: string }>({
      query: ({ addressId }) => ({
        url: '/address/retrieve',
        params: {
          ...(addressId && { address_id: addressId }),
        },
      }),
    }),
  })

export default loqateEndpoints