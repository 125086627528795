import { User } from 'api/types/models/user'
import { useMemo } from 'react'
import Button from 'components/button'
import Input from 'components/form/input'
import FormLayout from '../layouts/FormLayout'
import Phone from 'components/form/phone'
import ComboBox from 'components/form/combobox'
import { Client } from 'api/types/models/client'
import { useGetClientsQuery } from 'api/endpoints/admin/client'
import FormError from 'components/error'
import { UserRole } from 'enums/UserRole'
import { Form } from 'components/form/form'
import { z } from 'zod'
import { FormBaseProps } from 'types/form'
import PostCodeLookup from 'components/form/postcode_lookup'

const userSchema = z.object({
  client_id: z.coerce.number().nullable(),
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  email: z.string().min(1, 'Email is required').email('Invalid email address'),
  country_code: z.string(),
  phone: z.string().min(1, 'Phone number is required'),
  street_address: z.string().nullable().optional(),
  second_line: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  county: z.string().nullable().optional(),
  postcode: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
})

export type UserFormInputs = z.infer<typeof userSchema>

type AdminUserFormProps = FormBaseProps<UserFormInputs> & {
  user?: User
  type: 'update' | 'create'
  btnTxt: string
  isLoading: boolean,
}

const AdminUserForm = ({
  user,
  type,
  onSubmit,
  btnTxt,
  errors,
  defaultValues,
  isLoading,
}: AdminUserFormProps) => {
  const {
    data: clients,
    isLoading: isClientsLoading,
  } = useGetClientsQuery({})

  const clientOptions = useMemo(() => {
    return (clients as Client[])?.map((client) => ({
      value: client.id,
      text: client.display_name,
    }))
  }, [clients])

  return (
    <FormLayout>
      <Form<UserFormInputs, typeof userSchema>
        error={errors}
        onSubmit={onSubmit}
        validationSchema={userSchema}
        defaultValues={defaultValues}
      >
        {({ register, getValues, watch, clearErrors, trigger, setValue, formState: { errors } }) => (
          <>
            {
              (user?.role === UserRole.OWNER || type === 'create') &&
              <FormLayout.Block>
                <FormLayout.Title
                  title="Client"
                  subtitle="Assign the client this user will belong to"
                />

                <FormLayout.Group
                  label="Client"
                  htmlFor="client"
                >
                  <ComboBox
                    isClearable
                    isLoading={isClientsLoading}
                    options={clientOptions}
                    placeholder="Search by display name"
                    onChange={(option) => {
                      if (option) {
                        setValue('client_id', option.value as number)
                        trigger('client_id')
                      } else {
                        setValue('client_id', null)
                        trigger('client_id')
                      }
                    }}
                    defaultValue={watch('client_id') as number}
                  />
                  {
                    errors.client_id?.message &&
                    <FormError text={errors?.client_id?.message} className="mt-1 mb-2 !text-left" />
                  }
                </FormLayout.Group>
              </FormLayout.Block>
            }

            <FormLayout.Block>
              <FormLayout.Title
                title="Basic Information"
                subtitle="User's basic contact information"
              />

              <FormLayout.Group
                label="First Name"
                htmlFor="first_name"
              >
                <Input {...register('first_name')} error={errors.first_name?.message} />
              </FormLayout.Group>

              <FormLayout.Group
                label="Last Name"
                htmlFor="lastName"
              >
                <Input {...register('last_name')} error={errors.last_name?.message} />
              </FormLayout.Group>

              <FormLayout.Group
                label="Email"
                htmlFor="email"
              >
                <Input {...register('email')} error={errors.email?.message} />
              </FormLayout.Group>

              <FormLayout.Group
                label="Phone"
                htmlFor="phone"
              >
                <Phone
                  defaultPhone={getValues('phone')}
                  defaultCountryCode={getValues('country_code')}
                  onChange={(phone) => {
                    setValue('phone', phone.phone)
                    setValue('country_code', phone.country_code)

                    if (errors.phone || errors.country_code) {
                      clearErrors(['country_code', 'phone'])
                    }
                  }}
                  error={errors?.phone?.message || errors?.country_code?.message}
                />
              </FormLayout.Group>
            </FormLayout.Block>

            {
              (user?.role === UserRole.OWNER || type === 'create') &&
              <FormLayout.Block>
                <FormLayout.Title
                  title="Address Information"
                  subtitle="User's current address information"
                />

                <FormLayout.Group
                  label="Address Lookup"
                  description="Find and retrieve the user's address"
                >
                  <PostCodeLookup
                    onSelect={(object) => {
                      setValue('postcode', object.PostalCode)
                      setValue('street_address', object.Line1)
                      setValue('second_line', object.Line2)
                      setValue('city', object.City)
                      setValue('county', object.Province)
                    }}
                  />
                </FormLayout.Group>

                <FormLayout.Group
                  label="Street Address"
                  htmlFor="street_address"
                >
                  <Input {...register('street_address')} error={errors.street_address?.message} />
                </FormLayout.Group>

                <FormLayout.Group
                  label="Second Line"
                  htmlFor="second_line"
                >
                  <Input {...register('second_line')} error={errors.second_line?.message} />
                </FormLayout.Group>

                <FormLayout.Group
                  label="City"
                  htmlFor="city"
                >
                  <Input {...register('city')} error={errors.city?.message} />
                </FormLayout.Group>

                <FormLayout.Group
                  label="County"
                  htmlFor="county"
                >
                  <Input {...register('county')} error={errors.county?.message} />
                </FormLayout.Group>

                <FormLayout.Group
                  label="Country"
                  htmlFor="country"
                >
                  <Input {...register('country')} error={errors.country?.message} />
                </FormLayout.Group>

                <FormLayout.Group
                  label="Postcode"
                  htmlFor="postcode"
                >
                  <Input {...register('postcode')} error={errors.postcode?.message} />
                </FormLayout.Group>
              </FormLayout.Block>
            }

            <FormLayout.Footer>
              <Button href="/admin/dashboard/users/browse" variant="secondary" className="hidden lg:block">
                Cancel
              </Button>
              <Button type="submit" isLoading={isLoading}>{btnTxt}</Button>
            </FormLayout.Footer>
          </>
        )}
      </Form>
    </FormLayout>
  )
}

export default AdminUserForm