import React from 'react'

type PhoneUrlProps = {
  phone: string
  className?: string
  includePlus?: boolean
}

const PhoneUrl = ({ phone, className, includePlus = true }: PhoneUrlProps) => {
  return (
    <a href={`tel:+${phone}`} className={`text-primary ${className}`}>{includePlus ? '+' : ''}{phone}</a>
  )
}

export default PhoneUrl