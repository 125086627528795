import { useEffect, useRef, useState } from 'react'

type UseDebounceOptions = {
  delay?: number
}

type UseDebounceParams<T = string> = {
  value: T
  onDebounced?: (debouncedValue: T) => void
  opts?: UseDebounceOptions
}

/**
* 
* This hook is used to debounce the passed in value. Each time the
* value changes, an effect is executed starting a timer. If the value
* changes before the threshold is reached, the timer is cleared, and
* a new one is started. Once the threshold has been hit, the timer
* callback is executed.
*
* @param T value - The value being debounced
* @param UseDebounceOptions opts - Options for the debounce
* @return T
*
*/
export function useDebounce<T>({ value, onDebounced, opts }: UseDebounceParams<T>): T {
  const callbackRef = useRef<(value: T) => void>()

  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if(onDebounced) {
      callbackRef.current = onDebounced
    }
  }, [onDebounced])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value)

      onDebounced?.(value)
    }, opts?.delay ?? 500)

    return () => clearTimeout(timeout)
  }, [value, opts])

  return debouncedValue
}
