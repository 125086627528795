import { cn } from 'helpers/styling'

type HorizontalDividerProps = {
  className?: string
}

export function HorizontalDivider({ className }: HorizontalDividerProps) {
  return (
    <div className={cn('h-[1px] w-full bg-gray-200', className)}></div>
  )
}