import { HorizontalDivider } from 'components/Dividers/horizontal'
import Button from 'components/button'
import { FormControl } from 'components/form/control'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { FormLabel } from 'components/form/label'
import PostCodeLookup from 'components/form/postcode_lookup'
import TextArea from 'components/form/textarea'
import { Title } from 'components/headings'
import { addressSchema } from 'helpers/validation/fields/address'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormBaseProps } from 'types/form'
import { z } from 'zod'

const assetLocationSchema = z.object({
  note: z.string().nullable(),
}).and(addressSchema)

export type AssetLocationFormInputs = z.infer<typeof assetLocationSchema>

type AssetLocationFormProps = FormBaseProps<AssetLocationFormInputs> & {
  onDirty: (isDirty: boolean) => void
  isSuccess?: boolean,
}

function FormWrapper({
  register,
  onDirty,
  setValue,
  formState: { errors, isDirty },
}: UseFormReturn<AssetLocationFormInputs> & { onDirty: (isDirty: boolean) => void }) {
  useEffect(() => {
    if (isDirty) {
      onDirty(isDirty)
    }
  }, [isDirty])

  return (
    <div className="flex flex-col gap-4">
      <Title
        size='lg'
        className="!text-left !m-0"
        tooltip='Adding a location to your KiCall can help you keep track of your devices.'
        tooltipId='location-form-tooltip'
      >
        Address
      </Title>
      <PostCodeLookup
        placeHolder="Start typing the asset address"
        onSelect={(object) => {
          setValue('line_1', object.Line1)
          setValue('line_2', object.Line2)
          setValue('town_or_city', object.City)
          setValue('county', object.Province)
          setValue('postcode', object.PostalCode)
        }}
      />

      <FormControl>
        <FormLabel>Line 1</FormLabel>
        <Input
          {...register('line_1')}
          placeholder="Enter first line"
          error={errors?.line_1?.message}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Line 2</FormLabel>
        <Input
          {...register('line_2')}
          placeholder="Enter second line"
          error={errors?.line_2?.message}
        />
      </FormControl>

      <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
        <FormControl>
          <FormLabel>Town or City</FormLabel>
          <Input
            {...register('town_or_city')}
            placeholder="Enter town or city"
            error={errors?.town_or_city?.message}
          />
        </FormControl>

        <FormControl>
          <FormLabel>County</FormLabel>
          <Input
            {...register('county')}
            placeholder="Enter county"
            error={errors?.county?.message}
          />
        </FormControl>
      </div>

      <FormControl>
        <FormLabel>Country</FormLabel>
        <Input
          {...register('country')}
          placeholder="Enter country"
          error={errors?.country?.message}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Postcode</FormLabel>
        <Input
          {...register('postcode')}
          placeholder="Enter postcode"
          error={errors?.postcode?.message}
        />
      </FormControl>

      <HorizontalDivider />


      <FormControl className='flex flex-col gap-2'>
        <Title
          className="!text-left !mb-0"
          size="lg"
          tooltip='Define further locational details'
          tooltipId='note-form-tooltip'
        >
          Notes
        </Title>
        <TextArea
          {...register('note')}
          error={errors?.note?.message}
        />
      </FormControl>
    </div>
  )
}

export function AssetLocationForm({
  onSubmit,
  onDirty,
  isLoading,
  errors,
  defaultValues,
  isSuccess,
}: AssetLocationFormProps) {
  return (
    <Form<AssetLocationFormInputs, typeof assetLocationSchema>
      error={errors}
      onSubmit={onSubmit}
      validationSchema={assetLocationSchema}
      defaultValues={defaultValues}
    >
      {(methods) => (
        <>
          <FormWrapper
            onDirty={onDirty}
            {...methods}
          />

          <Button
            type="submit"
            className="!mt-6"
            isLoading={isLoading}
            isSuccess={isSuccess}
            successText='Asset updated'
            block
          >
            Update Location
          </Button>
        </>
      )}
    </Form>
  )
}