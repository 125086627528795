import classNames from 'classnames'
import TooltipIcon from 'components/icons/TooltipIcon'
import ToolTip from 'components/tooltip'
import { CSSProperties, ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'

type AlignmentTypes = 'left' | 'center' | 'right'

type HeadingProps = {
  children: ReactNode
  align?: AlignmentTypes,
  size?: 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  className?: string
  tooltip?: string,
  tooltipId?: string,
  tooltipStyle?: CSSProperties,
}

const alignment = (align: AlignmentTypes) => {
  return classNames({
    'text-left': align === 'left',
    'text-center': align === 'center',
    'text-right': align === 'right',
  })
}

const Title = ({ children, align = 'center', size = 'xl', className = '', tooltip, tooltipId }: HeadingProps) => (
  tooltip ? (
    <div className="flex gap-1 items-center">
      <h1 className={`text-${size} font-semibold ${alignment(align)} ${className}`}>{children}</h1>
      { 
        tooltip && tooltipId &&
        <ToolTip id={tooltipId} content={tooltip}>
          <TooltipIcon className='mt-[2px] h-4' id={tooltipId}/>
        </ToolTip>
      }
    </div>
  ) : (
    <h1 className={`text-${size} font-semibold ${alignment(align)} ${className}`}>{children}</h1>
  )
)

const Heading = ({ children, align = 'center' }: HeadingProps) => (
  <h2 className={`text-lg font-semibold mt-2 mb-1 ${alignment(align)}`}>{children}</h2>
)

const Tm = () => (
  <span className='font-light'>™</span>
)

export {
  Title,
  Heading,
  Tm,
}