import { Dialog, Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import authImage from 'resources/logo.svg'
import { useAdminLogoutMutation } from 'api'
import { logout } from 'slices/auth'
import HamburgerIcon from 'components/icons/HamburgerIcon'
import CrossIcon from 'components/icons/CrossIcon'

const AdminDashboardPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [submitLogout] = useAdminLogoutMutation()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const navigation = [
    { name: 'Clients', href: '/admin/dashboard/clients/browse', root: '/admin/dashboard/clients' },
    { name: 'Users', href: '/admin/dashboard/users/browse', root: '/admin/dashboard/users' },
    { name: 'Assets', href: '/admin/dashboard/assets/browse', root: '/admin/dashboard/assets' },
    { name: 'Themes', href: '/admin/dashboard/themes/browse', root: '/admin/dashboard/themes' },
    { name: 'People', href: '/admin/dashboard/devices/browse', root: '/admin/dashboard/devices' },
    { name: 'Files', href: '/admin/dashboard/files/browse', root: '/admin/dashboard/files' },
    { name: 'Configurations', href: '/admin/dashboard/configuration', root: '/admin/dashboard/configuration' },
    { name: 'Credit Logs', href: '/admin/dashboard/credit-logs', root: '/admin/dashboard/credit-logs' },
    { name: 'Reports', href: '/admin/dashboard/reports', root: '/admin/dashboard/reports' },
  ]

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const triggerLogout = async () => {
    await submitLogout()
    dispatch(logout())
    navigate('/admin/login')
  }

  return (
    <div className="h-full">

      {/* Responsive NavBar for Mobile */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-opacity-75 bg-navbar-navy" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-navbar-navy">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <CrossIcon className="w-5 h-5 m-auto" />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>

                <img className="w-auto h-24" src={authImage} alt="KiCall"/>

                <div className="flex-1 h-0 overflow-y-auto">
                  <nav>
                    {
                      navigation.map((item) => (
                        <Link
                          onClick={() => setSidebarOpen(false)}
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname.startsWith(item.root) ? 'bg-navbar-navy-selected text-white' : 'text-secondary-gray hover:text-white hover:bg-navbar-navy-selected bg-navbar-navy-item',
                            'transition group flex items-center p-4 pl-8'
                          )}
                        >
                          {item.name}
                        </Link>
                      ))
                    }
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
            <div className="flex-shrink-0 w-14" aria-hidden="true" />
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-navbar-navy">
        <div className="flex flex-col flex-1 min-h-0">
          <img className="w-auto h-20 -mt-2" src={authImage} alt="KiCall" />
          <div className="flex flex-col flex-1 overflow-y-auto">
            <nav className="flex-1">
              {
                navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      location.pathname.startsWith(item.root) ? 'bg-navbar-navy-selected text-white' : 'text-secondary-gray hover:text-white hover:bg-navbar-navy-selected bg-navbar-navy-item',
                      'transition group flex items-center p-4 pl-8'
                    )}
                  >
                    {item.name}
                  </Link>
                ))
              }
            </nav>
          </div>
        </div>
      </div>

      {/* Sticky Ceiling */}
      <div className="flex flex-col h-full md:pl-64">
        <div className="sticky top-0 flex flex-shrink-0 py-5 bg-white shadow-sm">
          <button
            type="button"
            className="flex px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-dark-blue md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <HamburgerIcon className="w-8 h-8 m-auto" />
            <span className="sr-only">Open sidebar</span>
          </button>
          <div className="flex justify-end flex-1 px-6 sm:px-8">
            <Menu as="div" className="relative my-auto">
              <div>
                <Menu.Button className="flex items-center max-w-xs gap-2 pr-2 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-dark-blue">
                  <span className="sr-only">Open user menu</span>
                  <div className="w-6 h-6 rounded-full bg-secondary-gray" />
                  <p className="text-primary-gray">{user?.first_name}</p>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => triggerLogout()}
                        className={classNames('block px-4 py-2 text-primary-gray', {
                          'bg-gray-100': active,
                        })}>
                        Logout
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <main className="flex-1 h-full overflow-auto bg-slate-200">
          <div className="flex-1 p-6">
            <div className="mx-auto bg-white rounded-md">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default AdminDashboardPage