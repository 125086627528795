import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { RootState } from 'store'

const RegisterPageOutlet = () => {
  const location = useLocation()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  if (user?.last_verified_at === null && location.pathname !== '/register/step-two') {
    return <Navigate to="/register/step-two" />
  }

  if (location.pathname === '/register') {
    return <Navigate to="/register/step-one" />
  }

  return (
    <div className="flex flex-col h-full">
      <Outlet />
    </div>
  )
}

export default RegisterPageOutlet