import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { User } from '../../types/models/user'
import AdminLoginRequest from '../../types/requests/auth/admin/login'
import QueryTags from '../../types/QueryTags'

const userAuthEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    adminLogin: builder.mutation<{ user: User }, AdminLoginRequest>({
      query: (body) => ({
        url: '/auth/admin/login',
        method: 'post',
        body,
      }),
    }),

    adminLogout: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/admin/logout',
        method: 'post',
      }),
    }),

  })

export default userAuthEndpoints