import { cva } from 'class-variance-authority'
import { WeekDay } from 'enums/WeekDay'
import { cn } from 'helpers/styling'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

const dayStyling = cva(
  [
    'border rounded-md h-9 w-10 capitalize grid place-content-center outline-none transition-all select-none text-primary-gray border-secondary-gray',
    'hover:bg-secondary-gray hover:bg-opacity-20',
    'focus-visible:bg-secondary-gray focus-visible:bg-opacity-20',
  ],
  {
    variants: {
      isSelected: {
        true: 'border-primary bg-primary bg-opacity-5 text-primary hover:bg-primary hover:bg-opacity-5',
      },
    },
    defaultVariants: {
      isSelected: false,
    },
  })

type WeekDayProps = {
  name: string
  control: any
  className?: string
  disabled?: boolean
}

export function WeekDays({
  name,
  control,
  className,
  disabled = false,
}: WeekDayProps) {
  const {
    field: { value, onChange },
  } = useController({ name, control })

  const handleSelectedDay = (day: WeekDay) => {
    // If the day is already selected, remove it from the list, else add it
    let updatedSelection = value?.some((selectedDay: WeekDay) => selectedDay === day)
      ? value?.filter((selectedDay: WeekDay) => selectedDay !== day)
      : [...value, day]

    onChange(updatedSelection)
  }

  const daysOfWeek = useMemo(
    () =>
      Object.values(WeekDay).map(day => ({
        value: day,
        abbre: day.slice(0, 3).toLowerCase(),
      })),
    []
  )

  return (
    <div className="flex flex-wrap items-center gap-2">
      {
        daysOfWeek?.map(day => (
          <button
            disabled={disabled}
            key={day.value}
            type="button"
            aria-label={`Select ${day.value}`}
            onClick={() => handleSelectedDay(day.value)}
            className={cn([
              dayStyling({
                isSelected: value?.some((selectedDay: WeekDay) => selectedDay === day.value),
              }),
              className,
            ])}
          >

            <span className="text-xs font-medium">
              {day.abbre}
            </span>
          </button>
        ))
      }
    </div>
  )
}