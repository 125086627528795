const HamburgerIcon = ({ className }: { className?: string }) => {
  return (
    <svg viewBox="172.41 118.733 100 80" className={className}>
      <rect fill="currentColor" width="100" height="20" x="172.41" y="118.733" rx="10" ry="10"></rect>
      <rect fill="currentColor" y="148.733" width="100" height="20" x="172.41" rx="10" ry="10"></rect>
      <rect fill="currentColor" y="178.733" width="100" height="20" x="172.41" rx="10" ry="10"></rect>
    </svg>
  )
}

export default HamburgerIcon
