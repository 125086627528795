import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import QueryTags from '../../types/QueryTags'
import PaginatedResponse from '../../types/requests/PaginatedResponse'
import AdminThemeGetRequest from 'api/types/requests/admin/theme/get'
import AdminThemeStoreRequest from 'api/types/requests/admin/theme/store'
import Theme from 'api/types/models/theme'
import { serialize } from 'object-to-formdata'
import ThemeUpdateRequest from 'api/types/requests/admin/theme/update'
import { stringify } from 'qs'

const adminThemeEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  adminListThemes: builder.query<Theme[], { client_id?: number }>({
    query: (params) => ({
      url: '/admin/themes/list',
      params,
    }),
  }),

  adminGetThemes: builder.query<PaginatedResponse<Theme[]>, AdminThemeGetRequest>({
    query: ({ page, sort }) => ({
      url: '/admin/themes',
      params: {
        ...(page && { page }),
        ...(sort && { sort: stringify(sort) }),
      },
    }),
    providesTags: ['admin_themes'],
  }),

  adminGetTheme: builder.query<{ theme: Theme }, string>({
    query: (id) => ({
      url: `/admin/themes/${id}`,
    }),
  }),

  adminStoreTheme: builder.mutation<{ theme: Theme }, AdminThemeStoreRequest>({
    query: (body) => ({
      url: '/admin/themes',
      method: 'post',
      body: serialize(body),
    }),
  }),

  adminUpdateTheme: builder.mutation<{ theme: Theme }, { id: number, body: ThemeUpdateRequest }>({
    query: ({ body, id }) => ({
      url: `/admin/themes/${id}`,
      method: 'put',
      body: serialize(body),
    }),
    invalidatesTags: ['clients', 'client'],
  }),

  adminDeleteTheme: builder.mutation<{ theme: Theme }, { id: number }>({
    query: ({ id }) => ({
      url: `/admin/themes/${id}`,
      method: 'delete',
    }),
    invalidatesTags: ['admin_themes'],
  }),
})

export default adminThemeEndpoints