import { useState } from 'react'
import { useAdminGetDevicesQuery } from 'api'
import Button from 'components/button'
import AdminTable from 'components/admin/tables'
import { Link } from 'react-router-dom'
import { formatDate } from 'helpers/dates'
import Pagination from 'components/pagination'
import Device from 'api/types/models/device'
import AdminDeleteDeviceModal from 'components/modals/forms/device'
import PhoneUrl from 'components/phone_url'
import { useSort } from 'hooks/useSort'

const AdminDeviceBrowsePage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const { sorts, toggleSorts } = useSort({
    'name': undefined,
    'relationship': undefined,
    'created_at': undefined,
  }, () => setCurrentPage(1))

  const {
    data: devices,
    isLoading: isLoadingDevices,
  } = useAdminGetDevicesQuery({
    page: currentPage,
    sort: sorts,
  }, { refetchOnMountOrArgChange: true })

  const openDeviceDeleteModal = (device: Device) => {
    setSelectedDevice(device)
    setIsDeleteModalOpen(true)
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
        <h1 className="text-2xl font-medium text-slate-800">
          People
        </h1>
        <Button href="/admin/dashboard/devices/create" className="hidden lg:block">
          Create
        </Button>
      </div>
      <div className="p-3 overflow-x-scroll lg:p-6">
        <AdminTable isLoading={isLoadingDevices}>
          <AdminTable.Head>
            <AdminTable.Heading>#</AdminTable.Heading>
            <AdminTable.Heading>
              Assets
            </AdminTable.Heading>
            <AdminTable.Heading
              className="hidden lg:table-cell"
              sortable
              direction={sorts.name}
              onClick={() => toggleSorts('name')}
            >
              Name
            </AdminTable.Heading>
            <AdminTable.Heading>Phone</AdminTable.Heading>
            <AdminTable.Heading
              sortable
              direction={sorts.relationship}
              onClick={() => toggleSorts('relationship')}
            >
              Relationship
            </AdminTable.Heading>
            <AdminTable.Heading
              sortable
              direction={sorts.created_at}
              onClick={() => toggleSorts('created_at')}
            >
              Created
            </AdminTable.Heading>
            <AdminTable.Heading>Actions</AdminTable.Heading>
          </AdminTable.Head>
          <AdminTable.Body>
            {
              devices?.data?.map((device, idx) => (
                <AdminTable.Row key={idx}>
                  <AdminTable.Column>{device.id}</AdminTable.Column>
                  <AdminTable.Column>
                    {
                      device.assets ? device.assets.length > 0 && device.assets.map((asset, index) => {
                        const url = `/admin/dashboard/assets/manage/${asset.id}`
                        return (
                          <>
                            {index > 0 && ', '}
                            <Link key={`asset-url-${index}`} to={url} className="url-text">
                              {asset.id.toString()}
                            </Link>
                          </>
                        )
                      })
                        : '-'
                    }
                  </AdminTable.Column>
                  <AdminTable.Column className="hidden lg:table-cell">{device.name}</AdminTable.Column>
                  <AdminTable.Column>
                    <PhoneUrl phone={device.full_phone_number} />
                  </AdminTable.Column>
                  <AdminTable.Column>{device.relationship}</AdminTable.Column>
                  <AdminTable.Column>{formatDate(device.created_at)}</AdminTable.Column>
                  <AdminTable.Column>
                    <div className="flex text-sm divide-x">
                      <Link to={`/admin/dashboard/devices/manage/${device?.id}`} className="px-1 url-text">
                        Manage
                      </Link>
                      <button className="px-1 text-red-500" onClick={() => openDeviceDeleteModal(device)}>
                        Delete
                      </button>
                    </div>
                  </AdminTable.Column>
                </AdminTable.Row>
              ))
            }
          </AdminTable.Body>
        </AdminTable>
      </div>

      <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
        <Pagination
          onPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          totalPages={devices?.meta?.last_page ?? 1}
        />
      </div>

      <AdminDeleteDeviceModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        device={selectedDevice!}
      />
    </div>
  )
}

export default AdminDeviceBrowsePage