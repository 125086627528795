import { useAdminGetUserFilesQuery, useAdminGetThemeQuery, useAdminStoreThemeMutation, useAdminUpdateFilesMutation, useAdminUpdateThemeMutation } from 'api'
import FormHeader from 'components/admin/form_header'
import AdminFileForm from 'components/admin/forms/file'
import NotificationToast from 'components/notifications'
import Spinner from 'components/spinner'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useParams } from 'react-router-dom'

const AdminFileManagePage = () => {
  const { notify } = useNotifier()
  const params = useParams()

  const { data: getResult, refetch } = useAdminGetUserFilesQuery(params.id!, { refetchOnMountOrArgChange: true })
  const [submitUpdate, { data, error, isLoading }] = useAdminUpdateFilesMutation()

  useEffect(() => {
    if (data) {
      notify(<NotificationToast message="Files updated successfully" />)
      refetch()
    }
  }, [data])

  if (!getResult) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Manage User Files"
        description="Manage files available to this user"
        backBtnText="Back to Files"
        backBtnUrl="/admin/dashboard/files/browse"
      />
      <AdminFileForm
        user={getResult?.user}
        onSubmit={(data) => submitUpdate({ userId: params.id!, body: data })}
        onSubmitBtnText='Update'
        onSubmitError={error}
        onSubmitLoading={isLoading}
      />
    </div>
  )
}

export default AdminFileManagePage