import classNames from 'classnames'
import { ReactNode } from 'react'

type AlertProps = {
  message: string | ReactNode,
  type?: 'success' | 'info' | 'error' | 'warning',
  className?: string
}

const Alert = ({ message, type = 'success', className = '' }: AlertProps) => {

  const notificationClasses = classNames({
    'bg-notification-success-secondary border-notification-success-primary': type === 'success',
    'bg-notification-info-secondary border-notification-info-primary': type === 'info',
    'bg-notification-error-secondary border-notification-error-primary': type === 'error',
    'bg-notification-warning-secondary border-notification-warning-primary': type === 'warning',
  })

  return (
    <div className={classNames('border p-[17px] rounded', notificationClasses, className)}>
      <p className="text-notification-message text-sm leading-5">{message}</p>
    </div>
  )
}

export default Alert 
