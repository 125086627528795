import { useCreateClientMutation } from 'api/endpoints/admin/client'
import FormHeader from 'components/admin/form_header'
import { ClientForm, ClientInputs } from 'components/admin/forms/client'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import { useNavigate } from 'react-router-dom'

const AdminClientStorePage = () => {
  const navigate = useNavigate()
  
  const{ successToast } = useToast()

  const [createClient, { 
    error: clientErrors, 
    isLoading: creatingClient, 
  }] = useCreateClientMutation()

  const handleSubmit = async (inputs: ClientInputs) => {
    await createClient(inputs).unwrap()
      .then(() => {
        navigate('/admin/dashboard/clients/browse', { replace: true })
        successToast({ message: 'Client created successfully' })
      })
      .catch(() => { /* Handled directly by the form */ })
  }

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Create Client"
        description="Fill in the fields below to create a new Client"
        backBtnText="Back to Clients"
        backBtnUrl="/admin/dashboard/clients/browse"
      />
      <ClientForm onSubmit={handleSubmit} errors={clientErrors}>
        <FormLayout.Footer>
          <Button href="/admin/dashboard/clients/browse" variant="secondary">
            Cancel
          </Button>

          <Button type="submit" isLoading={creatingClient}>
            Create Client
          </Button>
        </FormLayout.Footer>
      </ClientForm>
    </div>
  )
}

export default AdminClientStorePage