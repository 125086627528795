import { Tab } from '@headlessui/react'
import { cn } from 'helpers/styling'
import { StandardTab } from './standard-tab'
import { TabPanel } from './panel'

export type TabItem = {
  id: string
  value: string
  displayName: string
}

type TabProps = {
  className?: string
  value: TabItem[]
  onChange?: (tab: number) => void
  selectedTabIdx?: number
  renderTab?: (tab: TabItem) => JSX.Element
}

export function Tabs({
  value,
  renderTab,
  onChange,
  selectedTabIdx,
  className,
}: TabProps) {
  return (
    <div className={cn('w-full', className)}>
      <Tab.Group
        defaultIndex={0}
        selectedIndex={selectedTabIdx}
        onChange={(tabIdx) => {
          if (onChange) {
            onChange(tabIdx)
          }
        }}
      >
        <Tab.List className="flex gap-2 p-1 bg-gray-100 rounded-lg overflow-auto">
          {value?.map((tab, idx) => {
            if (renderTab) {
              return renderTab(tab)
            }

            return (
              <Tabs.StandardTab isSelected={selectedTabIdx === idx} key={tab.id}>
                {tab.displayName}
              </Tabs.StandardTab>)
          })}
        </Tab.List>
      </Tab.Group>
    </div>

  )
}

Tabs.StandardTab = StandardTab
Tabs.Panel = TabPanel