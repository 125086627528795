import { Tab } from '@headlessui/react'
import { cva } from 'class-variance-authority'
import { cn } from 'helpers/styling'
import { PropsWithChildren } from 'react'

const tabStyling = cva(
  [
    'w-full rounded-md py-[6px] md:py-2 px-2 md:px-4 transition-all outline-none text-sm md:text-base text-gray-500',
    'hover:bg-gray-200/50',
    'focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-primary-gray',
  ],
  {
    variants: {
      isSelected: {
        true: 'bg-primary-gray text-white hover:bg-primary-gray hover:text-white',
      },
    },
    defaultVariants: {
      isSelected: false,
    },
  }
)

type StandardTabProps = PropsWithChildren<{
  isSelected: boolean,
  className?: string
}>

export function StandardTab({ isSelected, className, children }: StandardTabProps) {
  return (
    <Tab className={cn(tabStyling({ isSelected }), className)}>
      {children}
    </Tab>
  )
}