import { CreditBlockInputs } from 'components/admin/forms/credit-block'
import Button from 'components/button'
import { FormatDateTime } from 'components/format-datetime'
import Modal from 'components/modals'
import { DateTime } from 'luxon'
import { ModalBaseProps } from 'types/modal'

type ConfirmCreditBlockModalProps = ModalBaseProps & {
  onConfirm: () => void
  creditBlock: Omit<CreditBlockInputs, 'client_id'>
}

export function ConfirmCreditBlockModal({ 
  isOpen, 
  creditBlock, 
  onConfirm,
  onClose, 
}: ConfirmCreditBlockModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Confirm Credit Block"/>

      <Modal.Content>
        <p>Please confirm the details for this credit top-up is correct:</p>
        <br />
        <p><strong>Credits:</strong> {creditBlock?.amount}</p>
        <p><strong>Purchase Date:</strong> <FormatDateTime value={creditBlock?.purchase_date} format={DateTime.DATE_MED} /></p>
        <br />
        <p>If the above details are correct, then please continue. <span className="font-medium text-red-600">Once this action has been performed, it cannot be undone or edited at a later date.</span></p>
      </Modal.Content>

      <Modal.Footer className="flex justify-between gap-4">
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
}