import { useEffect, useMemo } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import Modal from 'components/modals'
import { useAdminDeleteDeviceMutation } from 'api'
import Button from 'components/button'
import NotificationToast from 'components/notifications'
import Device from 'api/types/models/device'

type FormInputs = {
  id: number
}

type ModalProps = {
  device: Device | undefined
  isOpen: boolean
  onClose: () => void
}

const AdminDeleteDeviceModal = ({ device, isOpen, onClose }: ModalProps) => {
  const dispatch = useDispatch()
  const { notify } = useNotifier()

  const [deleteDeviceMutation, { isSuccess: isDeviceDeleted, error: isError, isLoading }] = useAdminDeleteDeviceMutation()
  const { handleSubmit, setValue, reset } = useForm<FormInputs>()

  const onSubmit = () => {
    if (device) {
      deleteDeviceMutation({id: device.id})
    }
  }

  useMemo(() => {
    if (device) {
      setValue('id', device.id)
    }
  }, [device])

  useEffect(() => {
    if (isDeviceDeleted && device) {
      notify(<NotificationToast message="Person deleted successfully" />)
      onClose()
    }
  }, [dispatch, isDeviceDeleted])

  useEffect(() => {
    if(isError) {
      notify(<NotificationToast message="There was an issue deleting this person, please try again" type="warning" />)
    }
  }, [isError])

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding beforeEnter={reset}>
      <Modal.Header title="Delete Person" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <p>Are you sure you want to delete this person?</p>
          <p>({device?.name}, {device?.relationship})</p>
        </Modal.Content>
        <Modal.Footer className="flex flex-row justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button variant="danger" type="submit" isLoading={isLoading}>Delete</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AdminDeleteDeviceModal