import Spinner from 'components/spinner'
import { useToast } from 'hooks/useToast'
import { useParams } from 'react-router-dom'
import FormHeader from 'components/admin/form_header'
import AdminDeviceForm from 'components/admin/forms/device'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useAdminGetDeviceQuery, useAdminUpdateDeviceMutation } from 'api'

const AdminDeviceManagePage = () => {
  const { id } = useParams()

  const { successToast, errorToast } = useToast()

  const {
    data: device,
    isLoading: isLoadingDevice,
  } = useAdminGetDeviceQuery(id!)

  const [updateDevice, {
    error: updateError,
    isLoading: updateLoading,
  }] = useAdminUpdateDeviceMutation()

  const handleSubmit = async (data: any) => {
    await updateDevice({
      id: device?.device.id!,
      ...data,
    }).unwrap()
      .then(() => successToast({
        title: 'Person Updated',
        message: 'Person has been updated successfully',
      }))
      .catch((err) => {
        if (err?.status === 422 || err?.data?.errors) return

        errorToast({
          title: 'Person Update Failed',
          message: 'Unable to update the people, please try again later.',
        })
      })
  }

  if (isLoadingDevice || !device) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  const fetchError = (updateError as FetchBaseQueryError)

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Manage People"
        description="Manage Person information"
        backBtnText="Back to People"
        backBtnUrl="/admin/dashboard/devices/browse"
      />

      <AdminDeviceForm
        device={device?.device}
        onSubmit={handleSubmit}
        onSubmitError={(fetchError?.status === 422 || (fetchError?.data as { errors: [] })?.errors) ? updateError : undefined}
        onSubmitBtnText="Update"
        onSubmitLoading={updateLoading}
      />
    </div>
  )
}

export default AdminDeviceManagePage