import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useMfaVerifyAssetOwnerMutation } from 'api'
import MfaForm from 'components/forms/mfa'
import { login } from 'slices/auth'
import { RootState } from 'store'
import { RegisterState } from 'types/registerState'
import MfaRequestContexts from 'enums/MfaRequestContexts'

const RegisterStepThree = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useLocation().state as RegisterState

  if (state === null){
    return <Navigate to={'/register/step-one'} state={{acceptedTerms: false}} />
  }
  
  const [mfaVerification, { data: mfaResult, error: mfaError, isLoading: isMfaLoading }] = useMfaVerifyAssetOwnerMutation()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  useEffect(() => {
    if (mfaResult) {
      dispatch(login(mfaResult.user))
      if (state.asset) {
        navigate('/asset/register')
      } else {
        navigate('/')
      }
    }
  }, [dispatch, mfaResult])

  if (!user) {
    navigate('/register')
  }

  return (
    <div className="h-full max-w-lg mx-auto">
      <MfaForm 
        countryCode={user?.country_code}
        phone={user?.phone} 
        context={MfaRequestContexts.ASSET_OWNER_AUTH}
        onSubmit={(data) => mfaVerification(data)} 
        onSubmitError={mfaError}
        isLoading={isMfaLoading} />
    </div>
  )
}

export default RegisterStepThree