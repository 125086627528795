import { PropsWithChildren, ReactNode } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useController } from 'react-hook-form'

type RadioCardGroupProps = PropsWithChildren<{
  name: string
  control: any
}>

export function RadioCardGroup({ name, control, children }: RadioCardGroupProps) {
  const { field: { value, onChange }} = useController({ name, control })
  
  return (
    <RadioGroup
      value={value} 
      onChange={onChange}
      className="w-full"
    >
      <div className="flex w-full bg-white group">
        {children}
      </div>
    </RadioGroup>
  )
}