import { cn } from 'helpers/styling'
import { PropsWithChildren } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import ToolTip from 'components/tooltip'

const badgeStyling = cva('rounded-full select-none grid place-content-center whitespace-nowrap w-min font-normal', {
  variants: {
    intent: {
      default: 'bg-gray-100 text-gray-700',
      primary: 'bg-primary-light text-primary',
      danger: 'bg-red-100 text-red-700',
      warning: 'bg-yellow-100 text-yellow-700',
      success: 'bg-green-100 text-green-700',
      info: 'bg-blue-100 text-blue-700',
    },
    size: {
      sm: 'text-xs px-2 py-[1px]',
      md: 'text-sm px-3',
      lg: 'text-base px-4',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
})

interface BadgeBaseProps extends VariantProps<typeof badgeStyling> {}

type BadgeProps = BadgeBaseProps & PropsWithChildren<{
  className?: string;
  tooltipId?: string
  tooltipContent?: string
}>

export function Badge({
  intent,
  size,
  className,
  children,
  tooltipId = undefined,
  tooltipContent = undefined,
}: BadgeProps) {

  if (tooltipId !== undefined && tooltipContent !== undefined) {
    return (
      <ToolTip id={tooltipId} content={tooltipContent}>
        <div className={cn(badgeStyling({ intent, size }), className)}>
          {children}
        </div>
      </ToolTip>
    )
  }

  return (
    <div className={cn(badgeStyling({ intent, size }), className)}>
      {children}
    </div>
  )
}