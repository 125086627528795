import { useState } from 'react'
import Button from 'components/button'
import Pagination from 'components/pagination'
import { ClientTable } from 'components/tables/client/client'
import { Client } from 'api/types/models/client'
import { useGetClientsQuery } from 'api/endpoints/admin/client'
import AdminDeleteClientModal from 'components/modals/forms/client'
import PaginatedResponse from 'api/types/requests/PaginatedResponse'

const AdminClientBrowsePage = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  
  const [currentPage, setCurrentPage] = useState(1)

  const { data, isLoading } = useGetClientsQuery({ page: currentPage ?? 1 })

  const handleDelete = (selectedClient: Client) => {
    setSelectedClient(selectedClient)
    setIsDeleteModalOpen(true)
  }

  const paginatedClients = data as PaginatedResponse<Client[]>

  return (
    <>
      <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
        <h1 className="text-2xl font-medium text-slate-800">
          Clients
        </h1>
        <Button href="/admin/dashboard/clients/create" className="hidden lg:block">
          Create
        </Button>
      </div>

      <div className="p-3 overflow-x-scroll lg:p-6">
        <ClientTable 
          isLoading={isLoading}
          onDelete={handleDelete}
          value={paginatedClients?.data}
        />
      </div>

      <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
        <Pagination 
          onPage={paginatedClients?.meta?.current_page ?? 1} 
          onPageChange={(page) => setCurrentPage(page)} 
          totalPages={paginatedClients?.meta?.last_page ?? 1 } 
        />
      </div>

      <AdminDeleteClientModal 
        client={selectedClient}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  )
}

export default AdminClientBrowsePage