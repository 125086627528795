import { useAdminGetThemesQuery } from 'api'
import Theme from 'api/types/models/theme'
import AdminTable from 'components/admin/tables'
import Button from 'components/button'
import DeleteThemeModal from 'components/modals/forms/theme/DeleteThemeModal'
import Pagination from 'components/pagination'
import { useSort } from 'hooks/useSort'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const AdminThemeBrowsePage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedTheme, setSelectedTheme] = useState<Theme|undefined>(undefined)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const { sorts, toggleSorts } = useSort({
    'title': undefined,
    'asset_count': undefined,
  })

  const { data, isLoading } = useAdminGetThemesQuery({ page: currentPage, sort: sorts }, { refetchOnMountOrArgChange: true })

  const openUserDeleteModal = (theme: Theme): void => {
    setSelectedTheme(theme)
    setIsDeleteModalOpen(true)
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
        <h1 className="text-2xl font-medium text-slate-800">
          Themes
        </h1>
        <Button href="/admin/dashboard/themes/create" className="hidden lg:block">
          Create
        </Button>
      </div>
      <div className="p-3 overflow-x-scroll lg:p-6">
        <AdminTable isLoading={isLoading}>
          <AdminTable.Head>
            <AdminTable.Heading>#</AdminTable.Heading>
            <AdminTable.Heading
              sortable
              direction={sorts.title}
              onClick={() => toggleSorts('title')}
            >
              Title
            </AdminTable.Heading>
            <AdminTable.Heading>Description</AdminTable.Heading>
            <AdminTable.Heading
              sortable
              direction={sorts.asset_count}
              onClick={() => toggleSorts('asset_count')}
            >
              Assets
            </AdminTable.Heading>
            <AdminTable.Heading>Background URL</AdminTable.Heading>
            <AdminTable.Heading>Logo URL</AdminTable.Heading>
            <AdminTable.Heading>Action</AdminTable.Heading>
          </AdminTable.Head>
          <AdminTable.Body>
            {
              data?.data && data?.data.map((theme) => (
                <AdminTable.Row key={`theme-browse-${theme.id}`}>
                  <AdminTable.Column>{theme.id}</AdminTable.Column>
                  <AdminTable.Column>{theme.title}</AdminTable.Column>
                  <AdminTable.Column className="pr-4 whitespace-normal">{theme.description}</AdminTable.Column>
                  <AdminTable.Column>{theme.asset_count}</AdminTable.Column>
                  <AdminTable.Column className="text-xs break-all">
                    <a href={theme.background.url ?? '#'} target="_blank" rel="noreferrer" className="url-text">
                      {theme.background.url ?? '-'}
                    </a>
                  </AdminTable.Column>
                  <AdminTable.Column className="text-xs break-all">
                    <a href={theme.logo.url ?? '#'} target="_blank" rel="noreferrer" className="url-text">
                      {theme.logo.url ?? '-'}
                    </a>
                  </AdminTable.Column>
                  <AdminTable.Column>
                    <div className="flex mx-1 text-sm divide-x">
                      <Link to={`/admin/dashboard/themes/manage/${theme.id}`} className="px-1 url-text">
                        Manage
                      </Link>
                      <button className="px-1 text-red-500" onClick={() => openUserDeleteModal(theme)}>
                        Delete
                      </button>
                    </div>
                  </AdminTable.Column>
                </AdminTable.Row>
              ))
            }
          </AdminTable.Body>
        </AdminTable>
      </div>

      <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
        <Pagination onPage={currentPage} onPageChange={(page) => setCurrentPage(page)} totalPages={data?.meta.last_page ?? 0} />
      </div>

      <DeleteThemeModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        theme={selectedTheme!}
      />
    </div>
  )
}

export default AdminThemeBrowsePage