import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import FormHeader from 'components/admin/form_header'
import { useInviteMutation } from 'api/endpoints/invite'
import { InviteForm, InviteFormInputs } from 'feature/users/components/forms/invite'
import { UserRole } from 'enums/UserRole'
import { useNavigate } from 'react-router-dom'

export function AdminInvitePage() {
  const navigate = useNavigate()

  const { successToast, errorToast } = useToast()

  const [invite, { 
    error: inviteErrors,
    isLoading: isInviting,
  }] = useInviteMutation()

  const handleSubmit = async (inputs: InviteFormInputs) => {
    await invite({
      email: inputs.email,
    })
      .unwrap()
      .then(() => {
        navigate('/admin/dashboard/users/browse')
        successToast({
          title: 'Invite Sent',
          message: 'An invite link has been sent to this admin to complete their account.',
        })
      })
      .catch((err) => {
        if(err?.status === 422 || err?.data?.message) return

        errorToast({
          title: 'Failed to Invite',
          message: 'We\'re unable to invite the admin at this time. Please try again later.',
        })
      })
  }

  return (
    <div>
      <FormHeader
        title="Invite Admin"
        description="Complete the form below to invite a new admin to the platform"
        backBtnText="Back to Users"
        backBtnUrl="/admin/dashboard/users/browse"
      />

      <InviteForm
        onSubmit={handleSubmit}
        errors={inviteErrors}
        defaultValues={{
          email: '',
        }}
      >
        <div className="flex justify-end !mt-16">
          <Button type="submit" isLoading={isInviting}>
            Invite
          </Button>
        </div>
      </InviteForm>
    </div>
  )
}