const UploadIcon = ({ className }: { className?: string }) => {
  return (
    <svg viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask id="mask0_27_8" maskUnits="userSpaceOnUse" width="14" height="14">
        <path d="M7.01367 0.875C6.89199 0.875 6.77031 0.921484 6.67871 1.01309L4.54727 3.14453C4.36406 3.3291 4.36406 3.62852 4.54863 3.81309C4.73184 3.99766 5.03125 3.99766 5.21582 3.81309L6.54062 2.48828V10.3879C6.54062 10.649 6.75254 10.8609 7.01367 10.8609C7.2748 10.8609 7.48672 10.649 7.48672 10.3879V2.48828L8.81152 3.81309C8.90039 3.90195 9.01934 3.95117 9.14512 3.95117C9.2709 3.95117 9.38984 3.90195 9.47871 3.81309C9.66328 3.62852 9.66328 3.3291 9.47871 3.14453L7.34863 1.01309C7.25566 0.921484 7.13535 0.875 7.01367 0.875ZM12.1789 9.22852C12.1789 8.96739 12.3908 8.75547 12.652 8.75547C12.9131 8.75547 13.125 8.96739 13.125 9.22715V12.2746C13.125 12.7422 12.7449 13.1236 12.276 13.1236H1.72402C1.25645 13.1236 0.875 12.7436 0.875 12.2746V9.22715C0.875 8.96739 1.08555 8.75411 1.34668 8.75411C1.60781 8.75411 1.81973 8.96602 1.81973 9.22715V11.9C1.81973 12.0559 1.94687 12.1816 2.10137 12.1816H11.8973C12.0531 12.1816 12.1789 12.0545 12.1789 11.9V9.22852Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_27_8)">
        <rect width="14" height="14" />
      </g>
    </svg>
  )
}

export default UploadIcon