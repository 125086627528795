import Asset from 'api/types/models/asset'
import AssetListCard from 'components/cards/asset/list'

type Props = {
  assets?: Asset[]
}

const AssetBrowseList = ({ assets }: Props) => {
  return (
    <div className="flex flex-col overflow-y-auto">
      {
        assets?.map((asset, index) => {
          return (
            <div key={index}>
              <AssetListCard
                asset={asset}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default AssetBrowseList