import dataApi from 'api'
import { MfaRequest } from 'api/types/models/mfa_request'

const requestAccessBaseUrl = '/admin/request-access'

const requestAccessEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    adminRequestAccess: build.mutation<MfaRequest, { user_id: number }>({
      query: (body) => ({
        url: `${requestAccessBaseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['admin_access_request'],
    }),

    adminGetAccessRequests: build.query<MfaRequest[], { user_id?: number }>({
      query: (params) => ({
        url: `${requestAccessBaseUrl}`,
        params,
      }),
      providesTags: ['admin_access_request'],
    }),

    adminVerifyRequest: build.mutation<void, { id: number, code: string }>({
      query: ({ id, ...body }) => ({
        url: `${requestAccessBaseUrl}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['admin_access_request'],
    }),
  }),
})

export const {
  useAdminRequestAccessMutation,
  useAdminGetAccessRequestsQuery,
  useAdminVerifyRequestMutation,
} = requestAccessEndpoints