import { useCreateCreditBlockMutation } from 'api/endpoints/admin/credit-block'
import FormHeader from 'components/admin/form_header'
import { CreditBlockForm, CreditBlockInputs } from 'components/admin/forms/credit-block'
import { useToast } from 'hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { ConfirmCreditBlockModal } from 'components/modals/forms/credit_block/ConfirmCreditBlockModal'

export function AdminCreditBlockCreatePage() {
  const navigate = useNavigate()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [confirmationInputs, setConfirmationInputs] = useState<CreditBlockInputs | null>(null)

  const { successToast } = useToast()
  
  const { clientId } = useParams()

  const [createCreditBlock, {
    error: creditBlockCreateErrors,
  }] = useCreateCreditBlockMutation()

  const handleConfirmation = (inputs: CreditBlockInputs) => {    
    setConfirmationInputs(inputs)
    setIsConfirmationOpen(true)
  }

  const handleSubmit = async (inputs: CreditBlockInputs) => {
    await createCreditBlock({
      ...inputs,
      client_id: parseInt(clientId!),
    })
      .unwrap()
      .then(() => {
        navigate(`/admin/dashboard/clients/manage/${clientId}`, { replace: true })
        successToast({ message: 'Credit block created successfully'})
      })
      .catch(() => { 
        setConfirmationInputs(null)
        setIsConfirmationOpen(false)
      })
  }

  return (
    <>
      <FormHeader
        title="Create Credit Block"
        description="Allocate a block of credits that go towards a client's account SMS usage"
        backBtnText="Back to Client"
        backBtnUrl={`/admin/dashboard/clients/manage/${clientId}`}
      />

      <CreditBlockForm
        onSubmit={handleConfirmation}
        cancelHref={`/admin/dashboard/clients/manage/${clientId}`}
        errors={creditBlockCreateErrors}
        defaultValues={{
          amount: undefined,
          purchase_date: undefined,
        }}
      />

      <ConfirmCreditBlockModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={() => handleSubmit(confirmationInputs!)}
        creditBlock={confirmationInputs!}
      />
    </>
  )
}