import Report from 'api/types/response/report'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import MapIcon from 'components/icons/MapIcon'
import PencilIcon from 'components/icons/PencilIcon'
import PhoneIcon from 'components/icons/PhoneIcon'
import QrIcon from 'components/icons/QrIcon'
import { StatCard } from 'components/stats/card'
import { useState } from 'react'
import AssetMap from './map'
import LockIcon from 'components/icons/LockIcon'

type AdminReportTableProps = {
  isFiltering: boolean,
  reportData?: Report
}

const AdminReportTable = ({
  isFiltering = false,
  reportData,
}: AdminReportTableProps) => {

  const [isShowingMap, setIsShowingMap] = useState<boolean>(false)

  return (
    <FormLayout>
      {
        !isFiltering &&
        <FormLayout.Block>
          <FormLayout.Title title='Clients' subtitle='Site-wide statistics related to clients' />
          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            <StatCard>
              <StatCard.Title>Total Clients</StatCard.Title>
              <StatCard.Representation type="number" currentValue={reportData?.clients.length ?? undefined} />
            </StatCard>
          </div>
        </FormLayout.Block>
      }

      <FormLayout.Block>
        <div className="flex justify-between">
          <FormLayout.Title
            title='Assets'
            subtitle='Statistical information associated to assets'
          />
          <Button
            variant='secondary'
            icon={<MapIcon className='w-5 h-5' />}
            onClick={ () => setIsShowingMap(!isShowingMap) }
          >
            {isShowingMap ? 'Hide Map' : 'Show Map'}
          </Button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          <StatCard>
            <StatCard.Title>Total Assets</StatCard.Title>
            <StatCard.Representation type="number" currentValue={reportData?.assets.total ?? undefined} />
          </StatCard>

          <StatCard>
            <StatCard.Title>Total Registered Assets</StatCard.Title>
            <StatCard.Representation type="number" currentValue={reportData?.assets.total_registered ?? undefined} />
          </StatCard>

          <StatCard>
            <StatCard.Title>Total Private Assets</StatCard.Title>
            <StatCard.Representation type="number" currentValue={reportData?.assets.total_private ?? undefined} />
          </StatCard>

          <StatCard>
            <StatCard.Title>Total Public Assets</StatCard.Title>
            <StatCard.Representation type="number" currentValue={reportData?.assets.total_public ?? undefined} />
          </StatCard>
        </div>

        { isShowingMap && <AssetMap /> }
      </FormLayout.Block>

      <FormLayout.Block>
        <FormLayout.Title
          title='Asset management'
          subtitle='Statistics related to scanning assets, editing assets, etc.'
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
          <StatCard>
            <StatCard.Title>
              <div className="flex gap-4 justify-between items-center">
                <span>Scans per Asset</span>
                <QrIcon className='h-5' />
              </div>
            </StatCard.Title>
            <StatCard.Average average={reportData?.assets.scans.average ?? undefined} min={reportData?.assets.scans.min ?? undefined} max={reportData?.assets.scans.max ?? undefined} />
          </StatCard>

          <StatCard>
            <StatCard.Title>
              <div className="flex gap-4 justify-between items-center">
                <span>Edits per Asset</span>
                <PencilIcon className='h-5' />
              </div>
            </StatCard.Title>
            <StatCard.Average average={reportData?.assets.edits.average ?? undefined} min={reportData?.assets.edits.min ?? undefined} max={reportData?.assets.edits.max ?? undefined} />
          </StatCard>

          <StatCard>
            <StatCard.Title>
              <div className="flex gap-4 justify-between items-center">
                <span>Distribution Usage</span>
                <LockIcon className='h-5' />
              </div>
            </StatCard.Title>
            <StatCard.LabelGroup>
              <StatCard.Labelled label="Unknown" value={reportData?.assets?.total_unknown_usage} />
              <StatCard.Labelled label="Information" value={reportData?.assets?.total_information_usage} />
              <StatCard.Labelled label="Code" value={reportData?.assets?.total_code_usage} />
            </StatCard.LabelGroup>
          </StatCard>
        </div>
      </FormLayout.Block>

      <FormLayout.Block>
        <FormLayout.Title
          title='SMS Usage'
          subtitle='Statistics related to SMS usage.'
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
          <StatCard>
            <StatCard.Title>
              <div className="flex gap-4 justify-between items-center">
                <span>SMS messages sent</span>
                <PhoneIcon className='h-5' />
              </div>
            </StatCard.Title>
            <StatCard.Representation type="number" currentValue={reportData?.credit_usage.total ?? undefined} />
          </StatCard>
        </div>
      </FormLayout.Block>

    </FormLayout>
  )
}

export default AdminReportTable