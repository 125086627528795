import { useExportClientMutation } from 'api/endpoints/admin/client'
import { Client } from 'api/types/models/client'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import NotificationToast from 'components/notifications'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'

type ClientExportProps = {
  client: Client | undefined
}

export function ClientExport({ client }: ClientExportProps) {
  const { notify } = useNotifier()

  const [exportClient, {
    data: clientExportData,
    isLoading: exportingClient,
    error: clientExportErrors,
  }] = useExportClientMutation()

  const handleSubmit = async () => {
    if (!client){
      notify(<NotificationToast type='error' message="There was an error retrieving the client, please try again later." />)
      return
    }
    
    await exportClient(client)
  }

  useEffect(() => {
    if (clientExportData && !clientExportErrors) {
      notify(<NotificationToast type='success' message="Client successfully exported" />)
    }
  }, [clientExportData])

  useEffect(() => {
    if (clientExportErrors) {
      notify(<NotificationToast type='error' message="There was an error exporting this client's assets. Please try again later" />)
    }
  }, [clientExportErrors])


  return (
    <FormLayout>
      <FormLayout.Block>
        <FormLayout.Title
          title="Export Information"
          subtitle="Download data related to this client"
        />
        <Button
          onClick={handleSubmit}
          isLoading={exportingClient}
        >
          Export Assets
        </Button>
      </FormLayout.Block>
    </FormLayout>
  )
}