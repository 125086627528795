import { VariantProps, cva } from 'class-variance-authority'
import { cn } from 'helpers/styling'
import { PropsWithChildren } from 'react'

const bannerStyling = cva('rounded-md border', {
  variants: {
    intent: {
      primary: 'text-primary bg-primary-light border-primary',
      success: 'text-green-700 bg-green-100 border-green-700',
      error: 'text-red-700 bg-red-100 border-red-700',
      warning: 'text-yellow-700 bg-yellow-100 border-yellow-700',
    },
    size: {
      sm: 'p-2',
      md: 'p-3',
      lg: 'p-4',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
})

interface BannerBaseProps extends VariantProps<typeof bannerStyling> {
  className?: string
}

type BannerProps = PropsWithChildren<BannerBaseProps>

export function Banner({ 
  size, 
  intent, 
  className,
  children, 
}: BannerProps) {
  return (
    <div className={cn(bannerStyling({ intent, size }), className)}>
      {children}
    </div>
  )
}