import { Form } from 'components/form/form'
import FormLayout from '../layouts/FormLayout'
import { Contact } from 'api/types/models/contact'
import Input from 'components/form/input'
import { z } from 'zod'
import { DeepPartial, FieldValues } from 'react-hook-form'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import Phone from 'components/form/phone'
import { ClientFields } from 'api/types/models/client'

export type ClientInputs = ClientFields & Omit<Contact, 'client_id'>

const clientFormSchema = z.object({
  display_name: z.string().min(1, { message: 'Display name is required' }),

  first_name: z.string().min(1, { message: 'First name is required' }),
  last_name: z.string().min(1, { message: 'Last name is required' }),
  email: z.string().email({ message: 'Email is required' }),
  phone: z.string().min(1, { message: 'Phone is required' }),
  country_code: z.string().min(1, { message: 'Country code is required' }),

  line_1: z.string().min(1, { message: 'Street address is required' }),
  line_2: z.string().optional().nullable(),
  town_or_city: z.string().min(1, { message: 'City or town is required' }),
  country: z.string().min(1, { message: 'Country is required' }),
  postcode: z.string().min(1, { message: 'Postcode is required' }),
})

type ClientFormProps<TFormFields extends FieldValues> = {
  onSubmit: (data: TFormFields) => void
  errors?: FetchBaseQueryError | SerializedError,
  defaultValues?: DeepPartial<ClientInputs>
  children?: ReactNode
}

export function ClientForm({
  onSubmit,
  errors,
  defaultValues,
  children,
}: ClientFormProps<ClientInputs>) {
  return (
    <FormLayout>
      <Form<ClientInputs, typeof clientFormSchema>
        error={errors}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        validationSchema={clientFormSchema}
      >
        {({ register, getValues, setValue, clearErrors, formState: { errors } }) => (
          <>
            <FormLayout.Block>
              <FormLayout.Title
                title="Basic Information"
              />

              <FormLayout.Group
                label="Display Name"
                htmlFor="display_name"
              >
                <Input
                  {...register('display_name')}
                  placeholder="Enter display name"
                  error={errors?.display_name?.message}
                />
              </FormLayout.Group>
            </FormLayout.Block>

            <FormLayout.Block>
              <FormLayout.Title
                title="Point of Contact - Details"
                subtitle="This will be the primary contacts details for this client"
              />

              <FormLayout.Group
                label="First Name"
                htmlFor="first_name"
              >
                <Input
                  {...register('first_name')}
                  placeholder="Enter first name"
                  error={errors?.first_name?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Last Name"
                htmlFor="last_name"
              >
                <Input
                  {...register('last_name')}
                  placeholder="Enter last name"
                  error={errors?.last_name?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Email"
                htmlFor="email"
              >
                <Input
                  {...register('email')}
                  placeholder="Enter email"
                  error={errors?.email?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Phone"
                htmlFor="phone"
              >
                <Phone
                  defaultPhone={getValues('phone')}
                  defaultCountryCode={getValues('country_code')}
                  onChange={(phone) => {
                    setValue('phone', phone.phone)
                    setValue('country_code', phone.country_code)

                    if (errors.phone || errors.country_code) {
                      clearErrors(['country_code', 'phone'])
                    }
                  }}
                  error={errors?.phone?.message || errors?.country_code?.message}
                />
              </FormLayout.Group>
            </FormLayout.Block>

            <FormLayout.Block>
              <FormLayout.Title
                title="Point of Contact - Address"
                subtitle="This will be the primary contacts address for this client"
              />

              <FormLayout.Group
                label="Street Address"
                htmlFor="line_1"
              >
                <Input
                  {...register('line_1')}
                  placeholder="Enter street address"
                  error={errors?.line_1?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Line Two (Optional)"
                htmlFor="line_2"
              >
                <Input
                  {...register('line_2')}
                  placeholder="Enter line two"
                  error={errors?.line_2?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Town or City"
                htmlFor="town_or_city"
              >
                <Input
                  {...register('town_or_city')}
                  placeholder="Enter town or city"
                  error={errors?.town_or_city?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Country"
                htmlFor="country"
              >
                <Input
                  {...register('country')}
                  placeholder="Enter country"
                  error={errors?.country?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Postcode"
                htmlFor="postcode"
              >
                <Input
                  {...register('postcode')}
                  placeholder="Enter postcode"
                  error={errors?.postcode?.message}
                />
              </FormLayout.Group>
            </FormLayout.Block>

            {children}
          </>
        )}
      </Form>
    </FormLayout>
  )
}