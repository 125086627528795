import { useAdminGetAssetQuery } from 'api'
import { useAdminCreateMessageMutation } from 'api/endpoints/admin/message'
import FormHeader from 'components/admin/form_header'
import AdminMessageForm, { AdminMessageFormInputs } from 'components/admin/forms/asset/message'
import Spinner from 'components/spinner'
import { WeekDay } from 'enums/WeekDay'
import { useToast } from 'hooks/useToast'
import { AccessRequestLayout } from 'layouts/AccessRequestLayout'
import { useNavigate, useParams } from 'react-router-dom'

const AdminMessageCreatePage = () => {
  const { id: assetId } = useParams()
  const { successToast } = useToast()
  const navigate = useNavigate()

  const { data: asset } = useAdminGetAssetQuery(parseInt(assetId!))
  const [createMessage, { isLoading: isCreationLoading, error: creationErrors }] = useAdminCreateMessageMutation()

  const handleCreateMessage = async (inputs: AdminMessageFormInputs) => {
    await createMessage({
      asset_id: parseInt(assetId!),
      name: inputs.name,
      content: inputs.content,
      is_default: inputs.is_default,
      time_frames: 'days_of_week' in inputs
        ? inputs?.days_of_week?.map(day => ({
          day_of_week: day as WeekDay,
          start_time: inputs?.start_time as string,
          end_time: inputs?.end_time as string,
        }))
        : [],
    })
      .unwrap()
      .then((data) => {
        navigate(`/admin/dashboard/assets/manage/${data.asset_id}/messages/${data.id}`)
        successToast({ message: 'Message has been created' })
      })
      .catch(() => { /* Errors directly handled by form */ })
  }

  if (!asset) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <AccessRequestLayout
      userId={asset?.user_id}
      blockRedirect={!asset?.user_id}
      redirectUrl="/admin/dashboard/assets/browse"
    >
      <div className="flex flex-col h-full">

        <FormHeader
          title="Create Message"
          description="Create Message information"
          backBtnText="Back to Asset"
          backBtnUrl={`/admin/dashboard/assets/manage/${assetId}`}
        />

        <AdminMessageForm
          btnText="Create Message"
          errors={creationErrors}
          isLoading={isCreationLoading}
          onSubmit={handleCreateMessage}
          defaultValues={{
            asset_id: undefined,
            name: '',
            content: '',
            is_default: false,
            days_of_week: [],
            start_time: '09:00',
            end_time: '17:00',
          }}
        />

      </div>
    </AccessRequestLayout>
  )
}

export default AdminMessageCreatePage