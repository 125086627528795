import dataApi from 'api'
import ReportRequest from 'api/types/requests/admin/report/get'
import Report from 'api/types/response/report'

const adminReportBaseUrl = '/admin/report'

const adminReportEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    adminGetReport: build.query<Report, ReportRequest>({
      query: ({ ...params }) => ({
        url: adminReportBaseUrl,
        params,
      }),
    }),
  }),
})

export const {
  useAdminGetReportQuery,
} = adminReportEndpoints