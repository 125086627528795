import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetClientQuery } from 'api/endpoints/admin/client'
import FormHeader from 'components/admin/form_header'
import Spinner from 'components/spinner'
import { useParams } from 'react-router-dom'
import { CreditBlockSection } from './sections/credit_blocks'
import { ManageDetailsSection } from './sections/manage'
import { HorizontalDivider } from 'components/Dividers/horizontal'
import { ClientExport } from './sections/client_export'

export function AdminClientManagePage() {
  const { clientId } = useParams()

  const {
    data: client,
    isLoading: loadingClient,
  } = useGetClientQuery(parseInt(clientId!) ?? skipToken)

  if (loadingClient) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <>
      <FormHeader
        title="Manage Client"
        description="Manage Client information"
        backBtnText="Back to Clients"
        backBtnUrl="/admin/dashboard/clients/browse"
      />

      <ManageDetailsSection client={client} />

      <HorizontalDivider />

      <CreditBlockSection client={client} />

      <HorizontalDivider />

      <ClientExport client={client} />
    </>
  )
}