type Props = {
  children: JSX.Element | JSX.Element[]
  className?: string
}

const HeaderRow = ({ children, className }: Props) => {
  return (
    <thead className={className}>
      <tr className="text-sm text-gray-900 border-b border-table-border">
        {children}
      </tr>
    </thead>
  )
}

export default HeaderRow