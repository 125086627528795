import classNames from 'classnames'
import React from 'react'

const ModalFooter = ({ children, className }: { children: React.ReactNode, className?: string }) => {
  return (
    <div className={classNames('px-4 py-3 border-t rounded-t-sm', className)}>
      {children}
    </div>
  )
}

export default ModalFooter
