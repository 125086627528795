import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useGetDeviceQuery, useGetUserAssetsQuery, useUpdateDeviceMutation } from 'api'
import Asset from 'api/types/models/asset'
import { HorizontalDivider } from 'components/Dividers/horizontal'
import Button from 'components/button'
import Card from 'components/cards/default'
import { FormControl } from 'components/form/control'
import Dropdown from 'components/form/dropdown'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { FormLabel } from 'components/form/label'
import Phone from 'components/form/phone'
import Toggle from 'components/form/toggle'
import { Title } from 'components/headings'
import ArrowIcon from 'components/icons/ArrowIcon'
import Spinner from 'components/spinner'
import DeviceRelationships from 'enums/DeviceRelationship'
import { useToast } from 'hooks/useToast'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { z as zod } from 'zod'

const deviceUpdateSchema = zod.object({
  name: zod.string(),
  country_code: zod.string(),
  phone: zod.string(),
  relationship: zod.nativeEnum(DeviceRelationships),
  masked_uids: zod.array(zod.string()),
})

type FormInputs = zod.infer<typeof deviceUpdateSchema>

const DeviceShowPage = () => {
  const { id: deviceId } = useParams()
  const { errorToast } = useToast()
  const navigate = useNavigate()

  const { data: device, isLoading: isDeviceLoading } = useGetDeviceQuery({ id: deviceId! })
  const { data: assetData } = useGetUserAssetsQuery({})

  const [updateDevice, { isSuccess, isLoading, error: updateError }] = useUpdateDeviceMutation()

  const handleSubmit = (data: FormInputs) => {
    updateDevice({
      id: parseInt(deviceId!),
      body: {
        ...data,
      },
    })
      .unwrap()
      .catch((error) => {
        if (error?.status == 422) return

        errorToast({
          title: 'Person Update Failed',
          message: 'There was a problem updating this person, please try again later',
        })
      })
  }

  useEffect(() => {
    if (device) {
      if (device.is_asset_owner) {
        navigate('/devices')
        errorToast({
          title: 'Unable to update person',
          message: 'This person is the owner of a KiCall, to update this person update your account settings!',
        })
      }
    }
  }, [device])

  return (
    <Card>

      <div className="flex justify-between items-center">
        <Button
          href="/devices"
          type="link"
          variant="secondary"
          icon={<ArrowIcon className="w-5 h-5" />}
          isIconOnly
        />
      </div>

      <HorizontalDivider className="my-4" />

      <Title className="!m-0" align="left">Configure Person</Title>

      {
        isDeviceLoading &&
        <Spinner />
      }

      {
        !isDeviceLoading &&
        <Form<FormInputs, typeof deviceUpdateSchema>
          error={(updateError as FetchBaseQueryError)?.status === 422 ? updateError : undefined}
          onSubmit={(data) => handleSubmit(data)}
          validationSchema={deviceUpdateSchema}
          defaultValues={{
            name: device?.name,
            phone: device?.phone,
            country_code: device?.country_code,
            relationship: device?.relationship as DeviceRelationships,
            masked_uids: device?.assets?.map((asset) => asset.masked_uid),
          }}
        >
          {(methods) => (
            <>

              <FormWrapper {...methods} assets={assetData} />

              <Button
                type="submit"
                className="mt-8"
                successText="Saved"
                isSuccess={isSuccess}
                isLoading={isLoading}
              >
                Save
              </Button>

            </>
          )}
        </Form>
      }

    </Card>
  )
}

interface FormWrapperType extends UseFormReturn<FormInputs> {
  assets: Asset[] | undefined,
}

function FormWrapper({
  register,
  getValues,
  setValue,
  clearErrors,
  watch,
  formState: { errors, defaultValues },
  assets,
}: FormWrapperType) {
  const [selectedMaskedUids, setSelectedMaskedUids] = useState(defaultValues?.masked_uids)

  // Control selected masked ids
  useEffect(() => {
    if (selectedMaskedUids === undefined && !!defaultValues?.masked_uids) {
      setSelectedMaskedUids(defaultValues.masked_uids)
    }
  }, [defaultValues?.masked_uids])

  const issueTypeOptions = (Object.entries(DeviceRelationships).map(([key, value]) => {
    return (
      <Dropdown.Item key={key} onClick={() => setValue('relationship', value)}>
        {value}
      </Dropdown.Item>
    )
  }))

  function handleArrayChange(value: string) {
    let array = getValues('masked_uids')
    const index = array.indexOf(value)

    if (index !== -1) {
      array.splice(index, 1)
    } else {
      array.push(value)
    }

    setValue('masked_uids', array)
    setSelectedMaskedUids(array)
  }

  return (
    <div className="space-y-6 mt-6">

      <FormControl>
        <FormLabel htmlFor="name">
          Name
        </FormLabel>
        <Input
          id="name"
          {...register('name', { required: true })}
          error={errors.name?.message}
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="phone">Phone</FormLabel>
        <Phone
          defaultPhone={getValues('phone')}
          defaultCountryCode={getValues('country_code')}
          onChange={(phone) => {
            setValue('phone', phone.phone)
            setValue('country_code', phone.country_code)
            if (errors.phone || errors.country_code) {
              clearErrors(['country_code', 'phone'])
            }
          }}
          error={errors?.phone?.message || errors?.country_code?.message}
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="relationship">Relationship</FormLabel>
        <Dropdown
          itemClass="-translate-y-full lg:translate-y-0"
          {...register('relationship')}
          trigger={<div>{watch('relationship') ?? 'Other'}</div>}
          error={errors?.relationship?.message}>
          {issueTypeOptions}
        </Dropdown>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="masked_uids">Assets</FormLabel>
        {
          assets && assets.length > 0 && assets.map((asset) => {
            return (
              <FormControl key={`asset-option-${asset.masked_uid}`} className="flex justify-between my-1 md:items-center items-start gap-4 md:gap-8">
                <FormLabel
                  onClick={() => handleArrayChange(asset.masked_uid)}
                  className="font-thin">
                  {asset.description ?? 'No Name'}
                </FormLabel>
                <Toggle
                  onChange={() => handleArrayChange(asset.masked_uid)}
                  checked={selectedMaskedUids?.includes(asset.masked_uid)}
                />
              </FormControl>
            )
          })
        }
      </FormControl>

    </div>
  )
}



export default DeviceShowPage