const ChevronUpIcon = ({ className }: { className?: string }) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask id="mask0_27_38" maskUnits="userSpaceOnUse" x="1" y="3" width="10" height="6">
        <path d="M10.4964 3.51676C10.4964 3.62926 10.4589 3.74293 10.3839 3.83785L6.38903 8.80426C6.29176 8.92496 6.14528 8.99528 5.98942 8.99528C5.83473 8.99528 5.68707 8.92496 5.59098 8.80426L1.61715 3.85895C1.4402 3.63864 1.47535 3.31637 1.69567 3.13942C1.91598 2.96246 2.23824 2.99762 2.4152 3.21793L5.99059 7.66637L9.5859 3.19567C9.76285 2.97535 10.0851 2.9402 10.3054 3.11715C10.4308 3.2191 10.4964 3.36676 10.4964 3.51676Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_27_38)">
        <rect width="12" height="12" fill="currentColor" />
      </g>
    </svg>

  )
}

export default ChevronUpIcon