import dataApi from 'api'
import { stringify } from 'qs'
import GetCreditLogsRequest from 'api/types/requests/credit_log/list'
import GetCreditLogsResponse from 'api/types/response/CreditLog/GetCreditLogsResponse'

const creditLogBaseUrl = '/admin/credit-logs'

const creditLogEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    getCreditLogs: build.query<GetCreditLogsResponse, GetCreditLogsRequest>({
      query: (params) => ({
        url: `${creditLogBaseUrl}?${stringify(params, { arrayFormat: 'brackets' })}`,
      }),
      providesTags: ['credit_logs'],
    }),
  }),
})

export const { 
  useGetCreditLogsQuery,
} = creditLogEndpoints