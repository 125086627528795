import { Transition } from '@headlessui/react'
import { FormatDateTime } from 'components/format-datetime'
import { WeekDay } from 'enums/WeekDay'
import { groupBy } from 'lodash'
import { Fragment } from 'react'


type TimeFrameConflictProps = {
  value: {
    name: string
    messageId: number,
    day_of_week: WeekDay,
    start_time: string,
    end_time: string,
  }[]
}

export function MessageConflict({ value }: TimeFrameConflictProps): JSX.Element {
  const groupedConflicts = groupBy(value, 'name')

  const messageKeys = Object.keys(groupedConflicts ?? {})

  return (
    <Transition
      as={Fragment}
      show={messageKeys?.length > 0}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="px-3 py-2 my-4 text-sm border rounded border-indicator-error bg-indicator-error bg-opacity-5 text-indicator-error min-h-[100px]">
        <p className="mb-3">This time frame has conflicts with the following messages:</p>
    
        <ul>
          {
            Object.keys(groupedConflicts ?? {}).map(key => (
              <li key={key}>
                <strong>{key}</strong>

                <ol className="pl-6 list-disc">
                  {
                    groupedConflicts?.[key]?.map((timeFrame, idx) => (
                      <li key={`${key}-time-frame-${idx}`}>
                      on <strong>{timeFrame.day_of_week}
                        </strong> between <strong>
                          <FormatDateTime value={timeFrame.start_time} format="HH:mm" />
                        </strong> and <strong>
                          <FormatDateTime value={timeFrame.end_time} format="HH:mm" /></strong>
                      </li>
                    ))
                  }
                </ol>
              </li>
            ))
          }
        </ul>
      </div>
    </Transition>

  )
}