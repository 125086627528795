import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../api/types/models/user'

export interface AuthState {
  authenticated: boolean | null
  is_admin: boolean
  user: User | null
}

const initialState: AuthState = {
  authenticated: null,
  is_admin: false,
  user: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, user: PayloadAction<User>) => {
      state.is_admin = user.payload.is_admin
      state.user = user.payload
      state.authenticated = true
    },

    logout: (state) => {
      state.is_admin = false
      state.authenticated = false
      state.user = null
    },

    updateAuthUser: (state, user: PayloadAction<User>) => {
      state.is_admin = user.payload.is_admin
      state.user = { ...state.user, ...user.payload }
    },
  },
})

export const {
  login,
  logout,
  updateAuthUser,
} = authSlice.actions

export default authSlice.reducer