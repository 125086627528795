import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { MouseEventHandler } from 'react'

const DropdownItem = ({
  children,
  onClick,
}: {
children: string | JSX.Element | JSX.Element[],
onClick?: MouseEventHandler,
}) => {
  return <Menu.Item>
    {({ active }: { active: boolean }) => (
      <button
        onClick={onClick}
        type="button"
        className={classNames(
          'group flex rounded-md items-center hover:text-white w-full px-2 py-2 text-sm',
          {
            'bg-primary text-white': active,
            'text-body-copy': !active,
          },
        )}
      >
        {children}
      </button>
    )}
  </Menu.Item>
}

export default DropdownItem