import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { first } from 'lodash'
import { PropsWithChildren } from 'react'

type RadioCardOptionProps = PropsWithChildren<{
  value: string | boolean | number
  first?: boolean,
  last?: boolean,
}>

export function RadioCardOption ({
  value,
  first,
  last,
  children,
}: RadioCardOptionProps) {
  return (
    <RadioGroup.Option value={value} className="w-full cursor-pointer">
      {({ checked }) => (
        <p className={classNames('w-full px-4 py-2 border text-center transition-all', {
          'border-secondary-blue text-secondary-blue': checked,
          'text-primary-gray border-secondary-gray': !checked,
          'rounded-l-md': first,
          'rounded-r-md': last,
        })}>
          {children}
        </p>
      )}
    </RadioGroup.Option>
  )
}