import classNames from 'classnames'
import React from 'react'

type Props = {
  children: JSX.Element | JSX.Element[] | string
  onClick?: () => void
  className?: string
}

const Row = ({ children, onClick, className }: Props) => {
  return (
    <tr className={classNames('border-b text-primary-gray border-b-slate-200', className)} onClick={onClick}>
      {children}
    </tr>
  )
}

export default Row