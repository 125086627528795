import { useGetCreditBlocksQuery } from 'api/endpoints/admin/credit-block'
import { Client } from 'api/types/models/client'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import Pagination from 'components/pagination'
import { StatCard } from 'components/stats/card'
import { CreditBlockTable } from 'components/tables/credit_block'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

type CreditBlockSectionProps = {
  client: Client | undefined
}

export function CreditBlockSection({ client }: CreditBlockSectionProps) {
  const { clientId } = useParams()
  
  const [currentPage, setCurrentPage] = useState(1)

  const {
    data: creditBlocks,
    isLoading: loadingCreditBlocks,
  } = useGetCreditBlocksQuery({
    page: currentPage,
    client_id: parseInt(clientId!),
  }, { skip: !clientId })

  return (
    <FormLayout>
      <FormLayout.Block>
        <div className="flex items-center justify-between gap-4">
          <FormLayout.Title 
            title="Credit Blocks"
            subtitle="Manage credit blocks for this client"
          />

          <Button href={`/admin/dashboard/clients/manage/${clientId}/credit-blocks/create`} className="max-h-11">
            Create Block
          </Button>
        </div>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <StatCard>
            <StatCard.Title>Total Credits</StatCard.Title>
            <StatCard.Representation 
              type="number"
              currentValue={client?.total_credits ?? 0}
            />
          </StatCard>

          <StatCard>
            <StatCard.Title>Total Spent</StatCard.Title>
            <StatCard.Representation 
              type="number"
              currentValue={client?.total_spent ?? 0}
            />
          </StatCard>

          <StatCard>
            <StatCard.Title>Total Remaining</StatCard.Title>
            <StatCard.Representation 
              type="number"
              currentValue={client?.total_remaining ?? 0}
            />
          </StatCard>
        </div>
        
        <div className="overflow-x-scroll">
          <CreditBlockTable 
            value={creditBlocks?.data}
            isLoading={loadingCreditBlocks}
          />
        </div>

        <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
          <Pagination 
            onPage={creditBlocks?.meta.current_page ?? 1} 
            onPageChange={(page) => setCurrentPage(page)} 
            totalPages={creditBlocks?.meta.last_page ?? 1} 
          />
        </div>
      </FormLayout.Block>
    </FormLayout>
  )
}