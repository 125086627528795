import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAdminLoginMutation } from 'api'
import Button from 'components/button'
import Card from 'components/cards/default'
import Input from 'components/form/input'
import useErrorHandler from 'hooks/useErrorHandler'
import { login } from 'slices/auth'

type FormInputs = { 
  email: string
  password: string
} 

const AdminLoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandler = useErrorHandler()

  const { register, handleSubmit, setError, formState: { errors } } = useForm<FormInputs>()
  const [submitLogin, { data: loginResult, error: loginError, isLoading: isLoginLoading }] = useAdminLoginMutation()

  useEffect(() => {
    if (loginError) {
      errorHandler(loginError, setError)
    }
  }, [loginError])

  useEffect(() => {
    if (loginResult) {
      dispatch(login(loginResult.user))
      navigate('/admin/dashboard/clients/browse')
    }
  }, [dispatch, loginResult])

  const onSubmit = (data: FormInputs) => { 
    submitLogin({
      ...data,
    })
  }

  return (
    <div className="h-full p-3 bg-slate-200 lg:p-0">
      <div className="flex h-full max-w-md mx-auto">
        <Card className="my-auto shadow-sm">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <p className="my-auto text-lg font-semibold">Login</p>
            <div className="space-y-2">
              <Input {...register('email')} type="email" label="Email" error={errors.email?.message} required />
              <Input {...register('password')} type="password" label="Password" error={errors.password?.message} required />
            </div>
            <div className="flex justify-end gap-2">
              <Button variant="secondary" href="/">Cancel</Button>
              <Button type="submit" isLoading={isLoginLoading}>Login</Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default AdminLoginPage