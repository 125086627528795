import classNames from 'classnames'

type Props = {
  text?: string
  className?: string
}

const FormError = ({ text, className }: Props) => {
  
  return (
    <p className={classNames('text-xs font-medium text-red-500 whitespace-pre-wrap text-left mt-1', className)}>
      {text}
    </p>
  )
}

export default FormError
