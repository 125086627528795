import { Transition } from '@headlessui/react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useVerifyAccountMutation } from 'api/endpoints/invite'
import { Banner } from 'components/banner'
import Button from 'components/button'
import Card from 'components/cards/default'
import { VerifyAccountForm, VerifyAccountFormInputs } from 'components/forms/verify-account'
import { useToast } from 'hooks/useToast'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

export function AdminVerifyAccountPage() {
  const navigate = useNavigate()

  const [error, setError] = useState('')
  
  const [searchParams] = useSearchParams()

  const { errorToast } = useToast()

  const [verifyAccount, {
    error: verifyAccountError,
    isLoading: isVerifyingAccount,
  }] = useVerifyAccountMutation()

  const handleSubmit = async (inputs: VerifyAccountFormInputs, methods?: UseFormReturn<VerifyAccountFormInputs>) => {
    const splitTokenParam = searchParams.get('token')?.split('|')

    if(splitTokenParam?.length !== 2) {
      setError('Your invite link has an invalid format, please contact your administrator to resend the invite.')
      return
    }

    await verifyAccount({
      user_id: parseInt(splitTokenParam?.[0], 10),
      token: splitTokenParam?.[1],
      first_name: inputs.firstName,
      last_name: inputs.lastName,
      phone: inputs.phone,
      country_code: inputs.countryCode,
      password: inputs.password,
      password_confirmation: inputs.passwordConfirmation,
    })
      .unwrap()
      .then(() => navigate('/admin/dashboard/clients/browse'))
      .catch((err) => {
        methods?.setValue('passwordConfirmation', '')

        if(err?.status === 422) return

        if(err?.data?.type === 'custom' && err?.data?.message) {
          setError(err.data.message)
          return
        }
        
        errorToast({
          title: 'Failed to Verify Account',
          message: 'We\'re unable to verify your account at this time. Please try again later.',
        })
      })
  }

  return (
    <div className="h-full p-3 bg-slate-200 lg:p-0">
      <div className="flex h-full max-w-lg mx-auto">
        <Card className="my-auto shadow-sm">
          <h1 className="mb-8 text-xl font-medium">
            Verify Account
          </h1>       

          <Transition
            show={!!error}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Banner intent="error" className="mb-6">
              {error}
            </Banner>
          </Transition>

          <VerifyAccountForm
            onSubmit={handleSubmit}
            errors={(verifyAccountError as FetchBaseQueryError)?.status === 422 ? verifyAccountError : undefined}
            defaultValues={{
              firstName: '',
              lastName: '',
              password: '',
              phone: '',
              countryCode: '44',
              passwordConfirmation: '',
            }}
          >          
            <Button
              type="submit"
              className="!mt-12"
              isLoading={isVerifyingAccount}
              block
            >
              Verify Account
            </Button>
          </VerifyAccountForm>
        </Card>
      </div>
    </div>
  )
}