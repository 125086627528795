import Spinner from 'components/spinner'
import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { UserRole } from 'enums/UserRole'
import { useAdminGetAccessRequestsQuery } from 'api/endpoints/admin/request-access'

type AccessRequestLayoutProps = PropsWithChildren<{
  userId?: number
  blockRedirect: boolean
  redirectUrl?: string
}>

export function AccessRequestLayout({
  userId,
  redirectUrl,
  blockRedirect,
  children,
}: AccessRequestLayoutProps): JSX.Element {
  const authUser = useSelector((state: RootState) => state.auth.user)

  const {
    data: accessRequests,
    isLoading: accessRequestsLoading,
  } = useAdminGetAccessRequestsQuery({}, {
    pollingInterval: 60000, // 1 minute
    skip: authUser?.role === UserRole.SUPER_ADMIN,
  })

  if (blockRedirect || authUser?.role === UserRole.SUPER_ADMIN) {
    return <>{children}</>
  }

  if (accessRequestsLoading) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  // If there are no access requests, then redirect
  if (!accessRequests?.length) {
    return <Navigate to={redirectUrl || '/'} />
  }

  const accessRequest = accessRequests?.find(request => request?.user_id === userId)

  // If there are no access requests or the access request is not verified, then redirect
  if (!accessRequest || !accessRequest?.verified_at) {
    return <Navigate to={redirectUrl || '/'} />
  }

  return <>{children}</>
}