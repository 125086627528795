import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './router'
import './styles/index.css'
import { store } from './store'
import { Provider } from 'react-redux'

const isProduction = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const gtm = document.getElementById('gtm') as HTMLElement

gtm.innerHTML = isProduction ?
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${process.env.REACT_APP_GOOGLE_TAG_MANAGER}');` : ''


root.render(
  <React.StrictMode>
    <Provider store={store}>
      {
        isProduction &&
        <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`}
          height="0" width="0" className='hidden'></iframe></noscript>
      }
      <Router />
    </Provider>
  </React.StrictMode>
)