import React from 'react'
import classNames from 'classnames'

const ModalContent = ({ children, className }: { children: React.ReactNode, className?: string }) => {
  return (
    <div className={classNames('p-6 text-primary-gray text-sm', className)}>
      {children}
    </div>
  )
}

export default ModalContent
