import { Message } from 'api/types/models/message'
import { Badge } from 'components/badge'
import { FormatDateTime } from 'components/format-datetime'
import ChevronIcon from 'components/icons/ChevronUpIcon'
import { NarrowArrowIcon } from 'components/icons/NarrowArrowIcon'
import { WeekDay } from 'enums/WeekDay'
import { cn } from 'helpers/styling'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

type TimedMessageProps = {
  to: string,
  message: Message
  className?: string
}

export function TimedMessage({ to, message, className }: TimedMessageProps) {
  const timeFrame = message?.time_frames?.reduce((acc, curr) => {
    return ({
      startTime: DateTime.fromISO(curr?.start_time as string),
      endTime: DateTime.fromISO(curr?.end_time as string),
      daysOfWeek: [...acc?.daysOfWeek ?? [], curr?.day_of_week],
    })
  }, {} as { startTime: DateTime, endTime: DateTime, daysOfWeek: WeekDay[] })

  return (
    <Link 
      to={to} 
      className="block p-3 border rounded-md"
    >
      <div className={cn('flex items-center justify-between', className)}>
        <div className="space-y-1">
          <p className="font-semibold">
            {message?.name ?? 'No name'}
          </p>
      
          <div className="flex gap-1 pt-1">
            {
              timeFrame?.daysOfWeek?.length ? (
                <div className="flex flex-wrap items-center gap-2">
                  <div className="flex flex-wrap gap-1">
                    {
                      Object.values(WeekDay)?.map(day => (
                        <Badge 
                          key={day} 
                          size="sm" 
                          className="capitalize text-[10px] leading-[14px] w-6 h-6"
                          intent={timeFrame?.daysOfWeek?.includes(day) ? 'primary' : 'default'} 
                        >
                          {day?.slice(0, 1)}
                        </Badge>
                      ))
                    }
                  </div>

                  <NarrowArrowIcon className="w-4" />

                  <div className="flex items-center gap-1 text-sm">
                    <FormatDateTime value={timeFrame.startTime} format="HH:mm" />
                    <span>-</span>
                    <FormatDateTime value={timeFrame.endTime} format="HH:mm" />
                  </div>
                </div>
              ) : (
                <span className="text-sm text-red-500 text-opacity-80">
                  Time frames not configured
                </span>
              )
            }
          </div>
        </div>

        <ChevronIcon className="w-4 -rotate-90 text-primary shrink-0" />
      </div>
    </Link>
  )
}