import { Navigate } from 'react-router-dom'
import Button from '../../components/button'
import authImage from '../../resources/authImage.png'

const ErrorPage = () => {
  return (
    <div>
      <div className="container absolute inset-0 mx-auto">
        <div className="flex text-center flex-col justify-center items-center w-full h-full max-w-sm p-4 mx-auto lg:max-w-none lg:p-2 text-white">
          <h1 className='text-3xl font-bold'>404</h1>
          <p className='text-3xl font-bold'>Page not found</p>
          <p className="text-sm max-w-md mt-8 mb-4">The page you were looking for could not be found, it might have been removed, renamed or did not exist in the first place.</p>
          <a className='mt-4' href="/login">
            <Button>Back to Login</Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage