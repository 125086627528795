import { useAdminDeleteThemeMutation } from 'api'
import Theme from 'api/types/models/theme'
import Button from 'components/button'
import Modal from 'components/modals'
import NotificationToast from 'components/notifications'
import useErrorHandler from 'hooks/useErrorHandler'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

type ModalProps = {
  theme?: Theme
  isOpen: boolean
  onClose: () => void
}

const DeleteThemeModal = ({ theme, isOpen = false, onClose }: ModalProps) => {
  const dispatch = useDispatch()
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()

  const [deleteTheme, { isSuccess, isLoading, error }] = useAdminDeleteThemeMutation()
  const { setError } = useForm()

  const onSubmit = () => {
    if (theme) {
      deleteTheme({ id: theme.id })
    }
  }

  useEffect(() => {
    if (isSuccess && theme) {
      notify(<NotificationToast message="Theme deleted successfully" />)
      onClose()
    }
  }, [dispatch, isSuccess])

  useEffect(() => {
    if (error) {
      errorHandler(error, setError)
    }
  }, [error])

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding>
      <Modal.Header title="Delete Theme" />
      <Modal.Content>
        <p>Are you sure you want to delete this theme? ({theme?.title})</p>
      </Modal.Content>
      <Modal.Footer className="flex flex-row justify-end gap-2">
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="danger" isLoading={isLoading} onClick={() => onSubmit()}>Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteThemeModal