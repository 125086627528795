import { DateTime } from 'luxon'
import AdminTable from 'components/admin/tables'
import { FormatDateTime } from 'components/format-datetime'
import { CreditLogTableSkeletonRow } from './skeleton'
import { getFormattedCreditReason } from 'enums/CreditLogReason'
import MultiSort from 'api/types/requests/Sort'
import { Link } from 'react-router-dom'
import GetCreditLogsResponse from 'api/types/response/CreditLog/GetCreditLogsResponse'

type CreditLogTableProps = {
  value?: GetCreditLogsResponse
  sorts: MultiSort,
  onSort: (key: string) => void
  isLoading?: boolean
}

export function CreditLogTable({ 
  value,
  sorts,
  onSort, 
  isLoading, 
}: CreditLogTableProps) {
  return (
    <AdminTable>
      <AdminTable.Head>
        <AdminTable.Heading>#</AdminTable.Heading>
        <AdminTable.Heading 
          onClick={() => onSort('user_id')}
          direction={sorts?.user_id}
          sortable
        >
          User
        </AdminTable.Heading>
        <AdminTable.Heading 
          onClick={() => onSort('asset_id')} 
          direction={sorts?.asset_id}
          sortable
        >
          Asset
        </AdminTable.Heading>
        <AdminTable.Heading 
          onClick={() => onSort('reason')}
          direction={sorts?.reason}
          sortable
        >
          Reason ({value?.logs?.meta?.total ?? 0})
        </AdminTable.Heading>
        <AdminTable.Heading 
          onClick={() => onSort('created_at')} 
          direction={sorts?.created_at}
          sortable
        >
          Logged At
        </AdminTable.Heading>
      </AdminTable.Head>
    
      <AdminTable.Body>
        {
          isLoading && (
            <>
              {Array.from({ length: 10 }).map((_, idx) => (
                <CreditLogTableSkeletonRow key={idx} />
              ))}
            </>
          )
        }

        {
          !isLoading && !value?.logs?.data?.length && (
            <AdminTable.Row>
              <AdminTable.Column colSpan={5} className="text-center">
                <span className="text-base">
                  No credit logs found
                </span>
              </AdminTable.Column>
            </AdminTable.Row>
          )
        }

        {
          value?.logs?.data?.map((creditLog) => (
            <AdminTable.Row key={creditLog.id}>            
              <AdminTable.Column>
                {creditLog.id}
              </AdminTable.Column>

              <AdminTable.Column>
                {
                  creditLog?.user_id ? (
                    <Link 
                      to={`/admin/dashboard/users/manage/${creditLog?.user_id}`} 
                      className="url-text" 
                    >
                      {creditLog?.user_id}
                    </Link>
                  ) : (
                    <span>-</span>
                  )
                }
              </AdminTable.Column>

              <AdminTable.Column>
                {
                  creditLog?.asset_id ? (
                    <Link 
                      to={`/admin/dashboard/assets/manage/${creditLog?.asset_id}`} 
                      className="url-text" 
                    >
                      {creditLog?.asset_id}
                    </Link>
                  ) : (
                    <span>-</span>
                  )
                }
              </AdminTable.Column>

              <AdminTable.Column>
                {getFormattedCreditReason(creditLog?.reason)}
              </AdminTable.Column>

              <AdminTable.Column>
                <FormatDateTime value={creditLog.created_at} format={DateTime.DATE_MED} />
              </AdminTable.Column>
            </AdminTable.Row>
          ))
        }
      </AdminTable.Body>
    </AdminTable>
  )
}