import { configureStore } from '@reduxjs/toolkit'
import dataApi from 'api'
import assetSlice from 'slices/asset'
import authSlice from 'slices/auth'

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataApi.middleware),
  reducer: {
    [dataApi.reducerPath]: dataApi.reducer,
    auth: authSlice,
    asset: assetSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch