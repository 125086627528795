import classNames from 'classnames'
import { forwardRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../spinner'
import { Transition } from '@headlessui/react'
import CheckIcon from 'components/icons/CheckIcon'

type ButtonProps = {
  block?: boolean
  children?: JSX.Element | string
  dashed?: boolean
  disabled?: boolean
  href?: string
  icon?: JSX.Element | undefined
  isIconOnly?: boolean
  isLoading?: boolean
  type?: 'button' | 'submit' | 'link'
  onClick?: () => void
  variant?: 'primary' | 'secondary' | 'danger' | 'link'
  isSuccess?: boolean
  successText?: string
  className?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button({
  block = false,
  children,
  className,
  dashed = false,
  disabled = false,
  href,
  isLoading = false,
  icon,
  isIconOnly = false,
  onClick,
  type = 'button',
  variant = 'primary',
  isSuccess = false,
  successText = 'Saved',
}, ref) {

  const spinnerVariant = classNames('absolute', {
    'text-primary-gray': variant === 'secondary',
    'text-red-500': variant === 'danger',
  })

  // we keep track of this to determine whether the successText parameter
  // is displayed on the button as opposed to the button's regular label
  const [showActionCompleted, setShowActionCompleted] = useState(isSuccess)

  useEffect(() => {
    if (isSuccess) {
      setShowActionCompleted(true)
      setTimeout(() => {
        setShowActionCompleted(false)
      }, 3000)
    }
  }, [isSuccess])

  const buttonClassNames = classNames('inline-flex items-center text-center transition justify-center focus:outline-primary', {
    'w-full': block,
    'md:rounded-md rounded-3xl': icon,
    'rounded-md': !icon,
    'min-w-[92px] py-2 px-4': !isIconOnly,

    // Button Variants
    'bg-secondary-blue hover:bg-secondary-dark-blue text-white outline-offset-2': !disabled && variant === 'primary',
    'bg-white hover:bg-gray-100 text-primary-gray': !disabled && variant === 'secondary',
    'bg-white text-red-500 hover:bg-red-100 hover:border-red-300 focus:outline-red-500': !disabled && variant === 'danger',
    'bg-transparent text-primary-gray': !disabled && variant === 'link',
    'bg-secondary-gray text-disabled-dark cursor-default': disabled === true,

    // Border Variants
    'border-solid border border-secondary-gray': !dashed && (variant !== 'primary' || disabled),
    'border-solid border border-transparent': !dashed && variant === 'link',
    'border-dashed border border-secondary-gray': dashed === true,

    'pointer-events-none': disabled,
  })

  const InnerButton = () => {
    return (
      <>
        {
          icon &&
          <span className={`${!isIconOnly ? 'md:mr-2' : 'p-2'}`}>{icon}</span>
        }
        <span className={classNames(icon && 'hidden md:inline', isLoading && 'invisible')}>
          {showActionCompleted
            ? (<div className="flex items-center gap-1">
              {successText}
              <CheckIcon className='h-4' />
            </div>)
            : children}
        </span>
        <Spinner className={classNames(isLoading ? spinnerVariant : 'hidden')} />
      </>
    )
  }

  if (href !== undefined) {
    return /^https?:\/\/|tel:|mailto:/i.test(href.toString())
      ? <a href={href.toString()} className={classNames(buttonClassNames, className)}><InnerButton /></a>
      : <Link to={href} className={classNames(buttonClassNames, className)}><InnerButton /></Link>
  }

  return (
    <button
      className={classNames(buttonClassNames, className)}
      onClick={onClick}
      type={type === 'link' ? 'button' : type}
      disabled={disabled || isLoading}
      ref={ref}
    >
      <InnerButton />
    </button>
  )
})

export default Button