import dataApi from 'api'
import { Message } from 'api/types/models/message'
import { CreateMessageRequest } from 'api/types/requests/message/create-message'
import { GetMessageRequest } from 'api/types/requests/message/get-message'
import { GetMessagesRequest } from 'api/types/requests/message/get-messages'
import { UpdateMessageRequest } from 'api/types/requests/message/update-message'

const messageBaseUrl = '/messages'

const adminMessageEndpoints = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    adminCreateMessage: builder.mutation<Message, CreateMessageRequest>({
      query: (body) => ({
        url: messageBaseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['admin_messages', 'admin_asset', 'admin_assets'],
    }),

    adminGetMessages: builder.query<Message[], GetMessagesRequest>({
      query: (params) => ({
        url: messageBaseUrl,
        params,
      }),
      providesTags: ['admin_messages'],
    }),

    adminGetMessage: builder.query<Message, GetMessageRequest>({
      query: ({ id }) => ({
        url: `${messageBaseUrl}/${id}`,
      }),
      providesTags: ['admin_message'],
    }),

    adminUpdateMessage: builder.mutation<Message, UpdateMessageRequest>({
      query: ({ id, ...body }) => ({
        url: `${messageBaseUrl}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['admin_messages', 'admin_message', 'admin_asset', 'admin_assets'],
    }),

    adminDeleteMessage: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `${messageBaseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin_messages', 'admin_message', 'admin_asset', 'admin_assets'],
    }),
  }),
})

export const {
  useAdminCreateMessageMutation,
  useAdminGetMessagesQuery,
  useAdminGetMessageQuery,
  useAdminUpdateMessageMutation,
  useAdminDeleteMessageMutation,
} = adminMessageEndpoints