import classNames from 'classnames'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  className?: string
  isGlass?: boolean
}

const Card = ({ children, className, isGlass }: Props) => {
  return (
    <div className={classNames(`flex flex-col md:min-h-min mx-auto w-full p-6 ${isGlass ? 'backdrop-blur-lg bg-white/60' : 'bg-white border-secondary-gray border'} rounded-md text-primary-gray transition`, className)}>
      {children}
    </div>
  )
}

export default Card