import React from 'react'

const TermsAndConditionsBody = () => {
  return (
    <>
      <p>SUPRA UK LTD, (The Company &amp; divisions thereof) only does business upon the following Conditions of Sale and all orders are accepted and executed on the understanding that these Conditions are incorporated into any Contract with a Customer to the exclusion of any conditions which the Customer seeks to impose unless otherwise agreed in writing and bearing the signature of the Managing Director or the Operations Director. Supra UK Ltd reserves the right to reject any order for products or services without prejudice.</p>

      <h2>1. INFORMATION ABOUT US</h2>
      <p>1.1. Supra UK Ltd are registered in England and Wales under company number 03294833 with our registered office at Curo Chartered Accountants, Curo House, Greenbox, Westonhall Road, Stoke Prior, Bromsgrove, B60 4AL.</p>
      <p>1.2. Our main trading address is Unit 4, Nunnery Park, Hornhill Road, Worcester, Worcestershire, WR4 0SH. Our VAT number is 748 0858 96.</p>

      <h2>2. PRICE AND PAYMENT</h2>                
      <p>2.1. The price of any Products will be as quoted, except in cases of obvious error. All orders will be invoiced at prices ruling at the date of dispatch. All prices are subject to change without notice.</p>
      <p>2.2. These prices exclude delivery costs and VAT, which will be added to the total amount due as set out in our Delivery Guide (Delivery costs available via our website, www.keysafe.co.uk for web orders). Contact the Sales Office on 0330 363 0303 for all other delivery costs.</p>
      <p>2.3. For credit customers, payment is strictly due 30 days from date of invoice. The Company reserves the right to charge interest at 2.5% over the Bank of England minimum lending rate for the time being on overdue accounts. Without prejudice to the above, in the event of payment not being received by the due date all monies owing to the Company under this or any other Contracts shall become payable immediately.</p>
      <p>2.4. Pending such payment the Company reserves the right to suspend performance of any of it&apos;s obligation to the Customer.</p>
      <p>2.5. Until such payment is received the Customer shall if so requested by the Company store the goods in such a way that they can be clearly identified as the property of the Company.</p>
      <p>2.6. If the Customer makes default in payment or if for any other reason the Company treats this contract as discharged the Company shall be entitled to enter the Customer&apos;s premises and to repossess its goods.</p>

      <h2>3. ORDER CONFIRMATION</h2>
      <p>Orders for credit accounts must be confirmed in writing by post, by fax or by email.</p>

      <h2>4. CANCELLATIONS AND RETURNS</h2>
      <p>4.1. To cancel a Contract, you must inform us in writing. You must also return the Product(s) to us immediately, in the same condition in which you received them, and at your own cost and risk. You have a legal obligation to take reasonable care of the Products while they are in your possession. If you fail to comply with this obligation, we may have a right of action against you for compensation.</p>
      <p>4.2. Orders will only be considered cancelled by the Company within 30 days of date of invoice and after written agreement on mutually satisfactory terms.</p>
      <p>4.3. All cancelled orders will incur a restocking and administration handling charge. This charge will vary depending on the type and number of products involved. These charges will apply where the goods have been despatched, and also, where the goods have been picked and packed prior to despatch. Charges are available on request.</p>
      <p>4.4. If the goods have already been despatched, then the customer has a duty to take &quot;reasonable care&quot; of them prior to returning them to Supra UK Ltd in order that they may be re-sold as new. Any failure in this duty may result in a claim by Supra UK Ltd for the resulting loss in value.</p>

      <h2>5. DELIVERY</h2>
      <p>5.1. Every attempt will be made to meet quoted delivery times subject to stock availability and order acceptance but time shall not be of the essence unless specifically agreed in writing. Unless so agreed goods cannot be returned if not inside the estimated delivery time.</p>
      <p>5.2. Delivery by courier will require a signature. It is the customer&apos;s responsibility to ensure deliveries can be made safely at ground level, free from obstructions. The customer is responsible for manual and mechanical off-loading provision at time of delivery. The customer is responsible for the provision of the correct delivery address prior to despatch and may be charged for any additional costs incurred during further attempts to deliver the goods should delivery address information supplied be incorrect or off-loading facilities be unavailable.</p>
      <p>5.3. Supra UK Ltd will not be responsible for any delay occasioned by carriers after dispatch from the Company&apos;s works. Any damages or shortages must be notified in writing, both to the carriers and the Company within three days of receipt of goods. In the case of non-delivery, claims must be made from seven days of receipt of invoice.</p>

      <h2>6. GOODS DAMAGED IN TRANSIT</h2>
      <p>If the goods are signed for on receipt as received in good condition, we are unable to process claims for damages. In order to be able to claim for damages, goods must be signed for as received unchecked or damaged.</p>

      <h2>7. DEFECTS AND GUARANTEE</h2>
      <p>7.1. KeySafe Products supplied by Supra UK Ltd are guaranteed to be free from defects of material and workmanship for a period of 24 months from purchase or 60 months on Supra C500 Pro and Supra P500 Pro products only. Other Products carry a 12 month guarantee. Guarantees do not cover batteries of any type used in connection with the products supplied.</p>
      <p>7.2. Claims for faulty materials or workmanship will not be accepted if modifications or repairs to the goods have been effected other than by the company (Supra UK limited).</p>
      <p>7.3. Faulty Products should be notified to Supra UK Ltd immediately with a description of the fault. If the fault cannot be resolved by telephone, in order to receive a replacement product the customer must return the faulty product, along with contact and purchase details, to Supra UK Ltd.</p>
      <p>7.4. Where the product is found not to be faulty upon inspection, it will be returned to the customer, a replacement will not be supplied and the cost of return carriage will be chargeable.</p>

      <h2>8. PAYMENT TERMS</h2>
      <p>8.1. For credit customers, payment is strictly due 30 days from date of invoice. The Company reserves the right to charge interest at 2.5% over the Bank of England minimum lending rate for the time being on overdue accounts. Without prejudice to the above, in the event of payment not being received by the due date all monies owing to the Company under this or any other Contracts shall become payable immediately.</p>
      <p>8.2. Pending such payment the Company reserves the right to suspend performance of any of it&apos;s obligation to the Customer.</p>
      <p>8.3. Until such payment is received the Customer shall if so requested by the Company store the goods in such a way that they can be clearly identified as the property of the Company.</p>
      <p>8.4. If the Customer makes default in payment or if for any other reason the Company treats this contract as discharged the Company shall be entitled to enter the Customer&apos;s premises and to repossess its goods.</p>

      <h2>9. RISK</h2>
      <p>Risk shall pass to the Customer on collection by courier from the Company premises. The Customer takes any risk of deterioration in the goods necessarily incident to the course of transit.</p>

      <h2>10. RETENTION OF TITLE</h2>
      <p>Title in any goods supplied by the Company shall not pass to the Customer until the Company has received payment in full for all such goods.</p>
      <p>*This does not affect your Statutory Rights*</p>

      <h2>11. LIMITATION OF LIABILITY</h2>
      <p>11.1. We warrant to you that any Product purchased from us through our site is of satisfactory quality and reasonably fit for all the purposes for which products of the kind are commonly supplied.</p>
      <p>11.2. Our liability for losses you suffer as a result of us breaking this agreement including deliberate breaches is strictly limited to the purchase price of the Product you purchased and any losses which are a foreseeable consequence of us breaking the agreement. Losses are foreseeable where they could be contemplated by you and us at the time your order is accepted by us.</p>
      <p>11.3. This does not include or limit in any way our liability:</p>

      <ul>
        <li> 11.3.1. For death or personal injury caused by our negligence;</li>
        <li> 11.3.2. Under section 2(3) of the Consumer Protection Act 1987; </li>
        <li> 11.3.3. For fraud or fraudulent misrepresentation; or</li>
        <li> 11.3.4. For any matter for which it would be illegal for us to exclude, or attempt to exclude, our liability.</li>
      </ul>

      <p> 11.4. We are not responsible for indirect losses which happen as a side effect of the main loss or damage and which are not foreseeable by you and us and even if such losses result from a deliberate breach of these Terms by us that would entitle you to terminate the contract between us, including but not limited to:</p>

      <ul>
        <li>11.4.1. loss of income or revenue</li>
        <li>11.4.2. loss of business</li>
        <li>11.4.3. loss of profits or contracts</li>
        <li>11.4.4. loss of anticipated savings</li>
        <li>11.4.5. loss of data</li>
        <li>11.4.6. waste of management or office time however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable]; provided that this clause 12.4 shall not prevent claims for loss of or damage to your tangible property that fall within the terms of clause 12.1 or clause 12.2 or any other claims for direct financial loss that are not excluded by any of categories (a) to (g) inclusive of this clause 12.4. </li>
      </ul>

      <p>Applicable laws require that some of the information or communications we send to you should be in writing. When using our site, you accept that communication with us will be mainly electronic. We will contact you by e-mail or provide you with information by posting notices on our website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing. This condition does not affect your statutory rights.</p>

      <h2>12. NOTICES</h2>

      <p>All notices given by you to us must be given to Supra UK Ltd, Unit 4 Nunnery Park, Hornhill Road, Worcester, WR4 0SX, UK OR <a href="mailto:sales@keysafe.co.uk">sales@keysafe.co.uk</a></p>

      <p>We may give notice to you at either the e-mail or postal address you provide to us when placing an order, or in any of the ways specified in clause 2 above. Notice will be deemed received and properly served immediately when posted on our website, 24 hours after an e-mail is sent, or three days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail, that such e-mail was sent to the specified e-mail address of the addressee.</p>

      <h2>13. TRANSFER OF RIGHTS AND OBLIGATIONS</h2>
      <p>13.1. The contract between you and us is binding on you and us and on our respective successors and assigns.</p>
      <p>13.2. You may not transfer, assign, charge or otherwise dispose of a Contract, or any of your rights or obligations arising under it, without our prior written consent.</p>
      <p>13.3. We may transfer, assign, charge, sub-contract or otherwise dispose of a Contract, or any of our rights or obligations arising under it, at any time during the term of the Contract.</p>

      <h2>14. EVENTS OUTSIDE OUR CONTROL</h2>
      <p>14.1. We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract that is caused by events outside our reasonable control (Force Majeure Event).</p>
      <p>14.2. A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:</p>
      <ul>
        <li>14.2.1. Strikes, lock-outs or other industrial action.</li>
        <li>14.2.2. Civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war.</li>
        <li>14.2.3. Fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster.</li>
        <li>14.2.4. Impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport.</li>
        <li>14.2.5. Impossibility of the use of public or private telecommunications networks.</li>
        <li>14.2.6. The acts, decrees, legislation, regulations or restrictions of any government.</li>
      </ul>                  
      <p>14.3. Our performance under any Contract is deemed to be suspended for the period that the Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavours to bring the Force Majeure Event to a close or to find a solution by which our obligations under the Contract may be performed despite the Force Majeure Event.</p>

      <h2>15. WAIVER</h2>
      <p>15.1. If we fail, at any time during the term of a Contract, to insist upon strict performance of any of your obligations under the Contract or any of these terms and conditions, or if we fail to exercise any of the rights or remedies to which we are entitled under the Contract, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such obligations.</p>
      <p>15.2. A waiver by us of any default shall not constitute a waiver of any subsequent default.</p>
      <p> 15.3. No waiver by us of any of these terms and conditions shall be effective unless it is expressly stated to be a waiver and is communicated to you in writing in accordance with clause 13 above.</p>

      <h2>16. SEVERABILITY</h2>
      <p>If any of these terms and Conditions or any provisions of a Contract are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>

      <h2>17. ENTIRE AGREEMENT</h2>
      <p>17.1. These terms and conditions and any document expressly referred to in them constitute the whole agreement between us and supersede any previous arrangement, understanding or agreement between us, relating to the subject matter of any Contract.</p>
      <p>17.2. We each acknowledge that, in entering into a Contract, (and the documents referred to in it), neither of us relies on any statement, representation, assurance or warranty (Representation) of any person (whether a party to that Contract or not) other than as expressly set out in these terms and conditions.</p>
      <p>17.3. Each of us agrees that the only rights and remedies available to us arising out of or in connection with a Representation shall be for breach of contract as provided in these terms and conditions.</p>
      <p>17.4. Nothing in this clause shall limit or exclude any liability for fraud.</p>

      <h2>18. OUR RIGHT TO VARY THESE TERMS AND CONDITIONS</h2>
      <p>18.1. We have the right to revise and amend these terms and conditions from time to time to reflect changes in market conditions affecting our business, changes in technology, changes in payment methods, changes in relevant laws and regulatory requirements and changes in our system&apos;s capabilities.</p>
      <p>18.2. You will be subject to the policies and terms and conditions in force at the time that you order products from us, unless any change to those policies or these terms and conditions is required to be made by law or governmental authority (in which case it will apply to orders previously placed by you), or if we notify you of the change to those policies or these terms and conditions before we send you the Dispatch Confirmation (in which case we have the right to assume that you have accepted the change to the terms and conditions, unless you notify us to the contrary within seven working days of receipt by you of the Products</p>

      <h2>19. LAW AND JURISDICTION</h2>
      <p>Contracts for the purchase of Products through our site and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) will be governed by English law. Any dispute or claim arising out of or in connection with such Contracts or their formation (including non-contractual disputes or claims) shall be subject to the non-exclusive jurisdiction of the courts of England and Wales.</p>
    </>
  )
}

export default TermsAndConditionsBody