import { FormatNumber } from 'components/format-number'

type RepresentationProps = {
  currentValue?: number
  previousValue?: number
  type: 'number'
}

export function Representation({ 
  currentValue, 
  type = 'number', 
}: RepresentationProps) {
  return (
    <dd className="mt-1">
      <div className="flex items-baseline text-2xl font-semibold text-primary">
        {
          type === 'number' && <FormatNumber value={currentValue as number} />
        }
      </div>
    </dd>
  )
}