import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { userPolicy } from 'helpers/policies/user'

export const useAuthorization = () => {
  const { authUser } = useSelector((state: RootState) => ({
    authUser: state.auth.user,
  }))

  const appPolicies = useMemo(
    () => ({ ...userPolicy }),
    [userPolicy],
  )

  const checkPermission = useCallback(
    (policy: keyof typeof appPolicies, model?: any) => {
      let hasAccess = false
      if (authUser) {
        hasAccess = appPolicies[policy]?.(authUser, model)
      }

      return hasAccess
    },
    [authUser, appPolicies],
  )

  return { checkPermission }
}