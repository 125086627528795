import { Outlet } from 'react-router-dom'

const AssetManageOutlet = () => {
  return (
    <div className='w-full h-full mx-auto'>
      <Outlet />
    </div>

  )
}

export default AssetManageOutlet