import Button from 'components/button'
import React from 'react'

type Props = {
  title: string
  description?: string
  backBtnText?: string
  backBtnUrl?: string
}

const FormHeader = ({ title, description, backBtnText, backBtnUrl }: Props) => {
  return (
    <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
      <div className="flex flex-col h-16 gap-1">
        <h1 className="text-2xl font-medium text-slate-800">
          {title}
        </h1>
        <p className="text-sm text-slate-400">{description}</p>
      </div>
      <Button href={backBtnUrl} variant="secondary" className="hidden lg:block">
        {backBtnText}
      </Button>
    </div>
  )
}

export default FormHeader