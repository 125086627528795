import Asset from '../api/types/models/asset'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AssetState {
  scanned_item: Asset | null
}

const initialState: AssetState = {
  scanned_item: null,
}

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setScannedAsset: (state, scannedAsset: PayloadAction<Asset>) => {
      state.scanned_item = scannedAsset.payload
    },
  },
})

export const {
  setScannedAsset,
} = assetSlice.actions

export default assetSlice.reducer