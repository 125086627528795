import dataApi from 'api'
import { CreateInviteRequest } from 'api/types/requests/admin/invite/create-invite'
import { VerifyAccountRequest } from 'api/types/requests/admin/invite/verify-account'

const inviteBaseUrl = '/auth/admin/invite'

const inviteEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    invite: build.mutation<void, CreateInviteRequest>({
      query: (body) => ({
        url: inviteBaseUrl,
        method: 'POST',
        body,
      }),
    }),

    resendInvite: build.mutation<void, { user_id: number }>({
      query: (body) => ({
        url: `${inviteBaseUrl}/resend`,
        method: 'POST',
        body,
      }),
    }),

    verifyAccount: build.mutation<void, VerifyAccountRequest>({
      query: (body) => ({
        url: `${inviteBaseUrl}/verify`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { 
  useInviteMutation,
  useResendInviteMutation,
  useVerifyAccountMutation,
} = inviteEndpoints