import { useAdminStoreThemeMutation } from 'api'
import FormHeader from 'components/admin/form_header'
import AdminThemeForm from 'components/admin/forms/theme'
import NotificationToast from 'components/notifications'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useNavigate } from 'react-router-dom'

const AdminThemeStorePage = () => {
  const navigate = useNavigate()
  const { notify } = useNotifier()

  const [submitStore, { data, error, isLoading }] = useAdminStoreThemeMutation()

  useEffect(() => {
    if (data) {
      notify(<NotificationToast message="Theme created successfully" />)
      navigate(`/admin/dashboard/themes/manage/${data.theme.id}`)
    }
  }, [data])

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Create Theme"
        description="Fill in the fields to create a new Theme"
        backBtnText="Back to Themes"
        backBtnUrl="/admin/dashboard/themes/browse"
      />
      <AdminThemeForm
        onSubmit={(data) => submitStore({ ...data })}
        onSubmitError={error}
        onSubmitLoading={isLoading}
      />
    </div>
  )
}

export default AdminThemeStorePage