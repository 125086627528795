import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAttachDeviceMutation, useDenyAssetRequestMutation, useStoreDeviceMutation } from 'api'
import { useParams, useSearchParams } from 'react-router-dom'
import Modal from 'components/modals'
import useErrorHandler from 'hooks/useErrorHandler'
import Button from 'components/button'
import Input from 'components/form/input'
import Phone, { PhoneInputProps } from 'components/form/phone'
import NotificationToast from 'components/notifications'
import DeviceRelationships from 'enums/DeviceRelationship'
import Dropdown from 'components/form/dropdown'
import Device from 'api/types/models/device'
import ComboBox, { SelectOption } from 'components/form/combobox'
import { useToast } from 'hooks/useToast'
import { HorizontalDivider } from 'components/Dividers/horizontal'
import { FormLabel } from 'components/form/label'
import Alert from 'components/alerts'

type FormInputs = {
  device_id?: number
  name: string
  country_code: string
  phone: string
  relationship: string
  masked_uid: string
  is_request: boolean
}

type ModalProps = {
  devices?: Device[]
  name?: string
  phone?: string
  countryCode?: string
  isRequest?: boolean
  isOpen: boolean
  onClose: () => void
}

const ListItem = ({ number, text }: { number: string, text: string }) => (
  <div className="flex flex-row items-center gap-3">
    <div className="flex max-w-[32px] h-[32px] w-full bg-secondary-blue rounded-full">
      <p className='mx-auto my-auto text-2xl text-white'>{number}</p>
    </div>
    <p className="text-sm">{text}</p>
  </div>
)

const AddDeviceModal = ({ isOpen, onClose, name, phone, countryCode, isRequest = false, devices }: ModalProps) => {
  const dispatch = useDispatch()
  const errorHandler = useErrorHandler()
  const params = useParams()
  const { notify } = useNotifier()
  const [searchParams, setSearchParams] = useSearchParams()

  const { errorToast, successToast } = useToast()

  const clearSearchParams = () => {
    searchParams.delete('name')
    searchParams.delete('phone')
    searchParams.delete('country_code')
    setSearchParams(searchParams)
  }

  const issueTypeOptions = (Object.entries(DeviceRelationships).map(([key, value]) => {
    return (
      <Dropdown.Item key={key} onClick={() => setValue('relationship', value)}>
        {value}
      </Dropdown.Item>
    )
  }))

  const [storeDevice, { data: storeDeviceResult, isLoading, error: storeDeviceError }] = useStoreDeviceMutation()
  const [attachDevice, { error: attachDeviceError }] = useAttachDeviceMutation()
  const [denyAccessRequest, { data: denyAccessResult, isLoading: isDenyAccessLoading, error: denyAccessError }] = useDenyAssetRequestMutation()

  const { register, handleSubmit, clearErrors, setValue, getValues, watch, reset, setError, formState: { errors } } = useForm<FormInputs>({
    defaultValues: {
      name: name,
      phone: phone,
      relationship: 'Other',
    },
  })

  const onSubmit = (data: FormInputs) => {
    data.masked_uid = params.uid!
    data.is_request = isRequest

    // Attach already existing device if selected
    if (data?.device_id) {
      attachDevice({
        device_id: data.device_id,
        masked_uid: data.masked_uid,
      })
        .unwrap()
        .then(() => {
          successToast({
            title: 'Successfully Given Access',
            message: 'Access provided to person successfully',
          })

          onClose()
        })
        .catch((err) => {
          if (err?.status === 422) return

          let errorMessage = 'We\'re unable to provide access to this person at this time. Please try again later.'
          if (err?.data?.type === 'custom') {
            errorMessage = err.data?.message
          }

          errorToast({
            title: 'Failed to Give Access',
            message: errorMessage,
          })
        })

      return
    }

    // Store device normally
    storeDevice({
      ...data,
    })
  }

  // Process attach device

  useEffect(() => {
    if (attachDeviceError) {
      errorHandler(attachDeviceError, setError)
    }
  }, [attachDeviceError])

  // Process store device

  useEffect(() => {
    if (storeDeviceError) {
      errorHandler(storeDeviceError, setError)
    }
  }, [storeDeviceError])

  useEffect(() => {
    if (storeDeviceResult) {
      notify(<NotificationToast message="Person added successfully" />)
      clearSearchParams()
      onClose()
      reset()
    }
  }, [dispatch, storeDeviceResult])

  // ---

  // Process deny access

  useEffect(() => {
    if (denyAccessError) {
      notify(<NotificationToast type='error' message="There was an error removing access for this person. Please try again later." />)
    }
  }, [denyAccessError])

  useEffect(() => {
    if (denyAccessResult){
      notify(<NotificationToast message="Access request rejected successfully" />)
      onClose()
    }
  }, [denyAccessResult])

  const handleDenyAssetRequest = () => {
    const data: FormInputs = getValues()
    denyAccessRequest({
      masked_uid: params.uid!,
      country_code: data.country_code,
      phone: data.phone,
    })
  }

  // ---

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone)
    setValue('country_code', phone.country_code)

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  const handleOnComboBoxBoxChange = (option: SelectOption | undefined) => {
    clearErrors('device_id')

    if (option === undefined) {
      return reset({
        device_id: undefined,
        name: '',
        relationship: 'Other',
        phone: '',
        country_code: '44',
      })
    }

    const device = devices!.find((device) => device.id === option.value)
    reset({
      ...getValues(),
      device_id: device!.id,
      name: device!.name,
      relationship: device!.relationship,
      country_code: device!.country_code,
      phone: device!.phone,
    })
  }

  const handleOnClose = () => {
    onClose()
    clearErrors()
    handleOnComboBoxBoxChange(undefined)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} mobilePadding beforeEnter={reset} containerClassName="max-w-xl mx-auto">
      <Modal.Header title="Add Person" description="Authorise someone for this KiCall" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          { isRequest
            ? <p>{name} has requested access to view this KiCall. Clicking &quot;accept request&quot; will add the following person to your list of people with access:</p>
            : <div className='flex flex-col gap-4'>
              <ListItem number="1" text={
                devices && devices.length > 0
                  ? 'Select or enter the details of the person you wish to give access to'
                  : 'Enter the mobile number and name of the person you wish to give access to'
              } />
              <ListItem number="2" text="They are ready to scan!" />
            </div>
          }
          <div className="flex flex-col gap-2 my-12">
            {
              devices && devices.length > 0 &&
              <>
                <FormLabel>Select an existing Person</FormLabel>
                <ComboBox
                  isClearable
                  placeholder="Search..."
                  options={devices.map((device) => ({ value: device.id, text: device.name, extra_text: `(${device.relationship})` }))}
                  onChange={(option) => handleOnComboBoxBoxChange(option)}
                  error={errors?.device_id?.message}
                  disabled={!!name && !!phone && !!countryCode} // disable if all params are present
                />
                <div className="flex items-center gap-4 md:my-5 my-3">
                  <HorizontalDivider /><p>OR</p><HorizontalDivider />
                </div>
              </>
            }
            <FormLabel>Create a new Person</FormLabel>
            <Input
              {...register('name')}
              label="Name"
              disabled={!!watch('device_id')}
              error={errors?.name?.message} />
            <Phone
              defaultPhone={phone || getValues('phone')}
              disabled={!!phone || !!watch('device_id')}
              defaultCountryCode={countryCode}
              onChange={(phone) => handlePhoneOnChange(phone)}
              error={errors?.phone?.message || errors?.country_code?.message}
            />
            <Dropdown
              itemClass="-translate-y-full lg:translate-y-0"
              {...register('relationship')}
              label="Relationship"
              trigger={<div>{watch('relationship') ?? 'Other'}</div>}
              error={errors?.relationship?.message}
              disabled={!!watch('device_id')}>
              {issueTypeOptions}
            </Dropdown>
            {
              devices && devices.length === 0 &&
              <Alert type="info" message="Looks like you have no people to select from at the moment, when you do, you can add them to other KiCalls using a selection box instead of typing the same details." />
            }
          </div>
        </Modal.Content>
        <Modal.Footer className="flex flex-row justify-end gap-2">
          { isRequest
            ? <>
              <Button variant="secondary" onClick={handleDenyAssetRequest} isLoading={isDenyAccessLoading}>Deny Request</Button>
              <Button type="submit" isLoading={isLoading}>Accept Request</Button>
            </>
            : <>
              <Button variant="secondary" onClick={handleOnClose}>Cancel</Button>
              <Button type="submit" isLoading={isLoading}>Add</Button>
            </>
          }
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddDeviceModal