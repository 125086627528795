import { Title } from './title'
import { cn } from 'helpers/styling'
import { PropsWithChildren } from 'react'
import { cva } from 'class-variance-authority'
import { Representation } from './representation'
import { Change } from './change'
import { Average } from './average'
import { Labelled } from './labelled'
import LabelGroup from './labelGroup'

const statCardStyling = cva('w-full border', {
  variants: {
    size: {
      sm: 'px-4 py-3 rounded-sm',
      md: 'px-6 py-4 rounded-md',
      lg: 'px-8 py-6 rounded-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type StatsCardProps = PropsWithChildren<{
  size?: 'sm' | 'md' | 'lg'
  className?: string
}>

export function StatCard({ 
  size,
  className,
  children, 
}: StatsCardProps) {
  return (
    <div className={cn(statCardStyling({ size }), className)}>
      {children}
    </div>
  )
}

StatCard.Title = Title
StatCard.Representation = Representation
StatCard.Average = Average
StatCard.Labelled = Labelled
StatCard.LabelGroup = LabelGroup
StatCard.Change = Change
