import AdminTable from 'components/admin/tables'
import { SkeletonBox } from 'components/skeletons/box'

export function CreditLogTableSkeletonRow() {
  return (
    <AdminTable.Row>
      <AdminTable.Column>
        <SkeletonBox className="h-5" randomWidth />
      </AdminTable.Column>

      <AdminTable.Column>
        <SkeletonBox className="h-5" randomWidth />
      </AdminTable.Column>

      <AdminTable.Column>
        <SkeletonBox className="h-5" randomWidth />
      </AdminTable.Column>

      <AdminTable.Column>
        <SkeletonBox className="h-5" randomWidth />
      </AdminTable.Column>

      <AdminTable.Column>
        <SkeletonBox className="h-5" randomWidth />
      </AdminTable.Column>
    </AdminTable.Row>
  )
}