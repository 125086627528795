enum AssetEvent {
  ACCESS_REQUEST = 'asset_access_request',
  ACCESS_REQUEST_APPROVED = 'asset_access_request_approved',
  ACCESS_REQUEST_REJECTED = 'asset_access_request_rejected',
  REGISTER = 'asset_register',
  SCAN_PRIVATE = 'asset_scan_private',
  SCAN = 'asset_scan',
  UPDATE = 'asset_update',
}

export default AssetEvent