import Button from 'components/button'
import Modal from 'components/modals'
import React from 'react'
import PrivacyAndCookiesBody from '../html/PrivacyAndCookies'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
}

const PrivacyAndCookiesModal = ({ isOpen, onClose }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Privacy & Cookies" />
      <Modal.Content>
        <div className="max-h-96 overflow-y-scroll informational-html pr-4 break-words">
          <PrivacyAndCookiesBody />
        </div>
      </Modal.Content>
      <Modal.Footer className="flex">
        <Button className="ml-auto" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PrivacyAndCookiesModal