import React from 'react'
import Spinner from '../../spinner'
import Body from './components/body'
import Column from './components/column'
import Head from './components/head'
import Heading from './components/heading'
import Row from './components/row'

// JSX element props
type Props = {
  children?: React.ReactNode
  isLoading?: boolean
  className?: string
}

const AdminTable = ({ children, isLoading = false, className }: Props) => {
  if (isLoading) {
    return (
      <div className={`flex justify-center ${className}`}>
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <table className={`w-full border-collapse table-auto ${className}`}>
      {children}
    </table>
  )
}

AdminTable.Body = Body
AdminTable.Column = Column
AdminTable.Head = Head
AdminTable.Heading = Heading
AdminTable.Row = Row

export default AdminTable