import Asset from 'api/types/models/asset'
import classNames from 'classnames'
import { Badge } from 'components/badge'
import Button from 'components/button'
import ArrowTopRightIcon from 'components/icons/ArrowTopRightIcon'
import ClockIcon from 'components/icons/ClockIcon'
import LockIcon from 'components/icons/LockIcon'
import PencilIcon from 'components/icons/PencilIcon'
import QrIcon from 'components/icons/QrIcon'
import UnlockIcon from 'components/icons/UnlockIcon'
import ToolTip from 'components/tooltip'
import { AssetType } from 'enums/AssetType'

type AssetListCardProps = {
  asset: Asset
}

const AssetListCard = ({
  asset,
}: AssetListCardProps) => {
  let params: string = '/'

  if (asset) {
    params += `?d=${asset.masked_uid}`

    if (asset?.type === AssetType.TAMO) {
      params += `&t=${asset?.type}`
    }
  }

  return (
    <div className="border-t py-2">

      <div className="flex justify-between items-center px-1 md:gap-4 gap-2 flex-wrap md:flex-nowrap">

        <div id={`privacy-indicator-${asset.id}`}>
          <ToolTip id={`privacy-indicator-${asset.id}`} content={asset.is_private ? 'Private' : 'Public'}>
            <div className={classNames('p-2 rounded-md', asset.is_private ? 'bg-red-100' : 'bg-blue-200')} >
              {
                asset.is_private
                  ? <LockIcon className='h-5 text-indicator-error' />
                  : <UnlockIcon className='h-5 text-primary' />
              }
            </div>
          </ ToolTip>
        </div>

        <div className="order-last flex items-center md:items-start md:justify-start justify-between md:flex-col md:order-none w-full max-h-9">
          <p className="font-medium capitalize leading-none">
            {asset.description ?? 'KiCall (Not Named)'}
          </p>
          <div className="md:mt-1">
            <Badge
              className="flex items-center gap-1"
              size="sm"
            >
              <QrIcon className="aspect-square h-4" />
              {(asset.total_scans || 0).toString().padStart(2, '0')}
            </Badge>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <Button isIconOnly icon={<ArrowTopRightIcon className="w-5 h-5" />} type="link" href={`/?d=${asset.masked_uid}`} variant="secondary" />
          <Button isIconOnly icon={<ClockIcon className='h-5' />} variant="secondary" href={`/asset/manage/${asset.masked_uid}/history`} />
          <Button isIconOnly icon={<PencilIcon className='h-5' />} type="link" href={`/asset/manage/${asset.masked_uid}`} />
        </div>

      </div>

    </div>
  )
}

export default AssetListCard