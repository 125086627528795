import classNames from 'classnames'
import Card from 'components/cards/default'
import { Title } from 'components/headings'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import EventLog from 'api/types/models/event_log'
import Button from 'components/button'
import QrIcon from 'components/icons/QrIcon'
import EyeIcon from 'components/icons/EyeIcon'
import CheckIcon from 'components/icons/CheckIcon'
import { useGetAssetEventsQuery } from 'api'
import PhoneUrl from 'components/phone_url'
import Spinner from 'components/spinner'
import InboxInIcon from 'components/icons/InboxInIcon'
import StarIcon from 'components/icons/StarIcon'
import AssetEvent from 'enums/AssetEvent'
import UserIcon from 'components/icons/UserIcon'
import ArrowIcon from 'components/icons/ArrowIcon'
import CrossIcon from 'components/icons/CrossIcon'
import Select, { components } from 'react-select'
import { useFilters } from 'hooks/useFilters'
import { useMemo, useState } from 'react'
import { TickIcon } from 'components/icons/TickIcon'
import ToolTip from 'components/tooltip'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type EventDeviceFilter = {
  devices: string[] | undefined
}

type DeviceListItem = {
  value: string,
  name?: string,
  label: string,
}[]

const AssetDashboardPage = () => {
  const params = useParams()
  const [deviceList, setDeviceList] = useState<DeviceListItem | undefined>(undefined)

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [{ debouncedFilters }, setFilters] = useFilters<EventDeviceFilter>({
    initialFilters: {
      devices: undefined,
    },
  })

  const { data: eventsData, isLoading: isEventsLoading } = useGetAssetEventsQuery({
    id: params.uid!,
    ...debouncedFilters,
  }, {
    refetchOnMountOrArgChange: true,
  })

  const formatPhoneNumber = (phone: string | undefined) => {
    if (phone === undefined) {
      return undefined
    }

    return ('+' + phone).trim()
  }

  useMemo(() => {
    if (eventsData?.eventLog && !deviceList) {
      const uniquePhones = new Set<string>()

      type AvailableFiltersType = {
        phone: string,
        name: string | undefined
      }[]

      let availableFilters: AvailableFiltersType = []

      eventsData.eventLog.map((event) => {
        const eventPhone = event?.phone?.trim()
        const userPhone = formatPhoneNumber(event.user?.full_phone_number)?.trim()
        const devicePhone = formatPhoneNumber(event.device?.full_phone_number)?.trim()

        const loggedInUserPhoneNumber = formatPhoneNumber(user?.full_phone_number)

        if (!eventPhone && !userPhone && !devicePhone) {
          return undefined
        }

        // Prioritise finding user phone ahead of others
        if (userPhone !== undefined && !uniquePhones.has(userPhone)) {
          uniquePhones.add(userPhone)
          availableFilters.push({ phone: userPhone, name: userPhone === loggedInUserPhoneNumber ? 'Me' : event.user.full_name })
        }

        // Secondly the device name
        if (devicePhone !== undefined && !uniquePhones.has(devicePhone)) {
          uniquePhones.add(devicePhone)
          availableFilters.push({ phone: devicePhone, name: event.device?.name })
        }

        // Then if there is no name at all
        if (eventPhone !== undefined && !uniquePhones.has(eventPhone)) {
          uniquePhones.add(eventPhone)
          const foundName = event.content?.name

          availableFilters.push({ phone: eventPhone, name: foundName ?? 'Unknown' })
        }

        return undefined
      }).filter((item) => item !== undefined)

      setDeviceList(availableFilters.map((event) => ({
        value: event!.phone,
        name: event!.name,
        label: `${event!.phone}${event!.name ? ' (' + event!.name + ')' : ''}`,
      })))
    }
  }, [eventsData])

  const Item = ({ event }: { event: EventLog }) => {
    const itemVariants = classNames('bg-gray-100 text-gray-600', {
      'bg-blue-100 text-blue-600 border-blue-500': event.description === AssetEvent.SCAN,
      'bg-red-100 text-red-600 border-red-500': event.description === AssetEvent.SCAN_PRIVATE || event.description === AssetEvent.ACCESS_REQUEST_REJECTED,
      'bg-green-100 text-green-600 border-green-500': event.description === AssetEvent.ACCESS_REQUEST || event.description === AssetEvent.ACCESS_REQUEST_APPROVED,
      'bg-yellow-100 text-yellow-600 border-yellow-500': event.description === AssetEvent.REGISTER,
    })

    let description: string
    let error: string | undefined = 'Error'
    let accessRequestName: string | undefined
    let icon: JSX.Element
    switch (event.description) {
      case AssetEvent.SCAN:
        description = 'KiCall Scanned'
        icon = <QrIcon className="w-4 h-4 m-auto" />
        break
      case AssetEvent.SCAN_PRIVATE:
        description = 'KiCall Accessed'
        error = 'Failed Attempt'
        icon = <EyeIcon className="w-4 h-4 m-auto" />
        break
      case AssetEvent.UPDATE:
        description = 'KiCall Updated'
        icon = <CheckIcon className="w-4 h-4 m-auto" />
        break
      case AssetEvent.ACCESS_REQUEST:
        description = 'KiCall Request Received'
        accessRequestName = event.content?.name
        icon = <InboxInIcon className="w-4 h-4 m-auto" />
        break
      case AssetEvent.ACCESS_REQUEST_APPROVED:
        description = 'KiCall Request Approved'
        icon = <CheckIcon className="w-4 h-4 m-auto" />
        break
      case AssetEvent.ACCESS_REQUEST_REJECTED:
        description = 'KiCall Request Rejected'
        icon = <CrossIcon className="w-4 h-4 m-auto" />
        break
      case AssetEvent.REGISTER:
        description = 'KiCall Registered'
        icon = <StarIcon className="w-4 h-4 m-auto" />
        break
      default:
        description = event.description.replace('_', ' ').replace('asset', 'KiCall')
        icon = <div className="m-auto">?</div>
        break
    }

    return (
      <div className="relative pb-6">
        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
        <div className="relative flex space-x-3">
          <div className={classNames('h-8 w-8 rounded-full flex items-center justify-center', itemVariants)}>
            {icon}
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="lg:text-sm text-xs">
              <span className="inline font-medium capitalize">{description} </span>
              <span className="inline text-gray-400">{DateTime.fromISO(event.created_at.toString()).toRelative()}</span>
            </div>
            <div className="flex flex-wrap gap-1">
              <p className="border border-gray-300 rounded-lg px-2 text-xs max-w-min">
                {event.ip}
              </p>
              {
                event.phone &&
                <PhoneUrl className="border border-gray-300 rounded-lg px-2 text-xs max-w-min" phone={event.phone} includePlus={false} />
              }
              {
                event?.device?.name &&
                <div className="flex border border-gray-300 rounded-lg px-2 text-xs items-center">
                  <UserIcon className="w-3 h-3 inline-flex mr-1" />
                  <span>{event?.device?.name}</span>
                </div>
              }
              {
                accessRequestName &&
                <ToolTip id={`access-request-name-${event.id}`} content="Name provided on access request">
                  <div className="flex border border-gray-300 rounded-lg px-2 text-xs items-center">
                    <UserIcon className="w-3 h-3 inline-flex mr-1" />
                    <span>{accessRequestName}</span>
                  </div>
                </ToolTip>
              }
              {
                !event?.is_success &&
                <p className="border border-gray-300 text-xs px-2 rounded-lg text-red-600">{error}</p>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card className="relative">
      <Button icon={<ArrowIcon className="w-5 h-5" />} isIconOnly href="/asset/manage/list" type="button" className="mr-auto text-sm" variant="secondary"></Button>

      <Title className="mt-6" align="left">History</Title>
      <p className="text-sm max-w-sm">This page shows the recent interactions with your KiCall.</p>

      <div className="flex flex-col gap-2 mt-3 mb-12">
        <div className="flex flex-col flex-wrap gap-2 mb-2 lg:text-sm text-xs">

          <div className="flex flex-wrap gap-2">
            <p className="align-middle p-1 bg-blue-100 rounded text-blue-600 font-medium">
              Scan Count: {eventsData?.statistics.scans.recent}{` (${eventsData?.statistics.scans.total})`}
            </p>
            <p className="align-middle p-1 bg-red-100 rounded text-red-600 font-medium">
              Access Count: {eventsData?.statistics.accessed.recent}{` (${eventsData?.statistics.accessed.total})`}
            </p>
          </div>

          <Select
            isClearable
            isMulti
            isSearchable={false}
            isLoading={isEventsLoading}
            classNamePrefix="react-select"
            placeholder={`Filter by phone (${debouncedFilters.devices?.length || 0} selected)`}
            className="react-select-container"
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            onChange={(options) => {
              setFilters({
                devices: options.map((option) => (option.value)) ?? undefined,
              })
            }}
            options={deviceList}
            components={{
              Option: IconOption,
            }}
          />

        </div>

        <div className="flex max-h-96 overflow-y-auto border-y-2 border-gray-200 py-2">
          <div className="flow-root max-w-sm ml-4 min-h-min">
            {
              eventsData ?
                <ul role="list" className="pr-2">
                  { eventsData?.eventLog?.map((event, id) => (
                    <li key={id}>
                      <Item event={event} />
                    </li>
                  ))}
                </ul>
                : <Spinner className="text-primary-gray" />
            }
          </div>
        </div>
      </div>
    </Card>
  )
}

const IconOption = (props: any) => (
  <components.Option {...props}>
    <div className="flex gap-2 items-center">
      {props.isSelected && <TickIcon className='h-6' />}
      {props.data.label}
    </div>
  </components.Option>
)

export default AssetDashboardPage