import classNames from 'classnames'
import { useState } from 'react'
import ChevronIcon from '../icons/ChevronUpIcon'

type Props = {
  onPage: number,
  onPageChange: (page: number) => void
  maxPages?: number
  totalPages: number
}

const Pagination = ({ onPage, totalPages, onPageChange, maxPages = 4 }: Props) => {
  const [currentPageGroup, setCurrentPageGroup] = useState<number>(0)

  const allPages = Array(totalPages).fill(null).map((_, i) => i + 1)
  const groupedPages = new Array(maxPages).fill(null).map((_, i) => allPages.slice(i * maxPages, (i + 1) * maxPages)).filter(group => group.length > 0)

  const handlePageChange = (pageNum: number) => {
    onPageChange(pageNum)
  }

  const incrementPageGroup = () => {
    let num = currentPageGroup
    if (num === groupedPages.length - 1) return

    setCurrentPageGroup(num + 1)
  }

  const decrementPageGroup = () => {
    let num = currentPageGroup
    if (num === 0) return

    setCurrentPageGroup(num - 1)
  }

  const ChevronListItem = ({children, isIncrement = true}: {children: JSX.Element, isIncrement?: boolean}) => {
    return (
      <button className={classNames('flex w-8 h-8 border rounded-md cursor-pointer border-secondary-gray text-primary-gray focus:outline-secondary-blue', {
        'pointer-events-none cursor-default bg-disabled': (isIncrement ? currentPageGroup === groupedPages.length - 1 : currentPageGroup === 0 ) || (totalPages === 0),
      })} onClick={() => isIncrement ? incrementPageGroup() : decrementPageGroup()}>
        {children}
      </button>
    )
  }

  return (
    <ul className="flex gap-2">
      <li>
        <ChevronListItem isIncrement={false}>
          <ChevronIcon className='w-4 h-4 m-auto rotate-90' />
        </ChevronListItem>
      </li>
      {
        groupedPages[currentPageGroup]?.map((page, index) => (
          <li key={page} >
            <button type="button" className={classNames('transition text-sm flex w-8 h-8 border rounded-md cursor-pointer border-secondary-gray focus:outline-secondary-blue', {
              'border-none bg-secondary-blue hover:bg-secondary-dark-blue text-white': onPage === page,
              'text-primary-gray': onPage !== page,
            })} onClick={() => handlePageChange(page)}>
              <p className="m-auto">{page}</p>
            </button>
          </li>
        ))
      }
      <li>
        <ChevronListItem>
          <ChevronIcon className='w-4 h-4 m-auto -rotate-90' />
        </ChevronListItem>
      </li>
    </ul>
  )
}

export default Pagination