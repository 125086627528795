import { useEffect, useMemo } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import Modal from '../..'
import { useDeleteDeviceMutation } from '../../../../api'
import Device from '../../../../api/types/models/device'
import Button from '../../../button'
import NotificationToast from '../../../notifications'
import Asset from 'api/types/models/asset'

type FormInputs = {
  id: number
}

type ModalProps = {
  asset?: Asset
  device: Device | undefined
  isOpen: boolean
  onClose: () => void
}

const RemoveDeviceModal = ({ asset, device, isOpen, onClose }: ModalProps) => {
  const { notify } = useNotifier()

  const [deleteDevice, { isSuccess: isDeviceDeleted, isLoading }] = useDeleteDeviceMutation()
  const { handleSubmit, setValue, reset } = useForm<FormInputs>()

  const onSubmit = () => {
    if (device) {
      deleteDevice(device.id)
    }
  }

  useMemo(() => {
    if (device) {
      setValue('id', device.id)
    }
  }, [device])

  useEffect(() => {
    if (isDeviceDeleted && device) {
      notify(<NotificationToast message="Person removed successfully" />)
      onClose()
    }
  }, [isDeviceDeleted])

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding beforeEnter={reset}>
      <Modal.Header title="Remove Person" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <div className="space-y-4">
            <p>Are you sure you want to remove this person?</p>
            <p><span className="font-semibold">{device?.name}</span> will be removed from your list of available people, and their access to any KiCalls revoked.</p>
          </div>
        </Modal.Content>
        <Modal.Footer className="flex flex-row justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button variant="danger" type="submit" isLoading={isLoading}>Remove</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default RemoveDeviceModal