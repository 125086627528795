import React from 'react'

const ModalHeader = ({ title, description }: { title: string, description?: string, className?: string }): JSX.Element => {
  return (
    <div className="px-6 py-4 border-b rounded-b-sm">
      <p className="text-lg font-semibold text-gray-900 pr-5">{title}</p>
      {
        description &&
        <p className="text-gray-400 text-sm">{description}</p>
      }
    </div>
  )
}

export default ModalHeader
