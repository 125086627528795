import dataApi from 'api'
import { CreditBlock } from 'api/types/models/credit_block'
import PaginatedResponse from 'api/types/requests/PaginatedResponse'
import GetCreditBlocksRequest from 'api/types/requests/credit_block/list'

const creditBlockBaseUrl = '/admin/credit-blocks'

const creditBlockEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    getCreditBlocks: build.query<PaginatedResponse<CreditBlock[]>, GetCreditBlocksRequest>({
      query: (params) => ({
        url: creditBlockBaseUrl,
        params,
      }),
      providesTags: ['credit_blocks'],
    }),

    createCreditBlock: build.mutation<CreditBlock, Omit<CreditBlock, 'id'>>({
      query: (body) => ({
        url: creditBlockBaseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['credit_blocks', 'clients', 'client'],
    }),
  }),
})

export const { 
  useGetCreditBlocksQuery,
  useCreateCreditBlockMutation,
} = creditBlockEndpoints