import { Labelled } from './labelled'
import { StatCard } from './card'

type AverageProps = {
  min?: number
  max?: number
  average?: number
}

export function Average({   
  min,
  max,
  average,
}: AverageProps) {
  return (
    <StatCard.LabelGroup>
      <Labelled label="Average" value={average ?? null} color="primary" />
      <Labelled label="Minimum" value={min ?? null} color="green-600" />
      <Labelled label="Maximum" value={max ?? null} color="red-600" />
    </StatCard.LabelGroup>
  )
}