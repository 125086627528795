import AssetImportError from 'enums/AssetImportError'

export const getAssetImportErrorString = (text: string) => {
  let message: string

  switch (text) {
    case AssetImportError.BAD_URL:
      message = 'This asset URL is incorrect, make sure it starts with https:// or http://.'
      break
    case AssetImportError.DUPLICATE_ENTRY:
      message = 'This asset URL uses a key which already exists on the database and cannot be duplicated.'
      break
    case AssetImportError.DUPLICATE_UNMASKED_ENTRY:
      message = 'This asset URL uses a key which when decrypted is identical to a pre-existing key.'
      break
    case AssetImportError.DUPLICATE_IMPORT:
      message = 'This asset URL attempted to imported more than once, duplicates have been prevented from being created.'
      break
    case AssetImportError.KEY_NOT_FOUND:
      message = 'This asset URL is missing a key, specify key using the ?d= parameter.'
      break
    default:
      message = 'Error Unknown'
      break
  }

  return message
}