import FormLayout from 'components/admin/forms/layouts/FormLayout'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { PropsWithChildren } from 'react'
import { FormBaseProps } from 'types/form'
import { z } from 'zod'

const inviteSchema = z.object({
  email: z.string().email({ message: 'Email address is required' }),
})

export type InviteFormInputs = z.infer<typeof inviteSchema>

type InviteFormProps = PropsWithChildren<FormBaseProps<InviteFormInputs>>

export function InviteForm({ 
  onSubmit,
  errors,
  defaultValues, 
  children, 
}: InviteFormProps) {
  return (
    <FormLayout>
      <Form<InviteFormInputs, typeof inviteSchema>
        error={errors}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        validationSchema={inviteSchema}
      >
        {({ register, formState: { errors } }) => (
          <>
            <FormLayout.Block>
              <FormLayout.Title
                title="Details"
                subtitle="Enter the details of the admin you'd like to invite"
              />

              <FormLayout.Group label="Email Address" htmlFor="email">
                <Input 
                  type="email"
                  placeholder="Enter email address"
                  error={errors.email?.message}
                  {...register('email')}
                />
              </FormLayout.Group>

              {children}
            </FormLayout.Block>
          </>
        )}
      </Form>
    </FormLayout>
  )
}