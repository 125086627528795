import { useState, useEffect } from 'react'
import { Combobox } from '@headlessui/react'
import ChevronIcon from 'components/icons/ChevronUpIcon'
import Spinner from 'components/spinner'
import FormError from 'components/error'

export type SelectOption = {
  value: string | number
  text: string // queryable
  extra_text?: string // non-queryable
}

type PropTypes = {
  label?: string
  onChange?: (option: SelectOption | undefined) => void
  onTextChange?: (text: string) => void
  options?: SelectOption[] | []
  defaultValue?: string | number
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  error?: string
  isClearable?: boolean
}

const ComboBox = ({
  isClearable = false,
  label,
  onChange = () => {},
  onTextChange = () => {},
  options,
  defaultValue,
  placeholder,
  disabled = false,
  isLoading = false,
  error,
}: PropTypes) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>()
  const [query, setQuery] = useState<string>('')

  const filteredOptions =
    query === ''
      ? options
      : options && options.filter((option) => {
        return option.text.toLowerCase().includes(query.toLowerCase())
      })

  useEffect(() => {
    if (query) {
      onTextChange(query)
    }
  }, [query])

  useEffect(() => {
    if (defaultValue && options) {
      setSelectedOption(options?.find((option) => option.value === defaultValue))
    }
  }, [defaultValue, options])

  return (
    <div className={`${disabled ? 'opacity-50' : ''} flex-1`}>
      {
        label &&
        <p>{label}</p>
      }
      <Combobox
        value={selectedOption}
        disabled={disabled}
        onChange={(option) => {
          // Allow clearable option by selecting the already chosen option
          // Returning undefined complicates types across app
          if (option?.value === selectedOption?.value) {
            if (isClearable) {
              setSelectedOption(undefined)
              onChange(undefined)
              return
            }
          }
          setSelectedOption(option)
          onChange(option!)
        }
        }>
        {({ open }) => (
          <div className="relative">
            <Combobox.Input
              className={`transition text-base w-full p-2 ${open ? 'transition rounded-t-lg' : 'outline-primary'} ${filteredOptions && filteredOptions.length === 0 ? 'rounded-lg' : ''} border border-secondary-gray disabled:bg-white rounded-md`}
              displayValue={(option: SelectOption) => option?.text}
              placeholder={placeholder}
              onChange={(event) => setQuery(event.target.value)}
              autoComplete="off"
            />
            {
              isLoading &&
              <div className="absolute inset-y-0 right-0 flex items-center mr-[3.875rem]">
                <Spinner className="!text-primary-gray" />
              </div>
            }
            {
              (options && options.length > 0) &&
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center mr-[1.5625rem]">
                <ChevronIcon
                  className={`transition h-4 w-4 text-primary ${open ? '' : 'rotate-180'}`}
                />
              </Combobox.Button>
            }

            <Combobox.Options className="absolute z-50 w-full pt-2 overflow-auto text-base shadow-md cursor-pointer max-h-60 focus:outline-none">
              {
                filteredOptions && filteredOptions.map((option: SelectOption) => {
                  return (
                    <Combobox.Option
                      className={({ active }) =>
                        `relative -mt-[0.0625rem] border border-t border-b last:border-b px-3 py-4 last:rounded-b-lg first:rounded-t-lg ${active ? 'bg-primary text-white' : 'bg-white'}`
                      }
                      key={option.value}
                      value={option}>
                      {option.text}
                      {
                        option.extra_text &&
                          ' ' + option.extra_text
                      }
                    </Combobox.Option>
                  )
                })
              }
            </Combobox.Options>
          </div>
        )
        }
      </Combobox>
      {
        error &&
        <FormError className="mt-1 !text-left" text={error} />
      }
    </div>
  )
}

export default ComboBox