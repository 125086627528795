import classNames from 'classnames'
import { ChangeEvent, FocusEventHandler, forwardRef, HTMLInputTypeAttribute, useState } from 'react'

type TextAreaProps = {
  disabled?: boolean
  defaultValue?: string,
  error?: string
  label?: string,
  name?: string,
  onBlur?: FocusEventHandler,
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  required?: boolean
  placeholder?: string
  value?: string,
  className?: string,
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function Input ({
  disabled = false,
  defaultValue,
  error,
  label,
  name,
  onBlur,
  onChange,
  required = false,
  placeholder,
  value,
  className,
}, ref) {

  const innerClassNames = classNames('min-h-[96px] border-secondary-gray text-primary-gray border rounded-b-md leading-6 w-full p-3', {
    'bg-disabled text-disabled-dark': disabled,
    'rounded-t-md': !label,
  })

  return (
    <div className={classNames('flex flex-col' && error, 'w-full')}>
      <div className={classNames('flex flex-col')}>
        {
          label !== undefined &&
          <span className="items-center px-3 py-2 border border-b-0 border-secondary-gray text-primary-gray rounded-t-md bg-gray-50">
            {label}
          </span>
        }
        <textarea
          className={classNames(innerClassNames, className)}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          ref={ref}
          value={value}
          required={required}
        />
      </div>
      {
        error &&
        <p className='mt-1 text-xs font-medium text-red-500 whitespace-pre-wrap'>
          {error}
        </p>
      }
    </div>
  )
})

export default TextArea