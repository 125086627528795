import { HorizontalDivider } from 'components/Dividers/horizontal'
import Button from 'components/button'
import { FormControl } from 'components/form/control'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { Title } from 'components/headings'
import { z } from 'zod'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMessagesQuery } from 'api/endpoints/user/message'
import { DefaultMessage } from 'feature/message/components/items/default-message'
import { TimedMessage } from 'feature/message/components/items/timed-message'
import { Transition } from '@headlessui/react'
import Card from 'components/cards/default'
import TextArea from 'components/form/textarea'
import LockIcon from 'components/icons/LockIcon'
import { useEffect, useMemo, useState } from 'react'
import PhoneUrl from 'components/phone_url'
import { RadioCardGroup } from 'components/form/radio_card_group'
import { RadioCardOption } from 'components/form/radio_card'
import { FormBaseProps } from 'types/form'
import Asset from 'api/types/models/asset'
import { Controller, UseFormReturn } from 'react-hook-form'
import Select from 'react-select'
import { DistributionUsage } from 'enums/DistributionUsage'

const assetDetailSchema = z.object({
  description: z.string().min(1, { message: 'Name is required' }).optional(),
  is_private: z.boolean().optional(),
  public_message: z.string().optional(),
  distribution_usage: z.string().optional().nullable(),
})

export type AssetDetailFormInputs = z.infer<typeof assetDetailSchema>

type AssetDetailFormProps = FormBaseProps<AssetDetailFormInputs> & {
  asset: Asset | undefined
  onDirty: (isDirty: boolean) => void
  isSuccess?: boolean,
}

const usageOptions = [
  { label: 'Sharing a code', value: DistributionUsage.CODE },
  { label: 'Sharing information', value: DistributionUsage.INFORMATION },
]

function FormWrapper({
  asset,
  register,
  onDirty,
  watch,
  control,
  formState: { errors, isDirty },
}: UseFormReturn<AssetDetailFormInputs> & { asset: Asset | undefined, onDirty: (isDirty: boolean) => void }) {
  const { uid } = useParams()
  const navigate = useNavigate()

  const { data: messages } = useGetMessagesQuery({ asset_identifier: uid! }, { skip: !uid })

  useEffect(() => {
    if (isDirty) {
      onDirty(isDirty)
    }
  }, [isDirty])

  const orderedDevices = useMemo(() => {
    const devices = [...asset?.devices ?? []]
    return devices?.sort((a, b) => Number(b.is_asset_owner) - Number(a.is_asset_owner))
  }, [asset])

  return (
    <div className="flex flex-col gap-4">
      <FormControl className='flex flex-col gap-2'>
        <Title
          size="lg"
          tooltipId='details-name-label'
          tooltip='Identify your KiCall'
        >
          Name
        </Title>
        <Input
          id="name"
          {...register('description', { required: true })}
          placeholder="Enter a name for this asset"
          error={errors?.description?.message}
        />
      </FormControl>

      <HorizontalDivider />

      {/* Messages */}
      <div className='flex flex-col gap-2'>
        <Title
          className="!text-left !mb-0"
          size="lg"
          tooltip='Configure up to four messages to display different information at varying times of the day or week'
          tooltipId='messages-form-tooltip'
        >
          Messages
        </Title>

        <div className="space-y-2">
          {
            !messages?.length && (
              <p className="py-1 text-sm text-center">This asset has no messages</p>
            )
          }

          {
            messages?.map(message =>
              message.is_default
                ? <DefaultMessage
                  key={message.id}
                  to={`${location.pathname}/messages/${message.id}`}
                  message={message}
                />
                : <TimedMessage
                  key={message.id}
                  to={`${location.pathname}/messages/${message.id}`}
                  message={message}
                  className="min-h-[56px]"
                />
            )
          }
        </div>

        <Button
          block
          onClick={() => navigate(`/asset/manage/${uid}/messages`)}
          disabled={(messages?.length ?? 0) >= (asset?.message_limit ?? 0)}
        >
          <div className="flex items-center gap-1">
            <span>Add Message</span>
            {
              asset && messages &&
              <span>({messages?.length ?? 0} / {asset?.message_limit ?? 0})</span>
            }
          </div>
        </Button>
      </div>

      <HorizontalDivider />

      {/* Visibility */}
      <div className='flex flex-col gap-2'>
        <Title
          size="lg"
          tooltip={'Setup your KiCall to be publicly available or privately accessed'}
          tooltipId='visibility-form-tooltip'
        >
          Visibility
        </Title>

        <RadioCardGroup name="is_private" control={control}>
          <RadioCardOption value={false} first>Everyone</RadioCardOption>
          <RadioCardOption value={true} last>Certain People</RadioCardOption>
        </RadioCardGroup>

        <Transition
          show={watch('is_private')?.toString() === 'true'}
          className="w-full space-y-6"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <div>
            <Card className="flex flex-col overflow-y-auto divide-y rounded-b-none max-h-[350px] !p-0">
              {
                !orderedDevices?.length && (
                  <p className="py-1 text-sm text-center">
                    This asset has no registered devices
                  </p>
                )
              }

              {
                orderedDevices?.map(device => (
                  <div key={device.id} className="flex items-center justify-between px-4 py-3 text-sm lg:text-base">
                    <div className="flex flex-col flex-grow">
                      <p>{device.name}, {device.relationship}</p>
                      <PhoneUrl phone={device.full_phone_number} />
                    </div>
                    {device.is_asset_owner && <LockIcon className="w-4 h-4 mr-2" />}
                  </div>
                ))
              }
            </Card>
            <Button
              block
              className="rounded-t-none"
              href={`${location.pathname}/access`}
            >
              Manage People
            </Button>
          </div>

          <TextArea
            {...register('public_message')}
            label="Public Message"
            error={errors?.public_message?.message}
            placeholder="Enter a message that will be displayed to the public"
          />
        </Transition>
      </div>

      <HorizontalDivider />

      {/* Other */}
      <div className='flex flex-col gap-2'>
        <div>
          <Title
            className="!text-left !mb-0"
            size='md'
            tooltipId='distribution-usage-label'
            tooltip='Optionally define what this KiCall is used for'
          >
            Usage
          </Title>
          <p className="text-sm text-gray-500">

          </p>
        </div>

        <Controller
          name="distribution_usage"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable
              onChange={(option) => field.onChange(option?.value ?? null)}
              options={usageOptions}
              placeholder="Select Usage"
              value={usageOptions.find((option) => option.value == field.value)}
            />)
          }
        />
      </div>
    </div>
  )
}

export function AssetDetailForm({
  asset,
  onSubmit,
  errors,
  onDirty,
  isLoading,
  isSuccess,
  defaultValues,
}: AssetDetailFormProps) {
  return (
    <>
      <Form<AssetDetailFormInputs, typeof assetDetailSchema>
        onSubmit={onSubmit}
        validationSchema={assetDetailSchema}
        error={errors}
        defaultValues={defaultValues}
      >
        {(methods) => (
          <>
            <FormWrapper
              onDirty={onDirty}
              asset={asset}
              {...methods}
            />

            <Button
              type="submit"
              isSuccess={isSuccess}
              successText='Asset updated'
              className="!mt-6"
              isLoading={isLoading}
              block
            >
              Update Details
            </Button>
          </>
        )}
      </Form>
    </>

  )
}