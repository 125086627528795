import ChevronIcon from 'components/icons/ChevronUpIcon'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { forwardRef } from 'react'

export type OptionProps = {
  value: string | number
  alt?: string
}

type Props = {
  name?: string
  options?: OptionProps[]
  defaultValue?: string | number
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const ListBox = forwardRef<HTMLSelectElement, Props>(function ListBox({
  name,
  options,
  defaultValue,
  onChange = () => {},
  onBlur,
}, ref) {
  const [value, setValue] = useState<string|number|undefined>(defaultValue)

  // Re render default value
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value)
    onChange(e)
  }

  return (
    <div className="w-full relative">
      <div className="absolute inset-y-0 right-0 flex items-center mr-3">
        <ChevronIcon className="w-4 h-4" />
      </div>
      <select
        value={value}
        ref={ref}
        name={name}
        onChange={(event) => handleOnChange(event)}
        onBlur={onBlur}
        className="w-full rounded-md border border-secondary-gray p-2 text-primary-gray focus:outline-primary focus:ring-bg-primary cursor-pointer appearance-none"
        defaultValue={defaultValue}
      >
        {
          options && options.map((option, idx) => (
            <option key={`asset-theme-${idx}`} value={option.value}>{option.alt ?? option.value}</option>
          ))
        }
      </select>
    </div>
  )
})

export default ListBox