import { useNavigate } from 'react-router-dom'
import { useAdminStoreUserMutation } from 'api'
import AdminUserForm, { UserFormInputs } from 'components/admin/forms/user'
import FormHeader from 'components/admin/form_header'
import { useToast } from 'hooks/useToast'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

const AdminUserCreatePage = () => {
  const navigate = useNavigate()

  const { successToast, errorToast } = useToast()

  const [createUser, {
    error: createdUserError,
    isLoading: isCreatingUser,
  }] = useAdminStoreUserMutation()

  const handleSubmit = async (inputs: UserFormInputs) => {
    await createUser({
      client_id: inputs.client_id,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      phone: inputs.phone,
      country_code: inputs.country_code,
      street_address: inputs.street_address,
      second_line: inputs.second_line,
      city: inputs.city,
      county: inputs.county,
      country: inputs.country,
      postcode: inputs.postcode,
    })
      .unwrap()
      .then((createdUser) => {
        navigate(`/admin/dashboard/users/manage/${createdUser.id}`)
        successToast({
          title: 'User Created',
          message: 'User has been created successfully.',
        })
      })
      .catch((err) => {
        if (err?.status === 422) return

        errorToast({
          title: 'User Creation Failed',
          message: 'Unable to create user, please try again later.',
        })
      })
  }

  const fetchError = (createdUserError as FetchBaseQueryError)

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Create User"
        description="Fill in the fields below to create a new User"
        backBtnText="Back to Users"
        backBtnUrl="/admin/dashboard/users/browse"
      />

      <AdminUserForm
        type="create"
        btnTxt="Create User"
        onSubmit={handleSubmit}
        defaultValues={{
          client_id: null,
          first_name: '',
          last_name: '',
          email: '',
          country_code: '44',
          phone: '',
          street_address: '',
          second_line: '',
          city: '',
          county: '',
          country: '',
          postcode: '',
        }}
        isLoading={isCreatingUser}
        errors={fetchError?.status === 422 ? fetchError : undefined}
      />
    </div>
  )
}

export default AdminUserCreatePage