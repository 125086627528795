import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { Banner } from 'components/banner'
import Button from 'components/button'
import Modal from 'components/modals'

type RequestAccessViewProps = {
  onSubmit: () => void
  onClose: () => void
  queryErrors: FetchBaseQueryError | SerializedError | undefined
  isLoading: boolean
}

export function RequestAccessView({
  onSubmit,
  onClose,
  queryErrors,
  isLoading,
}: RequestAccessViewProps) {
  return (
    <>
      <Modal.Content>
        {
          (queryErrors as FetchBaseQueryError)?.status === 429 &&
          <Banner intent="error" className="mb-6">
            <p>Too many unsuccessful attempts! Please try again in a few minutes.</p>
          </Banner>
        }

        <p>You do not have access to view this owners information.</p>
        <br />
        <p>When you request access, the owner will receive an OTP that is valid for 48 hours for you to enter.</p>
        <br />
        <p>Once verified, you can manage the owners information, including KiCalls for 15 minutes.</p>
      </Modal.Content>

      <Modal.Footer>
        <div className="flex justify-between gap-2">
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Request Access
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
}