import classNames from 'classnames'

type SkeletonBoxProps = {
  minWidth?: number
  randomWidth?: boolean
  className?: string
}

export function SkeletonBox({ minWidth = 20, randomWidth, className }: SkeletonBoxProps) {

  let style = {}
  if(randomWidth) {
    style = {
      width: `${Math.random() * (100 - minWidth) + minWidth}%`, 
    }
  }

  return (
    <div 
      style={style} 
      className={classNames('bg-gray-200 rounded animate-pulse', className)}
    ></div>
  )
}