import { User } from 'api/types/models/user'
import { UserRole } from 'enums/UserRole'

export const userPolicy = {
  'user:invite': (authUser: User) => {
    if(!authUser) {
      return false
    }

    if(authUser?.role !== UserRole.SUPER_ADMIN) {
      return false
    }

    return true
  },

  'user:update': (authUser: User, user: User) => {
    if(!authUser || !user) {
      return false
    }

    if(authUser?.id === user?.id) {
      return true
    }

    // Owners can only update themselves
    if(authUser?.role === UserRole.OWNER) {
      return false
    }

    // Admin cannot update other admins
    if(authUser?.role === UserRole.ADMIN && user.role === UserRole.ADMIN) {
      return false
    }

    // Admin cannot update super admins
    if(authUser?.role === UserRole.ADMIN && user.role === UserRole.SUPER_ADMIN) {
      return false
    }

    // Super admin cannot update another super admin
    if (authUser?.role === UserRole.SUPER_ADMIN && user.role === UserRole.SUPER_ADMIN) {
      return false
    }

    return true
  },

  'user:delete': (authUser: User, user: User) => {
    if(!authUser || !user) {
      return false
    }

    if(!authUser?.is_admin) {
      return false
    }

    // Admins cannot delete themselves
    if(authUser?.id === user.id) {
      return false
    }

    // Admin cannot delete other admins
    if(authUser?.role === UserRole.ADMIN && user.role === UserRole.ADMIN) {
      return false
    }

    // Admin cannot delete super admins
    if(authUser?.role === UserRole.ADMIN && user.role === UserRole.SUPER_ADMIN) {
      return false
    }

    // Super admin cannot delete another super admin
    if(authUser?.role === UserRole.SUPER_ADMIN && user.role === UserRole.SUPER_ADMIN) {
      return false
    }

    return true
  },
}