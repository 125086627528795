import { useAdminStoreFilesMutation } from 'api'
import FormHeader from 'components/admin/form_header'
import AdminFileForm from 'components/admin/forms/file'
import NotificationToast from 'components/notifications'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useNavigate } from 'react-router-dom'

const AdminFileStorePage = () => {
  const { notify } = useNotifier()
  const navigate = useNavigate()

  const [submitStore, { data, error, isLoading }] = useAdminStoreFilesMutation()

  useEffect(() => {
    if (data) {
      notify(<NotificationToast message="User files uploaded successfully" />)
      navigate(`/admin/dashboard/files/manage/${data.files[0].user_id}`)
    }
  }, [data])

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Upload User Files"
        description="Fill in the fields to create new files for a user"
        backBtnText="Back to Files"
        backBtnUrl="/admin/dashboard/files/browse"
      />
      <AdminFileForm
        onSubmit={(data) => submitStore({ ...data })}
        onSubmitError={error}
        onSubmitLoading={isLoading}
      />
    </div>
  )
}

export default AdminFileStorePage