import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import DropdownItem from './item'
import classNames from 'classnames'
import FormError from '../../error'

type DropdownProps = {
  trigger: JSX.Element
  children: JSX.Element | JSX.Element[]
  containerClass?: string
  label?: string
  itemClass?: string
  error?: string
  disabled?: boolean
}

const Dropdown = ({
  trigger,
  label,
  children,
  containerClass,
  itemClass,
  error,
  disabled = false,
}: DropdownProps) => {
  return (
    <Menu as="div" className={classNames('relative flex flex-col w-full', containerClass)}>
      <div className="flex">
        {
          label !== undefined &&
          <span className="inline-flex items-center px-3 border border-r-0 border-secondary-gray text-primary-gray rounded-l-md bg-gray-50 min-w-max">
            {label}
          </span>
        }
        <div className="relative w-full">
          <Menu.Button className={classNames('w-full p-2 leading-6 text-left border border-secondary-gray text-primary-gray rounded-r-md', !label && 'rounded-l-md')} disabled={disabled}>
            {trigger}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={classNames('absolute -top-0 transform -translate-y-full lg:translate-y-0 lg:top-full w-full p-1 origin-top-right bg-white rounded-md shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none', itemClass)}>
              {children}
            </Menu.Items>
          </Transition>
        </div>
      </div>
      {
        error &&
        <FormError className="mt-1" text={error} />
      }
    </Menu>
  )
}

Dropdown.Item = DropdownItem

export default Dropdown