import MultiFilter from 'api/types/requests/Filter'
import { useEffect, useState } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

type UseFilterOptions = {
  delay?: number
  useDebounce?: boolean
}

type UseFilterParams<T> = {
  initialFilters: T
  onFilterChange?: (updatedFilters?: T) => void
  opts?: UseFilterOptions
}

type UseFilterReturn<T> = [filters: Record<'filters' | 'debouncedFilters', T>, setFilters: (filters: T) => void]

/**
 * 
 * @param initialFilters Set the initial filters
 * @param onFilterChange Callback after the filters have changed
 * @param debounceTime Sets the debounce delay, defaults to 1000ms
 * @param useDebounce determines whether debouncing is enabled
 * @returns void
 *
 */
export function useFilters<T = MultiFilter>({
  initialFilters,
  onFilterChange,
  opts = { useDebounce: true },
}: UseFilterParams<T>): UseFilterReturn<T> {
  const [_, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState<T>(initialFilters)

  const [debouncedFilters] = useDebounce<T>(filters, opts?.delay ?? 500)

  useEffect(() => {
    if (filters) {
      onFilterChange?.(filters)
    }
  }, [filters])

  useEffect(() => {
    if (debouncedFilters && opts?.useDebounce) {
      setSearchParams(
        Object.fromEntries(
          Object.entries(debouncedFilters)
            .filter(([_, value]) => value),
        ) as URLSearchParamsInit)
    }
  }, [debouncedFilters])

  return [{ filters, debouncedFilters }, setFilters]
}