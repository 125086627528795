import { useNotifier } from 'react-headless-notifier'
import { useAdminDeregisterAssetMutation } from '../../../../api'
import Modal from '../..'
import Button from '../../../button'
import { useEffect } from 'react'
import NotificationToast from '../../../notifications'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  assetId: number
}

const DeregisterAssetModal = ({ isOpen, onClose, assetId }: ModalProps) => {
  const { notify } = useNotifier()

  const [deregisterAsset, {
    isSuccess: didDeregisterAsset,
    isLoading: isDeregisteringAsset,
    error: deregisterAssetError,
  }] = useAdminDeregisterAssetMutation()

  const onSubmit = () => {
    deregisterAsset({ id: assetId })
  }

  useEffect(() => {
    if (deregisterAssetError) {
      notify(<NotificationToast type="error" message="There was a problem removing this asset's owner" />)
    }
  }, [deregisterAssetError])

  useEffect(() => {
    if (didDeregisterAsset) {
      notify(<NotificationToast message="Asset owner removed successfully" />)
      onClose()
    }
  }, [didDeregisterAsset])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title="Deregister Asset"
        description="Permanently remove the owner of this asset, this cannot be reverted." />
      <Modal.Content>
        <p>Are you sure you want to remove the owner of this asset?</p>
        <p>This will <b>reset</b> the registration process of the asset.</p>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button variant="danger" isLoading={isDeregisteringAsset} onClick={() => onSubmit()}>Remove</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DeregisterAssetModal