import Card from 'components/cards/default'
import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import ListBulletIcon from 'components/icons/ListBulletIcon'
import SquaresIcon from 'components/icons/SquaresIcon'
import AssetBrowseGrid from './grid'
import AssetBrowseList from './list'
import { useGetUserAssetsQuery } from 'api'
import SearchInput from 'components/admin/search_input'
import PrivacyFilter from 'enums/PrivacyFilter'
import { useFilters } from 'hooks/useFilters'
import Select from 'react-select'
import UserAssetsRequest from 'api/types/requests/asset/user'

const AssetBrowsePage = () => {
  const [canFilter, setCanFilter] = useState(false)
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [isGridMode, setIsGridMode] = useState<boolean>(false)

  const [{ filters, debouncedFilters }, setFilters] = useFilters<UserAssetsRequest>({
    initialFilters: {
      search: undefined,
      privacy: undefined,
    },
  })

  const { data: assetData } = useGetUserAssetsQuery({ ...debouncedFilters }, { refetchOnMountOrArgChange: true })

  const privacyFilterOptions = [
    { label: 'Public', value: PrivacyFilter.PUBLIC },
    { label: 'Private', value: PrivacyFilter.PRIVATE },
  ]

  useEffect(() => {
    setIsFiltering(
      Object.values(filters).some((filter) => filter !== undefined)
    )
  }, [filters])

  useEffect(() => {
    if (assetData) {
      if (assetData.length > 1 && !canFilter) {
        setCanFilter(true)
      }
    }
  })

  return (
    <Card className="min-h-[450px]">
      <div className="flex flex-col gap-3">
        <div className='flex items-center justify-between mb-2'>
          <span className='text-xl font-semibold'>Your KiCalls</span>
          <RadioGroup value={isGridMode} onChange={setIsGridMode} className='inline-flex border border-gray-400 rounded-full overflow-clip'>

            <RadioGroup.Option value={true} className="rounded-l-full">
              {({ checked }) => (
                <div className={`flex items-center ${checked ? 'bg-blue-100' : ''}`}>
                  <SquaresIcon className='h-5 mx-2 my-1' />
                </div>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option value={false} className="rounded-r-full">
              {({ checked }) => (
                <div className={`flex items-center h-auto ${checked ? 'bg-blue-100' : ''}`}>
                  <ListBulletIcon className='h-5 mx-2 my-1' />
                </div>
              )}
            </RadioGroup.Option>

          </RadioGroup>
        </div>

        {
          canFilter &&
          <div className="flex gap-2 flex-col sm:flex-row justify-end">
            <SearchInput
              value={filters?.search ?? ''}
              onChange={(input) => setFilters({ ...filters, search: input != '' ? input : undefined })}
            />
            <Select
              isClearable
              placeholder="Filter by Privacy"
              classNamePrefix="react-select"
              className="react-select-container"
              onChange={(option) => setFilters({ ...filters, privacy: option?.value ?? undefined})}
              options={privacyFilterOptions}
            />
          </div>
        }

        {
          isGridMode
            ? <AssetBrowseGrid assets={assetData} />
            : <AssetBrowseList assets={assetData} />
        }

        {
          assetData && assetData?.length <= 0 && (
            <p className="m-auto text-sm text-center text-gray-400 lg:my-10 max-w-md">
              {
                isFiltering
                  ? 'No assets with the current search filters could be found. Please double check your filters and try again.'
                  : 'You currently have no registered KiCalls, simply scan an unregistered KiCall and follow the steps to add one to your account.'
              }
            </p>
          )
        }
      </div>
    </Card>
  )
}

export default AssetBrowsePage