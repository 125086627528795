import { useAdminGetThemeQuery, useAdminStoreThemeMutation, useAdminUpdateThemeMutation } from 'api'
import FormHeader from 'components/admin/form_header'
import AdminThemeForm from 'components/admin/forms/theme'
import NotificationToast from 'components/notifications'
import Spinner from 'components/spinner'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useParams } from 'react-router-dom'

const AdminThemeManagePage = () => {
  const { notify } = useNotifier()
  const params = useParams()

  const { data: getResult } = useAdminGetThemeQuery(params.id!, { refetchOnMountOrArgChange: true })
  const [submitUpdate, { data, error, isLoading }] = useAdminUpdateThemeMutation()

  useEffect(() => {
    if (data) {
      notify(<NotificationToast message="Theme updated successfully" />)
    }
  }, [data])

  if (!getResult?.theme) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Manage Theme"
        description="Manage theme information and images"
        backBtnText="Back to Themes"
        backBtnUrl="/admin/dashboard/themes/browse"
      />
      <AdminThemeForm
        theme={getResult?.theme}
        onSubmit={(data) => submitUpdate({ id: getResult.theme.id, body: data })}
        onSubmitError={error}
        onSubmitBtnText="Update"
        onSubmitLoading={isLoading}
      />
    </div>
  )
}

export default AdminThemeManagePage