import React from 'react'

const PrivacyAndCookiesBody = () => {
  const cookieConceptsUrl = 'https://developers.google.com/analytics/resources/concepts/gaConceptsCookies/'
  const manageCookiesUrl = 'http://www.allaboutcookies.org/manage-cookies/'
  
  return (
    <>
      <p>Supra UK Limited (&quot;We&quot;) are committed to protecting and respecting your privacy. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
      <p>This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
      <p>For the purpose of the Data Protection Act 1998 (the Act), the data controller is Supra UK Limited whose registered office is at Curo House, Green Box, Westonhall Road, Stoke Prior, Bromsgrove, Worcestershire B60 4AL.</p>

      <h2>Information we may collect from you</h2>
      <p>We may collect and process the following data about you:</p>
      <p>If you contact us, we may keep a record of that correspondence. We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them.
          Details of your visits to our site including, but not limited to, traffic data, location data, weblogs and other communication data, and the resources and pages that you access.</p>

      <h2>IP Addresses and Cookies</h2>
      <p>A cookie is a small file of letters and numbers that we put on your computer if you agree. These cookies allow us to distinguish you from other users of our website, which helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
      <p>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users&apos; browsing actions and patterns, and does not identify any individual.</p>
      <p>For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer. Cookies contain information that is transferred to your computer&apos;s hard drive. They help us to improve our site and to deliver a better and more personalised service.</p>
      <p>They enable us:</p>

      <ul>
        <li>To estimate our audience size and usage pattern.</li>
        <li>To store information about your preferences, and so allow us to customise our site according to your individual interests.</li>
        <li>To speed up your searches.</li>
        <li>To recognise you when you return to our site.</li>
      </ul>

      <h2>Analytical Cookies</h2>
      <p>Our site uses &quot;Analytical&quot; cookies which allow us to recognise and count the number of visitors and to see how visitors move around the site when they are using it. This helps us to improve the way our website works, for example by ensuring that users are finding what they are looking for easily. Read more about the individual analytical cookies we use and how to recognise them here: <a href={cookieConceptsUrl} target="_new" >{cookieConceptsUrl}</a></p>

      <p><b>__utma</b><br />
          This cookie is typically written to the browser upon the first visit to your site from that web browser. If the cookie has been deleted by the browser operator, and the browser subsequently visits your site, a new __utma cookie is written with a different unique ID. In most cases, this cookie is used to determine unique visitors to your site and it is updated with each page view. Additionally, this cookie is provided with a unique ID that Google Analytics uses to ensure both the validity and accessibility of the cookie as an extra security measure.</p>
          
      <p><b>__utmb</b><br />
          This cookie is used to establish and continue a user session with your site. When a user views a page on your site, the Google Analytics code attempts to update this cookie. If it does not find the cookie, a new one is written and a new session is established. Each time a user visits a different page on your site, this cookie is updated to expire in 30 minutes, thus continuing a single session for as long as user activity continues within 30-minute intervals. This cookie expires when a user pauses on a page on your site for longer than 30 minutes. You can modify the default length of a user session with the _setSessionCookieTimeout() method.</p>

      <p><b>__utmz</b><br />
          This cookie stores the type of referral used by the visitor to reach your site, whether via a direct method, a referring link, a website search, or a campaign such as an ad or an email link. It is used to calculate search engine traffic, ad campaigns and page navigation within your own site. The cookie is updated with each page view to your site.</p>

      <p><b>__utmv</b><br />
          This cookie is not normally present in a default configuration of the tracking code. The __utmv cookie passes the information provided via the _setVar() method, which you use to create a custom user segment. This string is then passed to the Analytics servers in the GIF request URL via the utmcc parameter. This cookie is only written if you have added the _setVar() method for the tracking code on your website page. </p>

      <p><b>__utmx</b><br />
          This cookie is used by Website Optimizer and only set when the Website Optimizer tracking code is installed and correctly configured for your pages. When the optimizer script executes, this cookie stores the variation this visitor is assigned to for each experiment, so the visitor has a consistent experience on your site. See the Website Optimizer Help Center for more information.
          You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. However, if you select this setting you may be unable to access certain parts of our site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to our site.</p>

      <p>To change your browser settings please follow these instructions <a href={manageCookiesUrl} target="_new">{manageCookiesUrl}</a></p>

      <h2>Where we store your personal data</h2> 
      <p>The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (&quot;EEA&quot;). It may also be processed by staffs operating outside the EEA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
      <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>

      <h2>Uses made of the information</h2> 
      <p>We (including our employees, agents and representatives) use information held about you in the following ways:</p>

      <ul>
        <li>To ensure that content from our site is presented in the most effective manner for you and for your computer. </li>
        <li>To provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes. </li>
        <li>To carry out our obligations arising from any contracts entered into between you and us. </li>
        <li>To allow you to participate in any interactive features of our service, when you choose to do so. </li>
        <li>To notify you about changes to our service.</li>
      </ul>

      <p>We may also use your data to provide you with information about goods and services which may be of interest to you and we may contact you about these by post or telephone.</p>
      <p>If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale to you.</p>

      <h2>Disclosure of your information</h2>
      <p>We may disclose your personal information to third parties:</p>

      <ul>
        <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</li>
        <li>If Supra UK Limited or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
        <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Supra UK Limited, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
      </ul>

      <h2>Your rights</h2>
      <p>You have the right to ask us not to process your personal data for marketing purposes. You can exercise the right at any time by contacting us at <a href="mailto:marketing@keysafe.co.uk">marketing@keysafe.co.uk</a></p>
      <p>Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>

      <h2>Access to information</h2>
      <p>The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act.  Any access request may be subject to a fee of £10 to meet our costs in providing you with details of the information we hold about you.</p>

      <h2>Changes to our Privacy Policy</h2>
      <p>Any changes we may make to our cookie policy in the future will be posted on this page and, where appropriate, notified to you by e-mail.</p>

      <h2>Contact</h2>
      <p>Questions, comments and requests regarding this cookie policy are welcomed and should be addressed to <a href="mailto:marketing@keysafe.co.uk">marketing@keysafe.co.uk</a></p>
    </>
  )
}

export default PrivacyAndCookiesBody