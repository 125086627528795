import { IconBaseProps } from 'types/icon'

export function TickIcon({ 
  ariaHidden = true, 
  className, 
}: IconBaseProps) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24" 
      strokeWidth="2" 
      stroke="currentColor" 
      fill="none"
      className={className}
      aria-hidden={ariaHidden}
      strokeLinecap="round" 
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M5 12l5 5l10 -10"></path>
    </svg>
  )
}