import classNames from 'classnames'
import { ChangeEvent, ClipboardEvent as ReactClipboardEvent, KeyboardEvent as ReactKeyboardEvent, FocusEventHandler, forwardRef, HTMLInputTypeAttribute, useState } from 'react'
import FormError from 'components/error'

type InputProps = {
  id?: string
  autoComplete?: string
  autoFocus?: boolean
  disabled?: boolean
  defaultValue?: string
  error?: string
  inputMode?: 'none' | 'search' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined // Can we improve this?
  centered?: boolean
  label?: string
  maxLength?: number
  name?: string
  onBlur?: FocusEventHandler
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: ReactKeyboardEvent<HTMLInputElement>) => void
  onPaste?: (e: ReactClipboardEvent<HTMLInputElement>) => void
  pattern?: string
  placeholder?: string
  required?: boolean
  suffix?: string
  suffixOptions?: string[]
  type?: HTMLInputTypeAttribute
  value?: string
  className?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input({
  id,
  autoComplete = 'off',
  autoFocus = false,
  disabled = false,
  defaultValue,
  error,
  inputMode = 'text',
  centered = false,
  label,
  name,
  maxLength,
  onBlur,
  onChange,
  onKeyDown,
  onPaste,
  required = false,
  pattern,
  placeholder,
  suffix,
  suffixOptions = [],
  type = 'text',
  value,
  className = '',
}, ref) {

  const [selectedOption, setSelectedOption] = useState<string>()

  const suffixClasses = 'inline-flex items-center border-secondary-gray text-primary-gray border text-custom border-secondary-gray bg-gray-50 border-l-0 w-full'
  const innerClassNames = classNames('border-secondary-gray focus:outline-primary/75 text-primary-gray border leading-6 w-full p-2', {

    // Input Variants
    'bg-disabled text-disabled-dark': disabled,
    'rounded-md': !label && (!suffix && suffixOptions.length === 0),
    'rounded-r': label && (!suffix || suffixOptions.length === 0),
    'rounded-r-none': (suffix || suffixOptions.length > 0),
    'text-center': centered === true,
  })

  return (
    <div className={classNames('flex flex-col' && error, 'w-full')}>
      <div className={classNames('flex', type === 'number' && 'items-center')}>
        {
          label &&
          <span className="inline-flex items-center px-3 border border-r-0 border-secondary-gray text-primary-gray rounded-l-md bg-gray-50 min-w-max">
            {label}
          </span>
        }

        <input
          id={id}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classNames(innerClassNames, className)}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          maxLength={maxLength}
          inputMode={inputMode}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
          required={required}
          pattern={pattern}
        />

        {
          suffix !== undefined &&
          <span className={classNames('px-3 border max-w-min', suffixClasses, suffixOptions.length === 0 && 'rounded-r-md')}>
            {suffix}
          </span>
        }

        {
          suffixOptions.length > 0 &&
          <select className={classNames('p-1 border rounded-r-md max-w-max', suffixClasses)} onChange={(e) => setSelectedOption(e.target.value)} disabled={disabled}>
            {
              suffixOptions.map((option, index) => {
                return (
                  <option key={index}>{option}</option>
                )
              })
            }
          </select>
        }
      </div>
      
      {
        error &&
        <FormError className="mt-1 !text-left" text={error} />
      }
    </div>
  )
})

export default Input