import Modal from 'components/modals'
import { useAdminDeleteUserMutation } from 'api'
import { User } from 'api/types/models/user'
import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import { ModalBaseProps } from 'types/modal'

type ModalProps = ModalBaseProps & {
  user: User | undefined
}

const DeleteUserModal = ({
  user,
  isOpen,
  onClose,
}: ModalProps) => {
  const { successToast, errorToast } = useToast()

  const [deleteMutation, {
    isLoading: isDeleting,
  }] = useAdminDeleteUserMutation()

  const handleDelete = async () => {
    await deleteMutation({ id: user!.id })
      .unwrap()
      .then(() => successToast({
        title: 'User Deleted',
        message: 'The user has been deleted.',
      }))
      .catch(() => errorToast({
        title: 'Failed to Delete User',
        message: 'We couldn\'t delete this user. Please try again later.',
      }))

    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobilePadding>
      <Modal.Header title="Delete User" />
      <Modal.Content>
        <p>Are you sure you want to delete {user?.full_name ? user?.full_name : 'this user?'}</p>
      </Modal.Content>
      <Modal.Footer className="flex flex-row justify-end gap-2">
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          isLoading={isDeleting}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteUserModal